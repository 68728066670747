import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Main = styled.main`
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const Hero = styled.div`
  position: relative;
  width: 100%;

  @media screen and (max-width: 1199px) {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

import { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);
  const [ethBalance, setEthBalance] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [signature, setSignature] = useState(null);

  return (
    <UserContext.Provider
      value={[
        user,
        setUser,
        walletAddress,
        setWalletAddress,
        ethBalance,
        setEthBalance,
        tokenBalance,
        setTokenBalance,
        signature,
        setSignature,
      ]}
    >
      {props.children}
    </UserContext.Provider>
  );
};

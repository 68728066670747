import styled from 'styled-components';

const getBGColor = (bgColor) => {
  switch (bgColor) {
    case 'Standard':
    case '001':
      return '#FFF9EB';
    case 'Prestige':
    case '002':
      return '#95C086';
    case 'Elite':
    case '003':
      return '#86C0BD';
    case 'Super Elite':
    case '004':
      return '#A894C7';
    case 'Legendary':
    case '005':
      return '#FFCE47';
  }
};

export const Border = styled.div`
  position: relative;
  width: 23%;
  border-radius: 1em;
  background-color: ${({ bgColor }) => getBGColor(bgColor)};
  border: 2px solid ${({ theme }) => theme.background.dark};
  margin-bottom: 2em;
  &:hover .sell-btn {
    display: flex;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 1em;
  .wish-icon {
    display: none;
  }

  &:hover .wish-icon {
    display: block;
  }

  &:hover .content {
  }

  &:hover .card-header {
    background: #fff;
  }

  .card-bottom {
    background-color: #000;
    margin-top: 1.5em;
    margin-bottom: -2px;
    padding: .75em 1.5em .5em;
    border: 1px solid #000;
    border-radius: 1em 1em 0 0;
  }

  .card-side {
    color: ${({ theme }) => theme.background.dark};
    width: 1em;
    height: 1em;
    white-space: nowrap;
    position: absolute;
    right: -.25em;
    top: 5em;
    font-weight: 400;
    text-align: center;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    text-transform: none;
  }
`;

export const CardHeader = styled.div`
  margin-top: -2.5rem;
  margin-bottom: 3rem;
  background-size: cover;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 1em;

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  img {
    max-height: 40vh;
  }
`;

export const CardContent = styled.div`
  padding: 0 1rem;
  position: relative;
  color: ${({ theme }) => theme.background.dark};
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .contract {
    margin-top: 2em;
    text-transform: none;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.4rem;
  }
  .currency {
    display: flex;
    align-items: center;

    p {
      font-size: 12px;
    }
    span {
      font-size: 10px;
      font-weight: 300;
    }
  }
`;

export const SoldBanner = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.45);
  border-radius: 6px 6px 0 0;

  display: none;
  align-items: center;
  justify-content: center;

  &.show {
    display: flex;
  }

  :after {
    content: 'SOLD';
    position: absolute;
    background: ${({ theme }) => theme.background.dark};
    border-radius: 4px;
    top: 50%;
    left: 50%;
    padding: 0.5rem 1rem;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    z-index: 10;
  }
`;

export const WishIcon = styled.div`
  position: absolute;
  background: ${({ inList }) =>
    inList ? `url('/images/boldstar.svg')` : `url('/images/star.svg')`};
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
`;

export const SellBtn = styled.div`
  position: absolute;
  height: 30px;
  width: 60px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  top: 10px;
  left: 10px;
  z-index: 20;
`;

export const SkeletonImg = styled.div`
  position: absolute;
  height: ${({ height }) => height};
  width: ${({ width }) => (width ? width : '95%')};
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  animation: 1500ms ease-in-out infinite pulse;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.45;
    }
  }
`;
export const SkeletonText = styled.div`
  margin: 0 1rem 0.75rem 0;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 6px;
  animation: 1500ms ease-in-out infinite pulse;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.45;
    }
  }
`;


export const TransferBtn = styled.div`
  position: absolute;
  height: 30px;
  width: 60px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 14px;
  display: none;
  align-items: center;
  justify-content: center;
  top: 40px;
  left: 10px;
  z-index: 20;
`;
import React from 'react';
import ReactDOM from 'react-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import config from './config'
import App from './App';
import { UserProvider } from './context/UserContext';

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider
      reCaptchaKey={config.reCaptchaKeyEnterprise}
      language="en"
      useRecaptchaNet="false"
      useEnterprise="true"
      scriptProps={{
        async: false, // optional, default to false,
        defer: false, // optional, default to false
        appendTo: 'head', // optional, default to "head", can be "head" or "body",
        nonce: undefined // optional, default undefined
      }}
    >
      <UserProvider>
        <App />
      </UserProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

import React from 'react';
import { StyledButton, ButtonContainer, ShadowDiv } from './Button2.styles';

const Button2 = (props) => {
  return (
    <ShadowDiv>
      <ButtonContainer {...props} onClick={null}>
        <StyledButton {...props}>{props.children}</StyledButton>
      </ButtonContainer>
    </ShadowDiv>
  );
};

export default Button2;

import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  width: 100vw;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5rem;
    .left {
      order: 1;
      width: 100% !important;
    }
    .iris-right {
      background-size: cover !important;
      background-position: top;
      height: 50vh !important;
    } 
  }
  form {
    width: 70%;
  }
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 600ms ease-in 0s, transform 600ms ease-in-out 0s;
    opacity: 1;
    form {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    }
    .heading-label {
      font-family: 'Dark twenty', sans-serif;
      font-size: 1.5em;
      text-shadow: 0 0 10px #0ad6ca;
    }
    .heading {
      font-family: 'Baron neue', sans-serif;
      font-size: 3em;
      margin-bottom: 2rem;
      text-shadow: 0 0 10px #0ad6ca;
    }
    button {
      font-family: 'D din pro 400', sans-serif;
      font-weight: 700;
      padding: 1rem 1.5rem;
      font-size: 1.2em;
      cursor: pointer;
    }
  }
  .iris-right {
    width: 50%;
    height: 100vh;
    display: flex;
    background-image: url('https://static-files.locgame.io/images/Iris.webp');
    background-size: contain;
    background-repeat: no-repeat;
    img {
      animation: fadeInAnimation ease 4s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      @keyframes fadeInAnimation {
        0% { opacity: 0 }
        100% { opacity: 1 }
      }
    }
  }
  .server-response {
    color: red;
  }
`;

export const Section = styled.div`
  width: 50%;
  height: 100%;

  @media screen and (max-width: 611px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
  }
`;

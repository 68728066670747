import styled from 'styled-components';

export const Container = styled.div`
  width: 650px;
  height: 75px;
  margin: 2rem;
  padding: 1rem 1.5rem;
  background: ${({ theme }) => theme.lightBgColor};
  border: 1px solid #362e55;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .caption {
    font: ${({ theme }) => theme.font.big};
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: ${({ theme }) => theme.red};
  }

  .timer {
    font-size: 40px;
    width: 290px;
  }

  @media screen and (max-width: 768px) {
    width: unset;
    height: unset;
    flex-direction: column;
    padding: 1rem 0;
  }
  @media screen and (max-width: 370px) {
    margin: 2rem 0;
    padding: 0.5rem 0;
  }
`;

import React, { useState, useEffect } from 'react';
import Search from '../Search/Search.component';
import { Container } from './Filters.style';

const Filters = (props) => {
  const { filters, setFilters, resetFilters, packFilter, sortOptions, items, characterFilterEnabled, setShowFilter } =
    props;

  const [show, setShow] = useState(null);
  const [characterFilter, setCharacterFilter] = useState(false);

  useEffect(() => {
    setShow(false);
  }, []);

  const handleChange = (e) => {
    e.stopPropagation();
    let {
      id,
      name,
      value,
      dataset: { group },
    } = e.target;
    if (name === 'character') {
      setFilters({ ...filters, search: value });
    } else if (name === 'sort') {
      setFilters({ ...filters, sort: value });
    } else {
      if (filters[group]?.includes(id)) {
        setFilters((prev) => ({
          ...prev,
          [group]: prev[group].filter((item) => item !== id),
        }));
      } else {
        setFilters((prev) => {
          return { ...prev, [group]: [...prev[group], String(id)] };
        });
      }
    }
  };

  const getSortOptions = () => {
    if (!sortOptions) return;
    let keys = Object.keys(sortOptions);
    return keys.map((key) => (
      <option key={key} value={key}>
        {sortOptions[key]}
      </option>
    ));
  };

  const getCharacters = () => {
    if (!characterFilter && items.length === 0) return '';
    if (!characterFilter) {
      const uniqueCharacters = items.filter((v, i, self) =>
        i === self.findIndex(t => (t.place === v.place && t.name === v.name))
      );
      setCharacterFilter(uniqueCharacters);
      return uniqueCharacters.map((v) => (
        <option key={v.name} value={v.name}>
          {v.name}
        </option>
      ));
    }
    return characterFilter.map((v) => (
      <option key={v.name} value={v.name}>
        {v.name}
      </option>
    ));
  };

  return (
    <Container {...props} showFilter={show}>
      {filters && (
        <div className='filter-body'>
          <div className='filter-group'>
            <h6>EDITION</h6>
            <ul style={{ flexWrap: 'wrap' }}>
              <li
                className={filters.edition?.includes('genesis') ? 'active' : null}
                id='genesis'
                data-group='edition'
                onClick={handleChange}
              >
                Genesis
              </li>
              <li
                className={filters.edition?.includes('apollo') ? 'active' : null}
                id='apollo'
                data-group='edition'
                onClick={handleChange}
              >
                Apollo
              </li>
              <li
                className={filters.edition?.includes('legendsAwakening') ? 'active' : null}
                id='legendsAwakening'
                data-group='edition'
                onClick={handleChange}
              >
                Legends Awakening
              </li>
            </ul>
          </div>
          <div className='filter-group'>
            <h6>RARITY</h6>
            <ul>
              <li
                className={filters.rarity?.includes('001') ? 'active' : null}
                id='001'
                data-group='rarity'
                onClick={handleChange}
              >
                S
              </li>
              <li
                className={filters.rarity?.includes('002') ? 'active' : null}
                id='002'
                data-group='rarity'
                onClick={handleChange}
              >
                P
              </li>
              <li
                className={filters.rarity?.includes('003') ? 'active' : null}
                id='003'
                data-group='rarity'
                onClick={handleChange}
              >
                E
              </li>
              <li
                className={filters.rarity?.includes('004') ? 'active' : null}
                id='004'
                data-group='rarity'
                onClick={handleChange}
              >
                SE
              </li>
              <li
                className={filters.rarity?.includes('005') ? 'active' : null}
                id='005'
                data-group='rarity'
                onClick={handleChange}
              >
                L
              </li>
            </ul>
          </div>
          {!packFilter && (
            <div className='filter-group'>
              <h6>TYPE</h6>
              <ul>
                <li
                  className={
                    filters.type?.includes('scout') ? 'active' : null
                  }
                  id='scout'
                  data-group='type'
                  onClick={handleChange}
                >
                  Scout
                </li>
                <li
                  className={filters.type?.includes('tank') ? 'active' : null}
                  id='tank'
                  data-group='type'
                  onClick={handleChange}
                >
                  Tank
                </li>
                <li
                  className={
                    filters.type?.includes('fighter') ? 'active' : null
                  }
                  id='fighter'
                  data-group='type'
                  onClick={handleChange}
                >
                  Fighter
                </li>
              </ul>
            </div>
          )}
          <div className='filter-group'>
            <h6>Sort</h6>
            <select
              name='sort'
              onChange={handleChange}
              defaultValue='DATE_ASC'
            >
              {getSortOptions()}
            </select>
          </div>
          {characterFilterEnabled &&
            <div className='filter-group'>
              <h6>Character</h6>
              <select
                name='character'
                onChange={handleChange}
                defaultValue='Select'
              >
                {<option key='' value=''>Select</option>}
                {getCharacters()}
              </select>
            </div>}
          <div className='filter-group'>
            <h6>Search</h6>
            <Search
              filters={filters}
              setFilters={setFilters}
              resetFilters={resetFilters}
            />
          </div>
        </div>
      )}
      <div className='filter-group'>
        <div
          className='toggle'
          onClick={() => {
            setShow(!show);
          }}
        >
          {show ? 'Hide Filters' : 'Show Filters'}
        </div>
        <h6>{`${items && items?.length} ${packFilter ? (items?.length === 1 ? 'PACK' : 'PACKS') : (items?.length === 1 ? 'CARD' : 'CARDS')}`}</h6>
      </div>
    </Container>
  );
};

export default Filters;

import React from 'react';
import { Container, Main } from './CategorySelector.styles';

const CategorySelector = (props) => {
  return (
    <Container>
      <Main {...props}>
        <div className='image'>
          <img src={props.image} alt='Category Icon' />
        </div>
        <div className='content'>
          <h3>{props.title}</h3>
          <p>{props.content} </p>
        </div>
      </Main>
    </Container>
  );
};

export default CategorySelector;

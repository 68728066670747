import styled from 'styled-components';

export const Main = styled.div`
  display: flex;
  position: relative;
  padding: 3rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  max-width: 110ch;
  min-height: 65vh;
  overflow: none;
  color: ${({ theme }) => theme.fontColor};
  h3,
  h4 {
    width: 100%;
    margin-bottom: 1.25rem;
    text-align: center;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from './Timer.styles';

const Timer = ({ caption, timestamp, setCurrentTime }) => {
  const [timer, setTimer] = useState(null);
  let timerId;

  useEffect(() => {
    if (!timestamp) return;
    timerId = setInterval(() => {
      let difference = timestamp - Date.now();
      let seconds = Math.floor(difference / 1000) % 60;
      let minutes = Math.floor(difference / 60000) % 60;
      let hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
      let days = Math.floor(difference / (1000 * 60 * 60 * 24));
      setTimer(
        `${days > 0 ? days : '0'} : ${
          hours > 0 ? hours.toString().padStart(2, '0') : '00'
        } : ${minutes > 0 ? minutes.toString().padStart(2, '0') : '00'} : ${
          seconds > 0 ? seconds.toString().padStart(2, '0') : '00'
        }`
      );
      if (setCurrentTime) setCurrentTime(Date.now());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [timestamp]);
  return (
    <Container>
      <p className='caption'>{caption ? caption : 'coming soon'}</p>
      {timer && (
        <p className='timer' style={{ display: timestamp ? 'block' : 'none' }}>
          {timestamp ? timer : null}
        </p>
      )}
    </Container>
  );
};

export default Timer;

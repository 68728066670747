import styled from 'styled-components';

const height = 140;
const width = 390;

export const Container = styled.div`
  position: relative;
  height: ${height}px;
  width: ${width}px;
  background: ${({ theme }) => theme.borderGradient};
  border-radius: 10px;

  @media screen and (max-width: 500px) {
    width: 300px;
  }
`;

export const Main = styled.div`
  position: absolute;
  height: ${height - 4}px;
  width: ${width - 4}px;
  background: ${(props) =>
    props.current ? props.theme.mainGradient : props.theme.hoverGradient};
  border-radius: 10px;
  inset: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .image {
    padding: 1.75rem;

    img {
      height: 3rem;
      width: 3rem;
    }
  }

  h3 {
    margin-bottom: 1rem;
  }

  @media screen and (max-width:500px){
    width:296px;

    .image {
    padding: 1.25rem;

    img {
      height: 1.75rem;
      width: 1.75rem;
    }
  }
`;

import React from 'react';
import { Container, CopyButton } from './WalletAddress.styles';
import { Icon } from '../Icons/BaseIcon';
const WalletAddress = ({ address }) => {
  const handleClick = () => {
    const h3El = document.getElementById('address');
    navigator.clipboard.writeText(h3El.textContent);
  };

  return (
    <Container>
      <p className='heading'>Wallet Address:</p>
      <span id='address'>
        {address}
        <CopyButton onClick={handleClick}>
          <Icon src='/images/copy.svg' height={18} width={18} />
        </CopyButton>
      </span>
    </Container>
  );
};

export default WalletAddress;

import React from 'react';
import { Container } from './Footer.styles';
import { Icon } from '../Icons/BaseIcon';

const Footer = () => {
  return (
    <Container>
      <a href='https://locgame.io' alt='Locgame.io Home' className='logo'>
        LOCG
      </a>
      <ul>
        <li>
          <a href='https://market.locgame.io/support' alt='Privacy Policy'>
            support
          </a>
        </li>
        <li>
          <a href='https://www.locgame.io/company' alt='Privacy Policy'>
            about us
          </a>
        </li>
        <li>
          <a href='https://www.locgame.io/privacy-policy' alt='Privacy Policy'>
            Privacy Policy
          </a>
        </li>
      </ul>
      <ul className='social'>
        <li>
          {' '}
          <a href='https://medium.com/locgame' target='_blank' rel='noreferrer'>
            <div className='icon-wrapper'>
              <Icon
                src='https://static-files.locgame.io/images/medium.svg'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a href='https://t.me/loc_game' target='_blank' rel='noreferrer'>
            <div className='icon-wrapper'>
              <Icon
                src='https://static-files.locgame.io/images/telegram.svg'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://discord.com/invite/aPf6MTqTAj'
            target='_blank'
            rel='noreferrer'
          >
            <div className='icon-wrapper'>
              <Icon
                src='https://static-files.locgame.io/images/discord.svg'
                margin='0'
              />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://twitter.com/LOCgameio?s=20'
            target='_blank'
            rel='noreferrer'
          >
            <div className='icon-wrapper'>
              <Icon
                src='https://static-files.locgame.io/images/twitter.svg'
                margin='0'
              />
            </div>
          </a>
        </li>
      </ul>
    </Container>
  );
};

export default React.memo(Footer);

import styled from 'styled-components';

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.background.light};
  width: 100%;
  padding: .75em 2em 1em 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.navHeight};
  z-index: 30;
  border-radius: 1.5em;

  a {
    color: #140F00;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .logo {
      font-family: 'Baron Neue', sans-serif;
      font-weight: 900;
      line-height: 100%;
      font-size: 1.25em;
    }
  }

  .right .dropdown {
    @media screen and (max-width: 1084px) {
      display: none;
    }
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Baron Neue', sans-serif;
    @media screen and (max-width: 1084px) {
      display: none;
    }
    .signup {
      margin-right: 1rem;
    }
  }

  // nav custom settings
  .nav-link {
    color: ${({ theme }) => theme.fontColor};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    margin: 1rem 1rem;
    height: 100%;
    white-space: nowrap;
    a {
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      .dot {
        position:absolute;
        bottom: -1rem;
        width: .5rem;
        height: .5rem;
        border-radius: 50%;
      }
    }

    &:hover {
      .dot {
        border: 2px solid ${({ theme }) => theme.accent.shade2};
      }
    }

    & .active {
      .dot {
        background-color: ${({ theme }) => theme.accent.shade1};
        border: 2px solid ${({ theme }) => theme.accent.shade2};
      }
    }
  }

  .nav-icon {
    width: 10px;
    margin-left: 8px;
    transform: rotate(90deg);
  }

  .nav-drop-down {
    width: 260px;
    background: ${({ theme }) => theme.bgColor};
    position: absolute;
    top: -20px;
    left: 0;
    display: flex;
    flex-direction: column;

    a.nav-drop-down-link {
      width: 100%;
      padding: 15px;
      text-transform: uppercase;

      &:hover {
        padding-left: 20px;
        transition: padding-left 0.3s, background-color 0.3s;
      }
    }

    &.game,
    &.more {
      display: none;
    }
  }

  .mobile-toggle {
    display: none;
    align-items: center;
    justify-content: center;

    .nav-icon {
      margin: 0;
      width: 2rem;
    }
    img {
      filter: invert(1);
      transform: rotate(0deg);
    }

    @media screen and (max-width: 1084px) {
      display: flex;
    }
  }

  @media screen and (max-width: 1023px) {
    justify-content: space-between;
    padding: 0 0.5rem 0 2rem;
  }

  @media screen and (max-width: 700px) {
    padding: 0 1rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      font-size: 14px;
      line-height: 16px;
    }
    .signup {
      padding-right: 2rem;
    }
  }
  @media screen and (max-width: 390px) {
    padding: 1.5rem 1.5rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      font-size: 12px;
      line-height: 14px;
    }
    .signup {
      padding-right: 1.75rem;
    }
  }
`;

export const Image = styled.img`
  width: 80px;
`;

export const Icon = styled.img`
  display: inline-block;
  height: 25px;
  width: 25px;
`;

export const NavMenuDesktop = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media screen and (max-width: 1084px) {
    display: none;
  }
`;

export const NavMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  background-color: #FFEFC2;
  padding: 2rem 0 1rem;
  .mobile-upper-wrapper {
    display: flex;
    justify-content: space-between;
    margin: 0 1rem;
    .nav-close-icon {
      background: #fff;
      border-radius: 50%;
    }
  }

  ul.submenu {
    display: flex;
    flex-direction: column;
  }

  & ul {
    .login-wrapper {
      display: flex;
      justify-content: center;
      padding: 1rem 0;
      a .signup {
        width:30vw;
      }
      .loginBtn {
        width:30vw;
      }
    }
    li.nav-link {
      justify-content: flex-start;
      border-bottom: 2px solid #202125;
      font-size:2em;
      margin-left: 1rem;
      a {
        padding: 0;
        width: fit-content;
        opacity: .6;
      }
      a.active {
        opacity: 1;
      }
    }
  }

  .sub-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    li {
      border-bottom: 2px solid #202125;
    }
  }

  .sub-heading {
    font: 500 14px/24px TTHoves;
    color: ${({ theme }) => theme.fontColor};
    padding: 0 15px;
    cursor: pointer;
    text-transform: uppercase;
    opacity: 0.6;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }

  @media screen and (max-width: 1084px) {
    display: flex;
  }
`;

// export const Link = styled.li`
//   display: inline-block;
//   font: ${({ theme }) => theme.font.button};
//   font-weight: 400;
//   margin: 0 1.5rem;
// `;

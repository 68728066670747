import styled from 'styled-components';

export const Main = styled.div`
  h4 {
    margin-bottom: 2.5rem;
  }
  .pass-container {
    width: 300px;
    margin-bottom: 1rem;
  }
  .pass {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }

  .error {
    color: ${({ theme }) => theme.errorColor};
  }
  .success {
    color: ${({ theme }) => theme.successColor};
  }
`;

import React, { useState, useEffect, useContext } from 'react';
import { Main } from './PasswordChange.styles';
import { Icon } from '../../components/Icons/BaseIcon';
import useForm from '../../utils/useForm';
import validate from './validate';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import config from '../../config';
import { UserContext } from '../../context/UserContext';
import StyledInput from '../../components/Input/Input.component';
import StdButton from '../../components/StdButton/StdButton.component';

const PasswordChange = (props) => {
  const [msg, setMsg] = useState('');
  const [isForced, setIsForced] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [user, setUser] = useContext(UserContext);

  const { values, errors, handleSubmit, handleChange, setErrors, setValues } =
    useForm(isForced ? createPassword : updatePassword, validate, {
      password: '',
      newPassword: '',
      confirmPassword: '',
    });

  useEffect(() => {
    if (
      props.passedUser &&
      props.passedUser.challengeName === 'NEW_PASSWORD_REQUIRED'
    ) {
      setIsForced(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCreds() {
    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();
    AWS.config.region = config.cognito.aws_cognito_region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.cognito.aws_cognito_identity_pool_id,
      Logins: {
        // Change the key below according to the specific region your user pool is in.
        [`cognito-idp.${config.cognito.aws_cognito_region}.amazonaws.com/${config.cognito.aws_user_pools_id}`]:
          jwt,
      },
    });
    await AWS.config.credentials.refresh();
  }

  async function createPassword() {
    try {
      let newUser = await Auth.completeNewPassword(
        props.passedUser,
        values.newPassword
      );
      let attributes = {
        given_name:
          newUser.challengeParam.userAttributes['custom:firstName'] ||
          newUser.challengeParam.userAttributes.given_name,
        family_name:
          newUser.challengeParam.userAttributes['custom:lastName'] ||
          newUser.challengeParam.userAttributes.family_name,
        picture:
          newUser.challengeParam.userAttributes['custom:avatar'] ||
          newUser.challengeParam.userAttributes.picture ||
          null,
        email: newUser.challengeParam.userAttributes.email,
      };
      setUser(attributes);
      setIsForced(false);
      setMsg('Password Successfully Updated.');
      setTimeout(() => {
        setMsg('');
        setValues({ password: '', newPassword: '', confirmPassword: '' });
      }, 4000);
    } catch (err) {
      console.log(err);
    }
  }

  async function updatePassword() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        currentUser,
        values.password,
        values.newPassword
      );
      setMsg('Password Successfully Updated.');
      let params = {
        Destination: {
          ToAddresses: [user.email],
        },
        Message: {
          Body: {
            /* required */
            Html: {
              Charset: 'UTF-8',
              Data: `Hello ${user.given_name}, </br> Your password was successfully updated for locgame.io </br></br> Kind regards, </br> Locgame Team.`,
            },
          },
          Subject: {
            Charset: 'UTF-8',
            Data: 'Password Updated',
          },
        },
        Source: 'no-reply@locgame.io', // SENDER_ADDRESS
        ReplyToAddresses: ['support@locgame.io'],
      };
      try {
        await getCreds();
        AWS.config.update({
          region: 'eu-west-1',
        });
        await new AWS.SES({ apiVersion: '2010-12-01' })
          .sendEmail(params)
          .promise();
      } catch (err) {
        console.log(err);
      }
      setTimeout(() => {
        setMsg('');
        setValues({ password: '', newPassword: '', confirmPassword: '' });
      }, 4000);
    } catch (err) {
      setErrors({ password: 'Incorrect Password' });
    }
  }

  return (
    <Main>
      <h4>Change Password</h4>
      {isForced && (
        <span className={'error'}>
          Please update password to complete sign up.
        </span>
      )}
      <form onSubmit={handleSubmit}>
        <StyledInput
          type={showPass ? 'text' : 'password'}
          name='password'
          id='password'
          value={values.password}
          errors={errors.password}
          placeholder='Current Password'
          autoComplete='off'
          onChange={handleChange}
          width='300px'
          showIcon={true}
          togglePassword={() => setShowPass(!showPass)}
          showPassword={showPass}
          margin='1rem 0'
        />
        <span className={msg ? 'success' : null}>{msg}</span>
        <StyledInput
          type={showPass ? 'text' : 'password'}
          name='newPassword'
          id='newPassword'
          value={values.newPassword}
          errors={errors.newPassword}
          placeholder='New Password'
          autoComplete='off'
          onChange={handleChange}
          width='300px'
          showIcon={true}
          togglePassword={() => setShowPass(!showPass)}
          showPassword={showPass}
          margin='1rem 0'
        />
        <StyledInput
          type={showPass ? 'text' : 'password'}
          name='confirmPassword'
          id='confirmPassword'
          value={values.confirmPassword}
          errors={errors.confirmPassword}
          placeholder='New Password'
          autoComplete='off'
          onChange={handleChange}
          width='300px'
          showIcon={true}
          togglePassword={() => setShowPass(!showPass)}
          showPassword={showPass}
          margin='1rem 0'
        />

        <div className='pass-container'>
          <p className='pass'>Password must:</p>
          <p className='pass'>- Be 8 or more characters.</p>
          <p className='pass'>
            - Include one uppercase and one lowercase letter.
          </p>
          <p className='pass'>- Include a number.</p>
          <p className='pass'>- Include a special character.</p>
        </div>
        {/* <input
          type='submit'
          value='submit'
          onSubmit={handleSubmit}
          className='hidden'
        /> */}
      </form>
      <StdButton onClick={handleSubmit} width={300} height={45}>
        <Icon src='/images/sms.svg' />
        Update Password
      </StdButton>
    </Main>
  );
};

export default PasswordChange;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from '../Page.styles';
import { Main } from './EmailVerify.styles';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb.component';

const EmailVerifyPage = (props) => {
  const [timer, setTimer] = useState(5);
  const history = useHistory();

  const countdown = () => {
    setTimeout(() => {
      setTimer(timer - 1);
    }, 1000);
  };

  useEffect(() => {
    countdown();
  }, []);

  useEffect(() => {
    if (timer < 1) {
      history.push('/');
    } else {
      setTimeout(() => setTimer((prevTimer) => prevTimer - 1), 1000);
    }
  }, [timer]);

  return (
    <Container>
      <Breadcrumb />

      <Main>
        <h3>Thanks for registering with us!</h3>
        <h4>Your email has now been verified and you're ready to log in.</h4>
        <br />
        <h4>Redirecting to the Marketplace Homepage in {timer}s</h4>
      </Main>
    </Container>
  );
};

export default EmailVerifyPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container, Controls, Wrapper } from './TransactionList.styles';
import TransactionItem from '../TransactionItem/TransactionItem.component';
import { API } from 'aws-amplify';
import MessageBox from '../MessageBox/MessageBox.component';
import Auth from '@aws-amplify/auth';
import RoundButton from '../RoundButton/RoundButton.component';

const TransactionList = ({ userId }) => {
  const txLimit = 7;
  const [transactions, setTransactions] = useState(null);
  const [page, setPage] = useState(0);
  const [txCount, setTxCount] = useState(null);
  const [msg] = useState({
    visible: false,
    status: 'fail',
    msg: 'Unable to reach our server. Please try again later.',
  });

  useEffect(() => {
    fetchTransactions();
  }, []);

  async function fetchTransactions(skip = 0) {
    const apiName = 'marketplace';
    const path = `/order/user/${userId}`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: {
        sort: 'created=-1',
        skip: skip,
        limit: txLimit,
        count: true,
      },
    };

    try {
      const res = await API.get(apiName, path, myInit);
      setTxCount(res.data.count);
      setTransactions(res.data.data);
    } catch (err) {
      console.log({
        level: 'Warning',
        message: 'Unable to fetch user transactions',
        error: err,
      });
    }
  }

  const handlePrev = async (e) => {
    e.stopPropagation();
    if (page <= 0) return;
    let skip = txLimit * (page - 1);
    try {
      await fetchTransactions(skip);
      setPage((prev) => prev - 1);
    } catch (err) {
      console.log(err);
    }
  };

  const handleNext = async (e) => {
    e.stopPropagation();
    if (page * txLimit + 7 >= txCount) return;
    let skip = txLimit * (page + 1);
    try {
      await fetchTransactions(skip);
      setPage((prev) => prev + 1);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Wrapper>
      <Container>
        <h3>Transactions</h3>
        <MessageBox
          className={msg.visible ? 'visible' : null}
          status={msg.status}
          msg={msg.msg}
        />
        {transactions &&
          transactions.map((transaction) => (
            <TransactionItem key={transaction._id} transaction={transaction} />
          ))}
        {txCount > txLimit ? (
          <Controls>
            <RoundButton
              id='prev'
              height={40}
              width={40}
              rotate={180}
              margin='1rem 0'
              disabled={page === 0}
              onClick={handlePrev}
            />
            <p className='txDesc'>{`${page === 0 ? 1 : page * txLimit + 1} - ${
              page * txLimit + txLimit > txCount
                ? txCount
                : page * txLimit + txLimit
            } of ${txCount}`}</p>
            <RoundButton
              id='next'
              height={40}
              width={40}
              margin='1rem 0'
              disabled={page * txLimit + 7 >= txCount}
              onClick={handleNext}
            />
          </Controls>
        ) : null}
      </Container>
    </Wrapper>
  );
};

export default TransactionList;

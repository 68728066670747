import React from 'react';
import { Container } from './PageHeader.styles';

const PageHeader = (props) => {
  const { children } = props;
  return (
    <Container {...props}>
      <div className='content'>{children}</div>
    </Container>
  );
};

export default PageHeader;

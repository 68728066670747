import styled from 'styled-components';

let height = 578;
let width = 364;

if (window.screen.availWidth < 390) {
  width = 300;
}

export const Wrapper = styled.div`
  height: ${height * 1.02}px;
  width: ${width * 1.02}px;
  transition: all 750ms ease-out;

  &.hideCard {
    opacity: 0;
    transform: scale(0.1);
  }
  &.delete {
    display: none;
  }
`;

export const Container = styled.div`
  position: relative;
  min-height: ${(props) =>
    props.height ? `${props.height}px` : `${height}px`};
  width: ${(props) => (props.width ? `${props.width}px` : `${width}px`)};
  background: ${({ theme }) => theme.borderGradient};

  /* &:hover {
    ${({ theme }) => theme.productHover};
  } */
`;

export const Content = styled.div`
  position: absolute;
  align-items: center;
  min-height: ${(props) =>
    props.height ? `${props.height - 6}px` : `${height - 6}px`};
  width: ${(props) =>
    props.width ? `${props.width - 6}px` : `${width - 6}px`};
  inset: 3px;
  background: ${({ theme }) => theme.linearGradient};
  overflow: hidden;

  h3 {
    text-align: right;
    width: 100%;
  }
  .bg-img {
    height: 100%;
    left: 50%;
    transform: translateX(-25%);
  }

  .built-image {
    position: absolute;
    inset: 0;
  }

  .packs {
    position: absolute;
    inset: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;

    img {
      height: 350px;
    }
  }

  .action {
    position: absolute;
    z-index: 20;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
  .animation {
    top: 0;
    width: 358px;
    height: 572px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

    .fade {
      animation: fade 1500ms forwards ease-in-out 4500ms;
    }

    @keyframes fade {
      100% {
        opacity: 0;
      }
    }
  }
`;

export const PackImage = styled.img`
  z-index: 1;
  position: absolute;
  height: 250px !important;
  bottom: 50%;
  left: ${(props) => `${Math.round((18 / props.totalPacks) ** 2)}px`};
  transform: ${(props) =>
    ` rotateZ(${
      -10 + 5 * (props.offset - 1)
    }deg) translateY(50%) translateX(calc(-1% + (30 * ${props.offset}px)))`};
  transform-origin: bottom center;

  @media screen and (max-width: 555px) {
    left: ${(props) => `${Math.round((12 / props.totalPacks) ** 2)}px`};
  }
  @media screen and (max-width: 390px) {
    left: ${(props) => `${Math.round((1 / props.totalPacks) ** 2)}px`};
  }
`;

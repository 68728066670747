export default function validate(values) {
  let errors = {};
  if (!values.password) {
    errors.password = 'Your Current Password is Required.';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Invalid Password';
  }

  if (values.newPassword !== values.confirmPassword) {
    errors.newPassword = 'Passwords Do Not Match';
  }

  if (
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
      values.newPassword
    )
  ) {
    errors.newPassword = 'Invalid Password';
  }

  return errors;
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { Container, Main } from './ItemPage.styles';
import Spinner from '../../components/Spinner/Spinner';
import config from '../../config';
import NFTImage from '../../components/NFTImage/NFTImage.component';
import NFTInfo from '../../components/NFTInfo/NFTInfo.component';
import { fetchNFT, addToken } from '../../utils/api';
import ErrorModal from '../../components/ErrorModal/ErrorModal.component';
import { fromWei } from '../../utils/useWeb3';
import NFTPurchaseConfirmation from '../../components/NFTPurchaseConfirmation/NFTPurchaseConfirmation.component';
import { useHistory } from 'react-router-dom';

const ItemPage = (props) => {
  const [user, , walletAddress] = useContext(UserContext);
  const [nft, setNft] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [validMetadata, setValidMetadata] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [price, setPrice] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [complete, setComplete] = useState(false);

  const history = useHistory();

  const id = props.match.params.id;

  useEffect(() => getNftData(), []);

  async function getNftData() {
    setShowSpinner(true);
    try {
      let currentNFT = await fetchNFT(
        config.maticNetworkName,
        config.nftAddress,
        id
      );
      if (!currentNFT) {
        await addToken(config.maticNetworkName, config.nftAddress, id);
        currentNFT = await fetchNFT(
          config.maticNetworkName,
          config.nftAddress,
          id
        );
      }
      setNft(currentNFT);
      setValidMetadata(currentNFT?.tokenMetadata?.valid);
      if (currentNFT?.hasOrder) {
        const {
          signedOrder: { order },
        } = currentNFT;
        setPrice(fromWei(order.price));
      }
      setShowSpinner(false);
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
    }
  }

  const sendClose = () => {
    setConfirm(false);
    history.push({ pathname: '/profile', tab: '2' });
  };

  const toggleConfirmation = () => {
    setConfirm((prev) => !prev);
  };

  return (
    <Container>
      {networkError && (
        <ErrorModal
          title='Please change your chain'
          msg={`${config.maticNetworkName} is required.`}
        />
      )}
      {showSpinner && <Spinner />}
      {confirm && (
        <NFTPurchaseConfirmation
          sendClose={sendClose}
          nft={nft}
          setComplete={setComplete}
          complete={complete}
        />
      )}
      {nft && (
        <Main>
          <NFTImage id='image' img={validMetadata ? nft.tokenMetadata.image : '/images/no-image.svg'} price={price}>
            <div className='back subheader-2' onClick={() => history.goBack()}>&lt; Back</div>
          </NFTImage>
          <NFTInfo
            nft={nft}
            valid={validMetadata}
            id='info'
            nftId={id}
            user={user}
            walletAddress={walletAddress}
            setNetworkError={setNetworkError}
            toggleConfirmation={toggleConfirmation}
            toggleLoginModal={props.toggleLoginModal}
            setComplete={setComplete}
          />
        </Main>
      )}
    </Container>
  );
};

export default ItemPage;

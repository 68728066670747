import React from 'react';
import { Container } from './MessageBox.styles';

/*----------------------------------------------------
PROPS
  visible : boolean
  status: success || fail
  msg: 'msg to display'
*/

const MessageBox = (props) => {
  return (
    <Container {...props}>
      <h3>{props.msg}</h3>
    </Container>
  );
};

export default MessageBox;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  min-height: 75vh;
  background: ${({ theme }) => theme.bgColor};
  display: flex;
  flex-direction: column;

  > main {
    width: 100%;
  }
`;

export const Header = styled.header`
  width: 100%;
  position: relative;
  background: ${({ theme }) => theme.bgColor};
  border-bottom: ${({ theme }) => theme.borderMuted};
  padding: 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
  }

  .filterMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 8px;
    cursor: pointer;
    font: 600 .75rem/.8rem TTHoves;
    color: #667FDF;

    .close {
      margin-left: 5px;
      font-size: 9px;
    }
  }
`;

export const Section = styled.section`
  //min-height: 511px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem;
  gap: 3rem;
  background-color: ${({ theme }) => theme.background.light};
  border-radius: 1.5em;
  margin: ${({ margin }) => (margin ? margin : '.125rem 0')};
`;

import styled from 'styled-components';

export const Container = styled.div`
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.background.dark};
  width: 30%;
  flex-direction: column;
  align-items: flex-start;

  padding: 1rem;
  margin: 0 auto;

  .filter-body {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .filter-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 125px;

    ul {
      display: flex;
      list-style: none;
      padding: 5px 10px;
    }
    li {
      margin: .125rem 5px;
      padding: 5px 8px;
      font-size: 1em;
      letter-spacing: 1px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 15px;
      cursor: pointer;
      white-space: nowrap;
    }

    li.active {
      background-color: rgba(255, 255, 255, 0.5);
      color: black;
      border: 1px solid rgba(255, 255, 255, 0.9);
    }
  }

  h6 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2px;
    margin-bottom: 5px;
  }
  select {
    display: inline-block;
    margin: 0 1rem 0 0;
    background: none;
    border: ${({ theme }) => theme.borderMuted};
    border-radius: 4px;
    color: ${({ theme }) => theme.fontColor};
    padding: 8px;
    font-size: 12px;
  }

  option {
    background: ${({ theme }) => theme.primaryColor};
  }

  .toggle {
    display: none;
    font-size: 12px;
    margin-top: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 1265px) {
    flex-direction: column;
    align-items: flex-start;

    * {
      margin-top: .25rem;
    }

    .filter-body {
      display: ${({ showFilter }) => (showFilter ? 'block' : 'none')};
    }
    .filter-group {
      align-items: flex-start;
    }
    .toggle {
      display: block;
    }
  }
`;

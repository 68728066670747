import styled from 'styled-components';

export const Container = styled.div`
  width: 35%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .built-images {
    width: 100%;
    height: 50vh;
  }

  .built-image {
    height: 50vh;
  }`;

import React, { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { Container } from './Breadcrumb.styles';

const Breadcrumb = (props) => {
  const location = useLocation('');
  const basePath = [
    { pathname: 'https://locgame.io', state: { name: 'Home' } },
    { pathname: '/', state: { name: 'Marketplace' } },
  ];
  const [path, setPath] = useState(null);

  const capitalize = (s) => s.charAt(0).toUpperCase() + s.slice(1);

  useEffect(() => {
    const newPath = location.pathname.split('/');
    newPath.shift();
    setPath(newPath);
  }, [location]);

  return (
    <Container {...props}>
      <h5>
        <a href={basePath[0].pathname} alt='Locgame.io Home'>
          Home
        </a>
        &nbsp;<span> &#8250;</span> &nbsp;
        <NavLink
          exact
          to='/'
          activeStyle={{
            color: 'rgba(255,255,255,.4)',
          }}
        >
          Marketplace
        </NavLink>
        {path &&
          path.map((path, i, pathArr) => (
            <NavLink
              exact
              key={path}
              to={
                i === 0
                  ? `/${path === 'products' ? '' : path}`
                  : `/${pathArr[0]}/${path}`
              }
              activeStyle={{
                color: 'rgba(255,255,255,.4)',
              }}
            >
              &nbsp;<span> &#8250;</span>
              {` ${capitalize(path.replace('-', ' '))}`}
            </NavLink>
          ))}
      </h5>
    </Container>
  );
};

export default React.memo(Breadcrumb);

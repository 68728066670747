import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Container, Section } from './AllItems.styles';
import NFTCard from '../../components/NFTCard/NFTCard.component';
import useFilter from '../../utils/useFilter';
import { fetchAllListedNFTs } from '../../utils/api';
import Filters from '../../components/Filters/Filters.component';
import PageHeader from '../../components/PageHeader/PageHeader.component';

const AllItems = ({
  maticRate,
  hidePageHeader,
}) => {
  const sortOptions = {
    OLDEST_ORDERS: 'Newest Orders',
    NEWEST_ORDERS: 'Oldest Orders',
    PRICE_ASC: 'Lowest Price',
    PRICE_DESC: 'Highest Price',
    TOKEN_ID_ASC: 'Token Id',
  };
  const limit = 100;
  const [items, setItems] = useState([]);
  const [idx, setIdx] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const sectionRef = useRef();

  const observer = useRef();

  const { filters, setFilters, filteredData, resetFilters } = useFilter(
    items,
    'nft'
  );

  const lastNftRef = useCallback(
    (node) => {
      if (isLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting && hasMore) {
          setIdx((prev) => prev + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore]
  );

  useEffect(() => {
    setFilters((prev) => ({ ...prev, sort: 'OLDEST_ORDERS' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) return;
    setIsLoading(true);
    fetchAllListedNFTs({ from: idx * limit, limit }).then((data) => {
      setItems((prev) => [...prev, ...data]);
      if (data.length === 0) {
        setHasMore(false);
      }
    });
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idx]);

  useEffect(() => {
    setIsLoading(true);
    fetchAllListedNFTs({
      limit,
      orderBy: filters.sort,
    }).then((data) => {
      setItems(data);
      if (data.length === 0) {
        setHasMore(false);
      }
    });
    setIsLoading(false);
  }, [filters.sort]);

  return (
    <Container>
      {filteredData && (
        <>
          {!hidePageHeader && <PageHeader bgImage='https://static-files.locgame.io/images/market.jpg' />}
          <main>
            <Filters
              items={filteredData}
              filters={filters}
              setFilters={setFilters}
              resetFilters={resetFilters}
              sortOptions={sortOptions}
            />
            <Section ref={sectionRef}>
              {filteredData.length > 0 &&
                filteredData.map((item, i) => {
                  if (i === filteredData.length - 1)
                    return (
                      <NFTCard
                        nft={item}
                        rate={maticRate}
                        size='small'
                        containerRef={lastNftRef}
                        key={item.id}
                      />
                    );
                  else
                    return (
                      <NFTCard
                        nft={item}
                        rate={maticRate}
                        size='small'
                        key={item.id}
                      />
                    );
                })}
            </Section>
          </main>
        </>
      )}
    </Container>
  );
};

export default AllItems;

import React, { useRef } from 'react';
import { Container, BorderWrapper } from './ContactForm.styles';
import useForm from '../../utils/useForm';
import validate from './validate';
import config from '../../config';
import StyledInput from '../Input/Input.component';

const ContactForm = (props) => {
  const initialState = { name: '', email: '', message: '' };

  const { values, errors, handleChange, setErrors } = useForm(
    null,
    validate,
    initialState
  );
  const formRef = useRef();

  const handleForm = (e) => {
    e.preventDefault();
    let formErrors = validate(values);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      formRef.current.submit();
    }
  };

  return (
    <Container>
      <h3>Contact Us</h3>
      <p className='heading'>
        Please contact us with any questions or concerns not addressed above and
        a member of our support team will be happy to help.
      </p>
      <form
        action={`https://formsubmit.co/${config.contactEmail}`}
        method='POST'
        ref={formRef}
        onSubmit={handleForm}
        noValidate
      >
        <div className='formGroup'>
          <StyledInput
            type='text'
            name='name'
            id='name'
            value={values.name}
            errors={errors.name}
            placeholder='Name'
            onChange={handleChange}
            width='300px'
          />
        </div>
        <div className='formGroup'>
          <StyledInput
            type='email'
            name='email'
            id='email'
            placeholder='Email'
            value={values.email}
            errors={errors.email}
            onChange={handleChange}
            width='300px'
          />
        </div>
        <StyledInput
          type='textarea'
          name='message'
          id='message'
          placeholder='Enter a Message'
          value={values.message}
          errors={errors.message}
          onChange={handleChange}
          rows='10'
        />

        <input
          type='hidden'
          name='_next'
          value={`${config.rootURL}/contact-confirmation`}
        />
        <input
          type='hidden'
          name='_autoresponse'
          value='Thank you for contacting us. 
          Someone will respond as soon as possible. 
          
          LOCGame Team.'
        />
        <BorderWrapper margin='2rem auto 0' width={240}>
          <input type='submit' value='Send Message' className='ActionButton' />
        </BorderWrapper>
      </form>
    </Container>
  );
};

export default ContactForm;

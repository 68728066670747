import React from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapper, Modal, CloseBtn } from './ErrorModal.styles';

const ErrorModal = ({ title, msg, sendClose }) => {
  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal>
        {sendClose && <CloseBtn onClick={sendClose} />}
        <h3>{title}</h3>
        <h4>{msg}</h4>
      </Modal>
    </ModalWrapper>,
    document.getElementById('portal')
  );
};

export default ErrorModal;

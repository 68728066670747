/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Button2 from '../Button2/Button2.component';
import { Nav, Image, NavMenuDesktop, NavMenuMobile } from './Navbar.styles';
import UserMenu from '../UserMenu/UserMenu.component';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Icon } from '../Icons/BaseIcon';

const Navbar = (props) => {
  const { toggleLoginModal } = props;
  const [user] = useContext(UserContext);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const [showSubmenu, setShowSubmenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let query = location.search.slice(1);
      if (query === 'login') {
        toggleLoginModal();
      }
    }
  }, [location.search]);

  return (
    <Nav background={props.background}>
      <div className='wrapper'>
        <a href='https://market.locgame.io' alt='Locgame.io Marketplace' className='logo'>
          LOCG
        </a>
        <div className='right'>
          <div className='mobile-toggle' onClick={() => setShowMobileNav(true)}>
            <Icon
              className='nav-icon'
              src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618a8721fb3bf939d6f1f750_hamburger.png'
            />
          </div>
        </div>
        <NavMenuDesktop>
          <li className='nav-link'>
            <NavLink to='/' exact activeClassName='active'>Shop<div className='dot'></div></NavLink>
          </li>
          <li className='nav-link'>
            <NavLink to='/all-items' activeClassName='active'>Marketplace<div className='dot'></div></NavLink>
          </li>
          <li className='nav-link'>
            <NavLink to='/collect2earn' activeClassName='active'>Collect2Earn<div className='dot'></div></NavLink>
          </li>
          <li className='nav-link'>
            <NavLink to='/my-collection' activeClassName='active'>My Collection<div className='dot'></div></NavLink>
          </li>
          <li className='nav-link'>
            <NavLink to='/support' activeClassName='active'>Support<div className='dot'></div></NavLink>
          </li>
          <li className='nav-link'>
          <a href='https://locgame.io' alt='Locgame.io Home'>locgame.io</a>
          </li>
        </NavMenuDesktop>
        {!user ? (
          <div className='login-wrapper'>
            <NavLink to='/signup'>
              <div className='signup'>SIGN UP</div>
            </NavLink>
            <Button2 className='loginBtn' width='140' onClick={toggleLoginModal}>
              LOG IN
            </Button2>
          </div>
        ) : (
          <UserMenu
            user={user}
            username={`${user.name || ''}`}
          />
        )}
        {showMobileNav && (
          <NavMenuMobile>
            <div className='mobile-upper-wrapper'>
              <a href='https://locgame.io' alt='Locgame.io Home'>
                <Image src='/images/logo.png' alt='Logo' />
              </a>
              <div
                className='nav-close-icon'
                onClick={() => setShowMobileNav(false)}
              >
                <Icon src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618a32a0f5709f2e4d729b1c_010-cancel.svg' />
              </div>
            </div>
            <ul>
              <li className='nav-link'>
                <NavLink to='/' onClick={() => setShowMobileNav(false)}>Shop</NavLink>
              </li>
              <li className='nav-link'>
                <NavLink to='/all-items' onClick={() => setShowMobileNav(false)}>Marketplace</NavLink>
              </li>
              <li className='nav-link'>
                <NavLink to='/collect2earn' onClick={() => setShowMobileNav(false)}>Collect2Earn</NavLink>
              </li>
              <li className='nav-link'>
                <NavLink to='/my-collection' onClick={() => setShowMobileNav(false)}>My Collection</NavLink>
              </li>
              <li className='nav-link'>
                <NavLink to='/support' onClick={() => setShowMobileNav(false)}>Help</NavLink>
              </li>
              <li className='nav-link'>
                <NavLink to='https://locgame.io'>locgame.io</NavLink>
              </li>
              {!user ? (
                <div className='login-wrapper'>
                  {/* <NavLink to='/signup' className='signup'>
                    SIGN UP
                  </NavLink> */}
                  <Button2 className='loginBtn' onClick={toggleLoginModal}>
                    LOG IN
                  </Button2>
                </div>
              ) : (
                <UserMenu
                  user={user}
                  username={`${user.name || ''}`}
                />
              )}
              {/*<li
              className=' sub-heading '
              onClick={() => setShowSubmenu(!showSubmenu)}
            >
              <div className='sub-menu'>
                <p>Info</p>
                <Icon src='/images/line-arrow-right.svg' className='nav-icon' />
              </div>
            </li>
            {showSubmenu && (
              <ul className='sub-menu'>
                <li className='nav-link'>
                  <a
                    href='https://locgame.io/lore'
                    className='nav-drop-down-link'
                  >
                    The Lore
                  </a>
                </li>
                <li className='nav-link'>
                  <a
                    href='https://locgame.io/gamefeatures'
                    className='nav-drop-down-link'
                  >
                    Game Features
                  </a>
                </li>
                <li className='nav-link'>
                  <a
                    href='https://locgame.io/game-modes'
                    className='nav-drop-down-link'
                  >
                    Game Modes
                  </a>
                </li>
                <li className='nav-link'>
                  <NavLink
                    to='/library'
                    className='nav-drop-down-link'
                    onClick={() => setShowMobileNav(false)}
                  >
                    Card Library
                  </NavLink>
                </li>
                {/* <li className='nav-link'>
                  <NavLink to='/support' className='nav-drop-down-link'>
                    Support
                  </NavLink>
                </li> 
              </ul>
            )}*/}
            </ul>
          </NavMenuMobile>
        )}
      </div>
    </Nav>
  );
};

export default React.memo(Navbar);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import web3 from 'web3';
import { API } from 'aws-amplify';
import useForm from '../../utils/useForm';
import { Container } from '../Page.styles';
import { Main } from './EarlyAccessAwakening.styles';
import StyledInput from '../../components/Input/Input.component';
import StdButton from '../../components/StdButton/StdButton.component';
import Spinner from '../../components/Spinner/Spinner';

const EarlyAccessAwakening = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const irisKey = 'iris-wallet';
  const validateSignup = (values) => {
    const errors = {};
    if (!web3.utils.isAddress(values.wallet.toLowerCase())) errors.wallet = 'Invalid wallet address';
    return errors;
  };

  const onSubmit = async (values) => {
    localStorage.setItem(irisKey, values.wallet);
    setShowSpinner(true);
    const response = await API.post('marketplace', '/early-access-awakening', { body: { ...values } });
    if (response.success === true) {

    } else {
      setErrors({ ...errors, server: response.message });
    }
    setShowSpinner(false);
  };

  const { values, errors, handleSubmit, handleChange, setErrors, setValues } =
    useForm(onSubmit, validateSignup, {
      wallet: '',
      code: '',
      recaptcha: null,
    });

  useEffect(() => {
    const wallet = localStorage.getItem(irisKey);
    if (wallet) values.wallet = wallet;
  }, []);

  return (
    <Container>
      {showSpinner && <Spinner />}
      <Main>
        <div className='left'>
          <div className='heading-label'>AWAKENING SEASON</div>
          <h1 className='heading'>EARLY ACCESS</h1>
          <div className='server-response'>{errors.server}</div>
          <form onSubmit={onSubmit}>
            <StyledInput type='text' name='wallet' value={values.wallet} errors={errors.wallet} placeholder='Wallet Address' onChange={e => setValues({ ...values, wallet: e.target.value })} />
            <StyledInput type='text' name='code' value={values.code} errors={errors.code} placeholder='Code' onChange={e => setValues({ ...values, code: e.target.value })} />
            <ReCAPTCHA name="recaptcha" sitekey="6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj" onChange={recaptcha => setValues({ ...values, recaptcha })} />
            <StdButton onClick={handleSubmit} disabled={!values.wallet || !values.code || !values.recaptcha}>GET IRIS</StdButton>
          </form>
        </div>
        <div className='iris-right'>

        </div>
      </Main>
    </Container>);
};

export default EarlyAccessAwakening;

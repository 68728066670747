import React from 'react';
import { Container, PropGrid, Property } from './NFTPropsList.styles';

const NFTPropsList = (props) => {
  const { title, properties } = props;
  return (
    <Container>
      <h6>{title}</h6>
      <PropGrid>
        {properties &&
          properties.map(({ trait_type, value }, i) => (
            <Property key={i}>
              <div><span className='t-label'>{trait_type}: </span><span>{value}</span></div>
            </Property>
          ))}
      </PropGrid>
    </Container>
  );
};

export default NFTPropsList;

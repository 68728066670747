import React, { useState, useEffect } from 'react';
import { Container, Header, Main } from './SupportPage.styles';
import ContactForm from '../../components/ContactForm/ContactForm.component';
import FAQItem from '../../components/FAQItem/FAQItem.component';
import Search from '../../components/Search/Search.component';
import CategorySelector from '../../components/CategorySelector/CategorySelector.component';
import axios from 'axios';
import PageHeader from '../../components/PageHeader/PageHeader.component';
//import faqItems2 from '../../utils/faq.json';

const SupportPage = (props) => {
  const [filters, setFilters] = useState({
    gettingStarted: false,
    general: false,
    payment: false,
    user: false,
    search: '',
  });

  const [faqItems, setFaqItems] = useState(null);
  const [filteredItems, setFilteredItems] = useState(null);

  const fetchFAQ = async () => {
    try {
      const res = await axios.get('https://static-files.locgame.io/faq.json');
      setFaqItems(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchFAQ();
  }, []);

  useEffect(() => {
    if (faqItems) {
      let items = faqItems;
      if (filters.gettingStarted) {
        items = items.filter((item) => item.tags.includes('getting started'));
      }
      if (filters.general) {
        items = items.filter((item) => item.tags.includes('general'));
      }
      if (filters.payment) {
        items = items.filter((item) => item.tags.includes('payment'));
      }
      if (filters.user) {
        items = items.filter((item) => item.tags.includes('user'));
      }

      if (filters.search) {
        let re = new RegExp(filters.search, 'i');
        items = items.filter((item) => re.test(item.Question));
      }
      setFilteredItems(items);
    }
  }, [filters, faqItems]);

  useEffect(() => {
    if (props.location.state === 'buy') {
      setFilters({ ...filters, search: 'purchase ' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faqItems, props.location.state]);

  const handleClick = (category) => {
    const filter = {
      gettingStarted: false,
      general: false,
      payment: false,
      user: false,
      search: '',
    };
    if (!filters[category]) {
      filter[category] = true;
    }
    setFilters(filter);
  };

  return (
    <Container>
      <PageHeader bgImage="https://static-files.locgame.io/images/help.jpg" />
      <Header>
        <h2>How can we help you?</h2>
      </Header>
      <Main>
        <div className='selector-container'>
          <CategorySelector
            title='Getting Started'
            content='Get started with LOCGame'
            image='/images/shuttle.svg'
            current={filters.gettingStarted}
            onClick={() => handleClick('gettingStarted')}
          />
          <CategorySelector
            title='General'
            content=''
            image='/images/loupe.svg'
            current={filters.general}
            onClick={() => handleClick('general')}
          />
          <CategorySelector
            title='Payments and Tokens'
            content='Learn about tokens, payments, and transactions'
            image='/images/credit-cards.svg'
            current={filters.payment}
            onClick={() => handleClick('payment')}
          />
          <CategorySelector
            title='User Account'
            content='Setup or modify account settings'
            image='/images/man-user.svg'
            current={filters.user}
            onClick={() => handleClick('user')}
          />
        </div>
        <h3>FAQ</h3>
        <Search
          setFilters={setFilters}
          filters={filters}
          placeholder='Search FAQ'
          id='search-bar'
        />
        {filteredItems &&
          filteredItems.map((item) => (
            <FAQItem key={item._id['$oid']} item={item} />
          ))}
        <ContactForm />
      </Main>
    </Container>
  );
};

export default SupportPage;

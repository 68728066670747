import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  backdrop-filter: blur(4px);
  overflow: auto;
  @media screen and (max-height: 885px) {
    justify-content: flex-start;
  }
`;

export const Modal = styled.div`
  display: flex;
  position: relative;
  padding: 3rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #362e55;
  background-size: cover;
  width: 485px;
  height: 755px;
  overflow: none;

  @media screen and (max-width: 500px) {
    padding: 3rem 0.75rem;
    width: 100vw;
  }
`;

export const CloseBtn = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 13px;
  right: 17px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 14vh;
  padding: 0.75rem 0.25rem;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h2 {
    font-size: 30px;
    margin: 1rem 0;
  }

  h3 {
    margin-bottom: 2rem;
  }
  img {
    max-height: 186px;
    max-width: 186px;
  }

  .qty {
    font: 600 .75rem/.8rem TTHoves;
    margin-bottom: 2rem;
  }

  .hashTitle {
    font: 600 .75rem/.8rem TTHoves;
    opacity: 0.8;
    margin-bottom: 0.7rem;
  }

  .hash {
    font: 600 .75rem/.8rem TTHoves;
    margin-bottom: 2rem;
    width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 225px;
  }

  @media screen and (max-width: 500px) {
    .hash {
      width: 350px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.navHeight};
  padding: 6rem;

  .back {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.background.dark};
  }
`;

export const Main = styled.main`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
  max-width: 1200px;

  h2 {
    margin-bottom: 2.75rem;
  }

  @media screen and (max-width: 1135px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const Content = styled.div`
  width: 500px;
  position: relative;

  p {
    margin-bottom: 1.5rem;
  }
  p.heading {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .icon {
    height: 62px;
    width: 51px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.52);
    border-radius: 4px;
    margin: 0 12px 0 0;

    p.icon-text {
      font-size: 8px;
      color: rgba(255, 255, 255, 0.52);
    }
  }

  .reserve {
    height: 150px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin-bottom: 1.2rem;
  }
  @media screen and (max-width: 585px) {
    width: 350px;
  }
`;

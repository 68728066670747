import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Section } from './NFTInfo.styles';
import { Icon } from '../Icons/BaseIcon';
import config from '../../config';
import { getPacks, fetchNFTHistory } from '../../utils/api';
import { createDateString, splitCardId } from '../../utils/utils';
import { fromWei } from '../../utils/useWeb3';
import NFTPropsList from '../NftPropsList/NFTPropsList.component';
import MultiButton from '../MultiButton/MultiButton.component';
import Button2 from '../Button2/Button2.component';
const { nftAddress, maticNetworkName } = config;

const NFTInfo = ({
  nft,
  valid,
  nftId,
  user,
  walletAddress,
  setNetworkError,
  toggleConfirmation,
  toggleLoginModal,
  setComplete,
}) => {
  const [packs, setPacks] = useState(null);
  const [packId, setPackId] = useState(null);
  const [tokenHistory, setTokenHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('details');
  const [metadata, setMetadata] = useState({});

  const getRarityText = (num) => {
    switch (num) {
      case '002': return 'Prestige';
      case '003': return 'Elite';
      case '004': return 'Super Elite';
      case '005': return 'Legendary';
      default: return 'Standard';
    }
  };

  useEffect(() => {
    if (nftAddress.toLowerCase() !== nft.contractAddress.toLowerCase()) return;
    getPacks().then(({ values }) => setPacks(values));
    const { pack } = splitCardId(nft.tokenTokenId);
    setPackId(pack);
    const m = {};
    nft.tokenMetadata.attributes.map(e => (m[e.trait_type] = e.value));
    setMetadata(m);
    fetchNFTHistory(
      maticNetworkName,
      nft.contractAddress,
      nft.tokenTokenId
    ).then((data) => {
      setTokenHistory(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabSelect = (e) => {
    const { id: tabId } = e.target;
    setActiveTab(tabId);
  };
  return (
    <Container className='nft-info'>
      <Section>
        <h4>{valid ? nft.tokenMetadata.name : 'Invalid Metadata'}</h4>
        <p><span className='t-label'>Description:</span> {nft.tokenMetadata.description}</p>
        <div style={{ whiteSpace: 'nowrap' }}>
          <p><span className='t-label'>Edition:</span> {metadata['Edition Set']}</p>
          <p><span className='t-label'>Rarity:</span> {getRarityText(metadata['Visual Rarity'])}</p>
          <p><span className='t-label'>Owner:</span> {nft.tokenOwner}</p>
          <p><span className='t-label'>ID:</span> {nft.tokenTokenId}</p>
          <p><span className='t-label'>Contract:</span> {nft.contractAddress}</p>
        </div>
        {valid && (
          nftAddress.toLowerCase() === nft.contractAddress.toLowerCase() && (
            <div className='license'>
              <Icon
                src='/images/green-check.svg'
                height={17}
                width={17}
                margin='0 10px 0 0'
              />{' '}
              <span className='small-title'>Contract Verified</span>
            </div>
          )
        )}
        <div className='btns'>
          <MultiButton
            type="sell"
            nft={nft}
            nftId={nftId}
            user={user}
            walletAddress={walletAddress}
            setNetworkError={setNetworkError}
            toggleConfirmation={toggleConfirmation}
            toggleLoginModal={toggleLoginModal}
            setComplete={setComplete}
          />
          <NavLink to={`/transfer/${nftId}`}>
            <Button2>Transfer</Button2>
          </NavLink>
        </div>
      </Section>
      <div className='properties'>
        <Section>
          <NFTPropsList
            id='boost'
            properties={nft.tokenMetadata.attributes.filter(
              (item) => item.display_type
            )}
            title='Boost Numbers'
          />
        </Section>
        <Section>
          <NFTPropsList
            id='properties'
            properties={nft.tokenMetadata.attributes.filter(
              (item) => !item.display_type && item.trait_type !== 'Pack'
            )}
            title='Properties'
          />
        </Section>
      </div>
      <Section className='history'>
        {tokenHistory.map((tx) => {
          const date = createDateString(tx.timestamp);
          return (
            <div className='history-item' key={tx.id}>
              <span className='tx-details'>
                <p>Listed for {fromWei(tx.price, 'ether')} Matic</p>
                <p style={{marginLeft:'1em'}}>By <span className='seller'>{tx.seller}</span> {date}</p>
              </span>
            </div>
          );
        })}
      </Section>
    </Container>
  );
};

export default NFTInfo;

import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 1650px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  min-height: 700px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1650px) {
    min-height: unset;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    margin: 3rem 0 2rem;
    text-align: center;
  }
  p {
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 30px;
    }
  }
`;

import React from 'react';
import { Container } from './NFTImage.styles';

const NFTImage = ({ img, price, children }) => {
  return (
    <Container>
      {children}
      {price && <h3 className='price'>{price}</h3>}
      <img src={img} alt='NFT Card' />
    </Container>
  );
};

export default NFTImage;

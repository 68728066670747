/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Browser, Route, Switch } from 'react-router-dom';
import { API } from 'aws-amplify';
import { lightTheme, darkTheme, GlobalStyles } from './themes';
import Navbar from './components/Navbar/Navbar.component';
import EarlyAccessAwakening from './pages/EarlyAccessAwakening/EarlyAccessAwakening';
import Footer from './components/Footer/Footer.component';
import './App.css';
import useWeb3 from './utils/useWeb3';
import { UserContext } from './context/UserContext';
//import AuthGuard from './components/AuthGuard/AuthGuard';
import ProductPage from './pages/ProductPage/ProductPage';
import PasswordReset from './components/PasswordReset/PasswordReset.component';
import CardLibrary from './pages/CardLibrary/CardLibrary';
import Collect2Earn from './pages/Collect2Earn/Collect2Earn';
import { fetchCurrentRate } from './utils/api';
import config from './config';
import Amplify, { Auth, Hub } from 'aws-amplify';
import PasswordChange from './pages/PasswordChange/PasswordChange.component';
import PrivacyPage from './pages/PrivacyPage/PrivacyPage';
import ConfirmContactPage from './pages/ConfirmContactPage/ConfirmContact';
import ConfirmationModal from './components/ConfirmationModal/ConfirmationModal.component';
import SupportPage from './pages/Support/SupportPage';
import PackPage from './pages/PackPage/PackPage';
import ScrollToTop from './utils/scrollToTop';
import EmailVerifyPage from './pages/EmailVerifyPage/EmailVerify';
// import AdminPanel from './pages/AdminPanel/AdminPanel';
import ListingPage from './pages/ListingPage/ListingPage';
import TransferPage from './pages/TransferPage/TransferPage';
import ItemPage from './pages/ItemPage/ItemPage';
import { USER_REJECTED } from './constants';
import LoginPage from './pages/LoginPage/LoginPage.component';
import SignupPage from './pages/SignupPage/SignupPage.component';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import useIntersection from './utils/useIntersection';
import AllItems from './pages/AllItemsPage/AllItems';
import SacrificePage from './pages/SacrificePage/SacrificePage';
import MyItems from './pages/MyItemsPage/MyItems';

Amplify.configure(config.cognito);

function App() {
  const { web3, provider, setProvider } = useWeb3();
  const [theme, setTheme] = useState('dark');
  const [user, setUser, walletAddress, setWalletAddress] =
    useContext(UserContext);
  const [loginModal, setLoginModal] = useState(false);
  const [confirmation, setConfirmation] = useState(false);
  const [rate, setRate] = useState(null);
  const [maticRate, setMaticRate] = useState(null);
  const [declineWallet, setDeclineWallet] = useState(false);

  const [setRef, visible] = useIntersection({});

  const toggleLoginModal = () => {
    setLoginModal(!loginModal);
  };

  const getUser2 = async () => {
    const wallet = localStorage.getItem('wallet');
    const signature = localStorage.getItem('signature');
    const params = { headers: { Authorization: `Bearer ${signature}` } };
    const result = await API.get('marketplace', `/customer/${wallet}`, params);
    if (result.success) {
      const { _id, nonce, email, name } = result.customer;
      setWalletAddress(_id);
      setUser({ nonce, email, name });
    }
  }

  useEffect(() => {
    checkRate();
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
        case 'federatedSignIn':
          getUser2();
          break;
        case 'oAuthSignOut':
        case 'signOut':
          signOut();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log({ level: 'Warning', message: 'Sign in failure', data });
          break;
      }
    });

    getUser2();
  }, []);

  useEffect(() => {
    if (user && user.email !== 'testaccount@locgame.io') {
      setWalletAttribute();
    }
  }, [walletAddress]);

  useEffect(() => {
    if (web3 && user && !declineWallet) {
      fetchWallet();
    }
  }, [user, setWalletAddress]);

  function signOut() {
    // window.location.assign(config.cognito.oauth.redirectSignOut);
    setUser(null);
    setWalletAddress(null);
    localStorage.removeItem('token');
    localStorage.removeItem('signature');
    localStorage.removeItem('wallet');
  }

  async function setWalletAttribute() {
    const currentUser = await Auth.currentAuthenticatedUser();
    Auth.updateUserAttributes(currentUser, {
      'custom:wallet': walletAddress ? walletAddress.toLowerCase() : '',
    });
  }

  async function getUser() {
    const getAvatar = (user) => {
      if (user.attributes['custom:avatar'])
        return `http://static-files.locgame.io/public/${user.attributes['custom:avatar']}`;
      else if (user.attributes.picture) return user.attributes.picture;
      else return '/images/user.svg';
    };
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const roles =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      localStorage.setItem('token', token);
      let attributes = {
        _id: user.username,
        given_name:
          user.attributes['custom:firstName'] || user.attributes.given_name,
        family_name:
          user.attributes['custom:lastName'] || user.attributes.family_name,
        picture: getAvatar(user),
        email: user.attributes.email,
        roles,
        wishlist: user.attributes['custom:wishlist'],
      };
      if (attributes.wishlist) {
        attributes.wishlist = JSON.parse(attributes.wishlist);
      }
      setUser(attributes);
    } catch (err) {
      setUser(null);
    }
  }

  async function checkRate() {
    let rate = JSON.parse(localStorage.getItem('rate'));
    if (rate) {
      rate.timestamp = new Date(rate.timestamp);
      let currentDate = new Date();
      if (currentDate.getTime() - rate.timestamp.getTime() > 3600000) {
        fetchRate();
      } else {
        setRate(rate.rate);
        setMaticRate(rate.maticRate)
      }
    } else {
      fetchRate();
    }
  }

  async function fetchWallet() {
    try {
      if (window.ethereum)
        await provider.request({ method: 'eth_requestAccounts' });
      else if (provider !== config.ethRpc) {
        await provider.enable();
        setProvider(provider);
      }
      let accounts = await web3.eth.getAccounts();
      setWalletAddress(accounts[0].toLowerCase());
    } catch (err) {
      if (err.code === USER_REJECTED) {
        setDeclineWallet(true);
        return
      }
      console.log('Error', err.code);
    }
  }

  const themeToggler = () => {
    theme === 'light' ? setTheme('dark') : setTheme('light');
  };

  const fetchRate = async () => {
    const rate = {};
    rate.rate = await fetchCurrentRate('USDT', 'LOCG');
    rate.maticRate = await fetchCurrentRate("MATIC", 'USDT');
    if (rate.rate) {
      rate.timestamp = Date.now();
      setRate(parseFloat(rate.rate));
      setMaticRate(parseFloat(rate.maticRate));
      localStorage.setItem('rate', JSON.stringify(rate));
    }
  };

  const toggleConfirmation = () => {
    setConfirmation(!confirmation);
  };

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Browser>
        <ScrollToTop>
          <div className="topDiv" ref={setRef}></div>
          {loginModal && <LoginPage sendClose={toggleLoginModal} web3={web3} web3Provider={provider} />}
          <Navbar
            loginModal={loginModal}
            toggleLoginModal={toggleLoginModal}
            web3={web3}
            background={visible}
          />
          {confirmation && <ConfirmationModal sendClose={toggleConfirmation} />}
          <Switch>
            <Route
              path='/'
              exact
              render={(props) => <PackPage
                web3={web3}
                rate={rate}
                maticRate={maticRate}
                provider={provider}
                {...props} />}
            />
            <Route
              path='/collect2earn'
              render={(props) => <Collect2Earn {...props} web3={web3} />}
            />
            <Route
              path='/support'
              render={(props) => <SupportPage {...props} />}
            />
            <Route
              path='/signup'
              render={(props) => <SignupPage {...props} web3={web3} web3Provider={provider} />}
            />
            <Route
              path='/verify'
              exact
              render={(props) => <EmailVerifyPage {...props} />}
            />
            <Route
              path='/contact-confirmation'
              render={(props) => <ConfirmContactPage {...props} />}
            />
            <Route
              path='/privacy'
              render={(props) => <PrivacyPage {...props} />}
            />
            <Route
              path='/profile'
              exact
              render={(props) => (
                user && <ProfilePage
                  {...props}
                  web3={web3}
                  rate={rate}
                  maticRate={maticRate}
                  provider={provider}
                  setProvider={setProvider} />
              )}
            />
            <Route
              path='/library'
              render={(props) => <CardLibrary {...props} />}
            />
            <Route
              path='/all-items'
              render={(props) => <AllItems {...props} maticRate={maticRate} />}
            />
            {/* <Route
              path='/admin'
              render={(props) => (
                <AuthGuard toggleLoginModal={toggleLoginModal}>
                  {user && user.roles && user.roles.includes('Admin') && (
                    <AdminPanel />
                  )}
                </AuthGuard>
              )}
            /> */}
            <Route
              path='/products/:id'
              render={(props) =>
                web3 && (
                  <ProductPage
                    {...props}
                    toggleLoginModal={toggleLoginModal}
                    toggleConfirmation={toggleConfirmation}
                    rate={rate}
                    web3={web3}
                  />
                )
              }
            />
            <Route
              path='/list/:id'
              render={(props) =>
                web3 && (
                  <ListingPage
                    {...props}
                    toggleLoginModal={toggleLoginModal}
                    toggleConfirmation={toggleConfirmation}
                    rate={rate}
                    web3={web3}
                  />
                )
              }
            />
            <Route
              path='/sacrifice/:id'
              render={(props) =>
                web3 && (
                  <SacrificePage
                    {...props}
                    toggleLoginModal={toggleLoginModal}
                    toggleConfirmation={toggleConfirmation}
                    rate={rate}
                    web3={web3}
                  />
                )
              }
            />
            <Route
              path='/item/:id'
              render={(props) =>
                <ItemPage
                  {...props}
                  toggleLoginModal={toggleLoginModal}
                />
              }
            />
            <Route
              path='/password-reset'
              render={(props) => <PasswordReset />}
            />
            <Route path='/my-collection' render={() => <ProfilePage />} />
            <Route
              path='/profile/password-change'
              render={(props) => (<PasswordChange user={user} />)}
            />
            <Route
              path='/early-access-awakening'
              render={() => (<EarlyAccessAwakening />)}
            />
            {/* <Route
              path='/'
              exact
              render={(props) => (
                <Homepage
                  {...props}
                  rate={rate}
                  maticRate={maticRate}
                  toggleConfirmation={toggleConfirmation}
                  toggleLogin={toggleLoginModal}
                />
              )}
            /> */}
            <Route
              path='/transfer/:id'
              render={(props) =>
                web3 && (
                  <TransferPage
                    {...props}
                    toggleLoginModal={toggleLoginModal}
                    toggleConfirmation={toggleConfirmation}
                    rate={rate}
                    web3={web3}
                  />
                )
              }
            />
          </Switch>
          <Footer />
        </ScrollToTop>
      </Browser>
    </ThemeProvider>
  );
}

export default App;

import React from 'react';
import { Container } from '../Page.styles';
import { Main } from './ConfirmContact.styles';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb.component';

const ConfirmContactPage = (props) => {
  return (
    <Container>
      <Breadcrumb />

      <Main>
        <h3>Thanks for contacting us!</h3>
        <h4>Someone will be in touch shortly.</h4>
      </Main>
    </Container>
  );
};

export default ConfirmContactPage;

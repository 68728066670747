import styled from 'styled-components';

export const Slot = styled.div`
  display: flex;
  position: relative;
  background-color: #FFE499;
  border-radius: 1em;
  width: 20%;
  flex-direction: column;
  align-items: center;
  margin: 0 .25rem;
  text-transform: uppercase;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1.5rem;
    border-radius: 50%;
    color: #fff;
    width: 3rem;
    height: 3rem;
    border: 2px solid ${({ theme }) => theme.fontColor};
    background-color: ${({ theme }) => theme.background.dark};
  }
 
  img {
    aspect-ratio: 1/1;
    width: 100%;
    background-color: ${({ theme }) => theme.background.dark};
    border-radius: 1em;
    margin-bottom: 1.5rem;
    padding: .5rem;
  }

  button {
    background-color: ${({ theme }) => theme.background.dark};
    border-radius: 1.5em;
    font-family: 'Baron Neue', sans-serif;
    text-transform: uppercase;
    color: #fff;
    padding: .25rem 1rem;
    margin: 1.5rem;
    line-height: 120%;
  }

  @media screen and (max-width: 625px) {
    width: 100%;
    margin: 1rem 0;
  }
`;

export const Slots = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 3rem 0;

  @media screen and (max-width: 625px) {
    flex-direction: column;
  }
`;

export const PrizeCard = styled.div`
  background-color: ${(props) => props.cardBG};
  border: 2px solid ${({ theme }) => theme.background.dark};
  border-radius: 1em;
  width: 33.33333%;
  padding: 2rem;
  margin: 0 .5rem;
  position: relative;

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -1.5rem;
    border-radius: 50%;
    color: #fff;
    width: 3rem;
    height: 3rem;
    font-size: 1.2em;
    background-color: ${({ theme }) => theme.background.dark};
  }

  .card-body {
    margin: 1em 0;
  }
  .list {
    .list-item {
      border-bottom: 1px solid ${({ theme }) => theme.background.dark};
      padding: .75em 0;
      position: relative;
    }
    .list-item:last-child {
      border: none;
    }
  }

  @media screen and (max-width: 625px) {
    width: 100%;
    margin: .5rem 0;
  }
`;

export const PrizeCards = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;

  @media screen and (max-width: 625px) {
    flex-direction: column;
  }
`;

export const Section = styled.div`
  position: relative;
  display: flex;
  background-color: ${({ theme }) => theme.background.light};
  border-radius: 1.5em;
  margin: ${({ margin }) => (margin ? margin : '.125rem 0')};
  padding: ${({ padding }) => (padding ? padding : '2rem')};
  width: 100%;
  color: #111;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    max-width: 720px;
    text-align: center;
    margin: 3rem 0;
  }
`;

export const BlurWrapper = styled.div`
  text-align: center;
  position: relative;
  h1 {
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 100%;
    width: 100%;
    height: 100%;
  }
`;

export const Blurred = styled.div`
  opacity: 50%;
  filter: blur(5px);
  background-color: #ccc;
`;
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.background.light};
  border-radius: 1.5em;
  margin: .25rem 0;

  .back {
    position: absolute;
    top: 0;;
    left: 0;
    cursor: pointer;
  }
`;

export const Section = styled.section`
  width: 100%;
  padding: 3em;
  display: flex;
  flex-direction: column;
  align-items: center;

  .select {
    display: none;
    position: relative;
    width: 100%;
    max-width: 300px;
  }
  .select::after {
    content: '';
    position: absolute;
    height: 8px;
    width: 11px;
    background-image: url('/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
  }

  @media screen and (max-width: 750px) {
    .select {
      display: inline-block;
    }
  }
`;

export const Avatar = styled.div`
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%);
  border-radius: 50%;
  height: 120px;
  width: 120px;
  background: ${({ img }) => (img ? `url(${img})` : null)};
  background-size: contain;
  z-index: 2;
`;

export const Tabs = styled.ul`
  border: 2px solid ${({ theme }) => theme.background.dark};
  background-color: ${({ theme }) => theme.background.extraLight};
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;  
  border-radius: 1em;

  button {
    color: ${({ theme }) => theme.background.dark};
    padding: 1em;
    margin: .25rem;
    border-radius: 1em;
  }

  @media screen and (max-width: 750px) {
    display: none;
  }
`;

export const DropDown = styled.select`
  position: relative;
  width: 100%;
  max-width: 350px;
  appearance: none;
  border-radius: 6px;
  color: ${({ theme }) => theme.fontColor};
  padding: 12px 0 12px 10%;
  outline: none;
  cursor: pointer;
`;

import styled from 'styled-components';

export const ShadowDiv = styled.div`
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled.button`
  padding: .5rem .75rem;
  background-color: ${({ theme }) => theme.accent.shade1};
  border-radius: .5em;
  box-shadow: 2px 2px 0px 2px #664A00, 4px 4px 0px 2px #1B180E;
  border: 2px solid #140F00;

  &:hover {
    box-shadow: none;
    transform: translate(4px, 4px);
  }

  &:active {
    box-shadow: inset 2px 2px 0px 4px #664A00;
  }

  & .active {
    box-shadow: inset 2px 2px 0px 4px #664A00;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { UserContext } from '../../context/UserContext';
import { Auth, API } from 'aws-amplify';
import {
  SelectWrapper,
  Container,
  Content,
  ImageContainer,
  PackImage,
  PackInfo,
  ProductContainer,
  Section,
} from './ProductPage.styles';
import BuyButton from '../../components/BuyButton/BuyButton.component';
import { Span } from '../../components/BuyButton/BuyButton.styles';
import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/ErrorModal/ErrorModal.component';
import ButtonFlat from '../../components/Button2/ButtonFlat.component';
import { checkChainId } from '../../utils/utils';
import {
  applyDiscount,
  createPayment,
  fetchAllCurrentRates,
  fetchCurrentRate,
  getDiscount,
  getGasPrice,
  getMaxPriorityFee,
  getProductEditions,
  getProductTypes,
} from '../../utils/api';
import ABI from 'erc-20-abi';
import config from '../../config';
import { USER_REJECTED } from '../../constants';
import { WishIcon } from './ProductPage.styles';
import StdButton from '../../components/StdButton/StdButton.component';
import StyledInput from '../../components/Input/Input.component';
import ShareIcons from '../../components/ShareIcons/ShareIcons';

const ProductPage = (props) => {
  const web3 = props.web3;
  const [product, setProduct] = useState(null);
  const [user, setUser, walletAddress] = useContext(UserContext);
  const history = useHistory();
  const [purchaseQty, setPurchaseQty] = useState(1);
  const [currency, setCurrency] = useState('USDT');
  const [currencies, setCurrencies] = useState('');
  const [txHash, setTxHash] = useState('');
  const [rate, setRate] = useState(props.rate);
  const [rates, setRates] = useState();
  const [purchaseTotal, setPurchaseTotal] = useState('');
  const [showSpinner, setShowSpinner] = useState(false);
  const [balance, setBalance] = useState({ eth: 0, token: 0 });
  const [order, setOrder] = useState(null);
  const [buttonAttr, setButtonAttr] = useState({
    disabled: false,
    tooltip: 'Price is subject to change due to fluctuating exchange rate',
    label: 'BUY IT NOW',
    nsf: false,
  });
  const [networkError, setNetworkError] = useState(false);
  const [chainId, setChainId] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [productEditions, setProductEditions] = useState(null);
  const [wishlist, setWishlist] = useState(false);
  const [discountCode, setDiscountCode] = useState('');
  const [discount, setDiscount] = useState(null);
  const [dollarDiscount, setDollarDiscount] = useState(0);
  const [price, setPrice] = useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const id = props.match.params.id;
  const RECV_ACCT = config.recv_acct;
  let newOrder, action;

  async function getProduct() {
    if (!id) return;
    const apiName = 'marketplace';
    const path = `/product/${id}`;
    try {
      const data = await API.get(apiName, path);
      setProduct(data);
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to fetch product',
        error: err,
      });
    }
  }

  async function postOrder(orderInfo) {
    const apiName = 'marketplace';
    const path = '/order';
    const myInit = {
      body: orderInfo,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('signature')}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  async function deleteOrder(id) {
    const apiName = 'marketplace';
    const path = `/order/${id}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.del(apiName, path, myInit);
  }

  async function updateOrder(orderInfo) {
    const apiName = 'marketplace';
    const path = `/order/${order._id}`;
    const myInit = {
      body: orderInfo,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.put(apiName, path, myInit);
  }

  async function compileOrder() {
    try {
      let { nonce } = await web3.eth.getTransaction(txHash);
      await updateOrder({ nonce, txHash });
    } catch (err) {
      console.log(err);
    }
    setShowSpinner(false);
    handleConfirmation();
  }

  const getContract = () => {
    let contractAddress;
    if (currency === 'LOCG') {
      contractAddress = config.LOCGcontract;
    } else if (currency === 'USDT') {
      contractAddress = config.USDTcontract;
    }
    return new web3.eth.Contract(ABI, contractAddress);
  };

  const updateBalance = async (fromAddress) => {
    let currentRate = await fetchRate();
    setRate(currentRate);
    const contract = getContract();
    if (fromAddress !== undefined) {
      try {
        let value = await web3.eth.getBalance(fromAddress);
        value = web3.utils.fromWei(value, 'ether');
        let tokens;
        if (currency !== 'ETH') {
          tokens = await contract.methods.balanceOf(fromAddress).call();
          tokens = web3.utils.fromWei(
            tokens,
            currency === 'USDT' ? 'mwei' : 'ether'
          );
        } else {
          tokens = value;
        }
        setBalance({ eth: value, token: tokens });
      } catch (err) {
        console.log({
          level: 'Error',
          message: 'Unable to fetch user balance',
          error: err,
        });
      }
    } else {
      setBalance({ eth: 0, token: 0 });
    }
  };

  const handleChain = () => {
    checkChainId(web3, config.ethChainId, setNetworkError, setChainId);
  };

  useEffect(() => {
    getProductTypes()
      .then((data) => setProductTypes(data))
      .catch((err) => console.log(err));
    getProductEditions()
      .then((data) => setProductEditions(data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!web3.givenProvider || !web3.givenProvider.isMetaMask) return;
    handleChain();
    web3.eth.currentProvider.on('chainChanged', handleChain);
    return () => {
      web3.eth.currentProvider.removeListener('chainChanged', handleChain);
    };
  }, [chainId]);

  useEffect(async () => {
    if (!id) return;
    getProduct();
  }, [id]);

  useEffect(async () => {
    getCurrencies();
    if (product) {
      if (product.tags.includes('vip')) {
        if (!user || (user.roles && !user.roles.includes('investor'))) {
          history.push('/');
        }
      }
      if (product.soldQty >= product.initialQty) {
        setButtonAttr({
          disabled: true,
          tooltip: '',
          label: 'Sold out',
          nsf: true,
        });
      }
      if (user && user.wishlist) {
        if (user.wishlist.includes(product._id) && product._id !== null) {
          setWishlist(true);
        }
      }
    }
  }, [product, user]);

  useEffect(() => {
    if (walletAddress) updateBalance(walletAddress);
  }, [walletAddress, currency, networkError]);

  useEffect(() => {
    getCurrencies();
    if (!product || !walletAddress) return;
    let total;
    if (currency === 'LOCG') {
      total = Math.ceil(price * purchaseQty * rates?.LOCG?.price).toString();
    }
    if (currency === 'USDT') {
      total = (price * purchaseQty).toFixed(2).toString();
    } else if (currency === 'ETH') {
      total = (price * purchaseQty * rates?.ETH?.price).toFixed(18).toString();
    }
    if (product.soldQty >= product.initialQty) {
      setButtonAttr({
        disabled: true,
        tooltip: '',
        label: 'Sold out',
        nsf: true,
      });
    } else if (balance.token < parseFloat(total)) {
      setButtonAttr({
        disabled: true,
        tooltip:
          'Insufficient funds to complete transaction. Please add LOCG to your Wallet.',
        label: 'Insufficient Funds',
        nsf: true,
      });
    } else {
      setButtonAttr({
        disabled: false,
        tooltip: 'Price is subject to change due to fluctuating exchange rate.',
        label: 'BUY IT NOW',
        nsf: false,
      });
    }
  }, [product, walletAddress, balance, rate, purchaseQty, price]);

  useEffect(() => {
    if (txHash) {
      compileOrder();
    }
  }, [txHash]);

  useEffect(() => {
    let discountPrice;
    if (!product) return;
    if (discount?.type !== 'dollar') {
      discountPrice = applyDiscount(product.price, discount);
      setPrice(discountPrice);
      setDollarDiscount(0);
    }
    if (discount?.type === 'dollar') {
      setDollarDiscount(parseFloat(discount.value));
      setPrice(product.price);
      discountPrice = product.price;
    }
    switch (currency) {
      case 'LOCG':
        setPurchaseTotal(
          Math.ceil(
            (purchaseQty * discountPrice - dollarDiscount) * rates?.LOCG.price
          )
        );
        break;
      case 'ETH':
        setPurchaseTotal(
          (
            (purchaseQty * discountPrice - dollarDiscount) *
            rates?.ETH.price
          )?.toFixed(6)
        );
        break;
      default:
        setPurchaseTotal(
          (purchaseQty * discountPrice - dollarDiscount)?.toFixed(2)
        );
        break;
    }
  }, [discount, product, dollarDiscount, rate, purchaseQty, currency]);

  const toggleWishlist = async (e) => {
    e.preventDefault();
    if (!user) return;
    const currentUser = await Auth.currentAuthenticatedUser();
    if (wishlist) {
      let newList = user.wishlist.filter(
        (item) => item !== product._id && item
      );
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    } else {
      let newList;
      if (user.wishlist) {
        newList = [...user.wishlist, product._id];
      } else {
        newList = [product._id];
      }
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    }
  };

  const handleConfirmation = () => {
    props.toggleConfirmation();
    history.push({
      pathname: '/',
      order: {
        txHash,
        product: product,
        qty: purchaseQty,
      },
    });
  };

  const sendOrder = async (currency) => {
    let order = {
      product,
      qty: purchaseQty,
      currency,
      discountCode,
    };

    try {
      return await postOrder(order);
    } catch (err) {
      console.log(order);
      console.log(err.message);
      console.log({
        level: 'Warning',
        message: 'Failed to Post Order.',
      });
    }
  };

  const sendTokens = async (amount) => {
    const contract = getContract();
    let tokens = web3.utils.toWei(
      amount,
      currency === 'USDT' ? 'mwei' : 'ether'
    );

    // Transfer Tokens from users current wallet to receiver account
    try {
      const gasInGwei = await getGasPrice('ethereum');
      const VALUE_FROM_GAS_STATION = web3.utils.toWei(
        gasInGwei.toString(),
        'gwei'
      );
      const maxPriorityFeePerGas = await getMaxPriorityFee('ethereum');

      await contract.methods
        .transfer(RECV_ACCT, tokens)
        .send({
          from: walletAddress,
          maxFeePerGas: VALUE_FROM_GAS_STATION,
          maxPriorityFeePerGas,
        })
        .on('transactionHash', (txHash) => setTxHash(txHash));
    } catch (err) {
      if (err.code === USER_REJECTED) {
        await deleteOrder(newOrder._id);
        setShowSpinner(false);
        return;
      }
      setShowSpinner(false);
      console.log({
        level: 'Error',
        message: 'Unable to send funds.',
        error: err,
      });
    }
  };

  const sendETH = async (amount) => {
    let tokens = web3.utils.toWei(amount, 'ether');

    // Transfer Tokens from users current wallet to receiver account
    try {
      const gasInGwei = await getGasPrice('ethereum');
      const VALUE_FROM_GAS_STATION = web3.utils.toWei(
        gasInGwei.toString(),
        'gwei'
      );

      const maxPriorityFeePerGas = await getMaxPriorityFee('ethereum');

      await web3.eth
        .sendTransaction({
          from: walletAddress,
          to: RECV_ACCT,
          value: tokens,
          maxFeePerGas: VALUE_FROM_GAS_STATION,
          maxPriorityFeePerGas,
        })
        .on('transactionHash', (txHash) => setTxHash(txHash));
    } catch (err) {
      if (err.code === USER_REJECTED) {
        await deleteOrder(newOrder._id);
        setShowSpinner(false);
        return;
      }
      setShowSpinner(false);
      console.log({
        level: 'Error',
        message: 'Unable to send funds.',
        error: err,
      });
    }
  };

  const fetchRate = async () => {
    const newRate = await fetchCurrentRate('USDT', currency);
    return parseFloat(newRate);
  };

  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return {
          valid: false,
          errorMessage: 'ExecuteRecaptchaNotYetAvailable',
        };
      }
      const token = await executeRecaptcha(action);
      const result = await API.post('marketplace', '/recaptcha', {
        body: { token, action },
      });
      return result;
    } catch (err) {
      console.log(err);
      return { valid: false, errorMessage: err };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePurchase = async () => {
    if (!user) {
      props.toggleLoginModal();
      return;
    }
    if (walletAddress) {
      try {
        action = 'buyWithCrypto';
        const recaptcha = await handleReCaptchaVerify();
        if (!recaptcha || !recaptcha.valid) console.log(recaptcha.errorMessage);
        let currentRate = await fetchRate();
        let total;
        if (currency === 'LOCG') {
          total = Math.ceil(
            (price * purchaseQty - dollarDiscount) * currentRate
          ).toString();
        }
        if (currency === 'USDT') {
          total = (price * purchaseQty - dollarDiscount).toFixed(2).toString();
        } else if (currency === 'ETH') {
          total = ((price * purchaseQty - dollarDiscount) * currentRate)
            .toFixed(18)
            .toString();
        }
        setPurchaseTotal(total);
        window.scrollTo(0, 0);
        setShowSpinner(true);
        newOrder = await sendOrder(currency);
        setOrder(newOrder);
        if (currency === 'ETH') {
          sendETH(total);
        } else {
          sendTokens(total);
        }
      } catch (err) {
        setShowSpinner(false);
        console.log({
          level: 'Error',
          message: 'Unable to fetch exchange rate.',
          error: err,
        });
      }
    } else {
      history.push({ pathname: '/profile', tab: 'wallet' });
    }
  };

  const getPurchaseQuantities = () => {
    let content = [];
    for (let i = 1; i <= product.maxPurchase; i++) {
      content.push(
        <option
          value={i}
          key={i}
          disabled={product.soldQty + i > product.initialQty}
        >
          {i === 1 ? `1  Pack` : ` ${i}  Packs`}
        </option>
      );
    }
    return content;
  };

  const getCurrencies = async () => {
    if (!price) return;
    const apiName = 'marketplace';
    const path = '/enum/currency';
    let currencies = await API.get(apiName, path);
    currencies = currencies.values;
    let rates = await fetchAllCurrentRates('USDT', 'LOCG,ETH');
    setRates(rates);

    currencies = currencies.map((key, i) => {
      if (key === 'LOCG') {
        return (
          <option value={key} key={key}>
            {`${Math.ceil(price * rates.LOCG.price)} ${currencies[i]} / Pack`}
          </option>
        );
      } else if (key === 'ETH') {
        return (
          <option value={key} key={key}>
            {`${(price * rates.ETH.price).toFixed(4)} ${currencies[i]} / Pack`}
          </option>
        );
      } else {
        return (
          <option value={key} key={key}>
            {`${price} ${currencies[i]} / Pack`}
          </option>
        );
      }
    });
    setCurrencies(currencies);
  };

  const buildImage = () => {
    let content = [];
    if (!product.images[2]) {
      return <img src='/images/multi-pack-soon.png' alt='packaging' />;
    }
    for (let i = 1; i <= purchaseQty; i++) {
      content.push(
        <PackImage
          totalPacks={purchaseQty}
          src={product.images[2]}
          alt='Packaging'
          offset={i}
          key={i}
        />
      );
    }
    return <div className='built-images'>{content}</div>;
  };

  const handleCCOrder = async (e) => {
    e.preventDefault();
    if (!user) {
      history.push('/?login');
      return;
    }
    if (!walletAddress) {
      history.push({ pathname: '/profile', tab: '5' });
      return;
    }
    try {
      action = 'buyWithCreditCard';
      const recaptcha = await handleReCaptchaVerify();
      if (!recaptcha || !recaptcha.valid) console.log(recaptcha.errorMessage);
      const order = await sendOrder('USD C/C');
      const res = await createPayment({
        orderId: order._id,
        email: user.email,
        iduser: user._id,
        productType: product.productType,
      });
      if (res.status > 201) {
        //Log Error delete order
        await deleteOrder(order._id);
        throw Error('Unable to process credit card order');
      }
      window.location.href = res.url;
    } catch (err) {
      console.log(err);
    }
  };

  const handleDiscount = async () => {
    if (!discountCode) {
      setDiscount({});
      return;
    }
    try {
      const data = await getDiscount(discountCode);
      setDiscount(data);
    } catch (err) {
      console.log(err);
      setDiscount({});
    }
  };

  return (
    <Container>
      {showSpinner && (
        <Spinner
          msg1={`Fetching current exchange rate.`}
          msg2={`Purchase Price: ${purchaseTotal} LOCG`}
        />
      )}
      {networkError && (
        <ErrorModal
          title='Please change you chain.'
          msg={`${config.ethNetworkName} is required to make transactions`}
        />
      )}
      {product && (
        <Content>
          <ProductContainer>
            <ImageContainer>
              <div className='back subheader-2' onClick={() => history.goBack()}>&lt; Back</div>
              {purchaseQty === 1 ? (
                <div className='built-image'>
                  <PackImage
                    src={
                      product?.images[2]
                        ? product.images[2]
                        : '/images/multi-pack-soon.png'
                    }
                    alt='Product'
                  />
                </div>
              ) : (
                buildImage()
              )}
            </ImageContainer>
            <PackInfo>
              <Section>
                <h4>{product.title}</h4>
                <p><span className='t-label'>Description:</span> {product?.description}</p>
                <p><span className='t-label'>Rarity:</span> {product?.rarity}</p>
                <p><span className='t-label'>Edition:</span> {product?.edition}</p>
              </Section>
              <Section>
                <div className='checkout-container'>
                  <div className='control-group'>
                    <span className='t-label'>Select Quantity</span>
                    <SelectWrapper>
                      <select name='qty' id='qty' onChange={(e) => setPurchaseQty(parseInt(e.target.value))}>
                        {getPurchaseQuantities()}
                      </select>
                    </SelectWrapper>
                  </div>
                  <div className='control-group'>
                    <span className='t-label'>Choose Your Currency</span>
                    <SelectWrapper>
                      <select
                        name='currency'
                        id='currency'
                        onChange={(e) => setCurrency(e.target.value)}
                        value={currency}
                      >
                        {currencies && currencies}
                      </select>
                    </SelectWrapper>
                  </div>
                </div>
                <div className='checkout-container'>
                  <div className='s-control-group'>
                    <div className='control-group'>
                      <span className='t-label'>Promo Code</span>
                      <StyledInput id='discountCode' name='discountCode' value={discountCode} type='text' onChange={(e) => setDiscountCode(e.target.value)} errors={discount?.error} />
                    </div>
                    <ButtonFlat onClick={handleDiscount}>Apply</ButtonFlat>
                  </div>
                  <div className='control-group'>
                    <span className='t-label'>Final Price</span>
                    <div className='total subheader-2'>{`${purchaseTotal} ${currency}`}</div>
                  </div>
                </div>
                <BuyButton
                  animate
                  onClick={handlePurchase}
                  tooltip={buttonAttr.tooltip}
                  nsf={buttonAttr.nsf}
                  disabled={showSpinner || !rate || buttonAttr.disabled}
                >
                  {rate ? (
                    <span>{buttonAttr.label}</span>
                  ) : (
                    'Unable to fetch conversion rate. Please try again later.'
                  )}
                </BuyButton>
                {/* <StdButton
                  width='90%'
                  height='40'
                  margin='2rem 0 0'
                  onClick={handleCCOrder}
                >
                  BUY WITH CREDIT CARD
                </StdButton> */}
              </Section>
              <Section>
                <div className='description'>
                  <span className='t-label'>Details:</span>
                  <p>{product?.descriptionLong}</p>
                </div>
              </Section>
              {/* <div className='row'>
                <WishIcon
                  className='wish-icon'
                  inList={wishlist}
                  onClick={toggleWishlist}
                />
                <p className='small-heading'>Favorite</p>
                <ShareIcons
                  title='LOCGame Apollo Sale On Now'
                  url={`https://market.locgame.io${props.location.pathname}`}
                  // url={`${config.rootURL}${props.location.pathname}`}
                  twitterHandle='LOCgameio'
                  tags={['TooTheMoon', 'ApolloSale', 'LOCGame']}
                />
              </div>
              <p className='med-heading'>Item Description</p>
              <p className='description'>{product.description}</p> */}
            </PackInfo>
          </ProductContainer>
        </Content>
      )}
    </Container>
  );
};

export default ProductPage;

import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.background.dark};
  display: flex;
`;

export const Hero = styled.div`
  height: 955px;
  width: 553px;
  background: url('/images/login-hero.jpg');
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 4rem 0 6rem;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background: linear-gradient(
      180deg,
      rgba(81, 64, 190, 0.75) 0%,
      rgba(149, 74, 243, 0.75) 100%
    );
  }

  img {
    width: 144px;
    margin-bottom: 5rem;
    z-index: 2;
  }

  h2 {
    z-index: 2;
  }
  p {
    font-family: 'TTHoves';
    font-size: 20px;
    font-weight: 400;
    line-height: 1.5rem;
    margin-top: 3.5rem;
    z-index: 2;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.navHeight};
  padding-left: 8.3rem;
  justify-content: center;

  .tag {
    width: 500px;
    font-size: 20px;
    line-height: 24px;
    margin: 2rem 0;
  }
  .tag2 {
    width: 500px;
    font-size: 14px;
    line-height: 16.5px;
    margin-bottom: 2rem;
  }

  .title {
    font-size: 20px;
    margin: 0.5rem 0;
  }
  .logo {
    width: 80px;
    margin-bottom: 4rem;
  }

  p {
    line-height: 22px;
    width: 300px;

    &.pass {
      font-size: 12px;
      font-weight: 300;
      line-height: 18px;
    }

    &.msg {
      max-width: 300px;
      font-size: 14px;
      color: ${({ theme }) => theme.errorColor};
      margin: 0.75rem 0;
    }

    &.email {
      font-size: 16px;
      line-height: 18.9px;
      margin-top: 1rem;
    }

    span.change-link {
      color: rgba(102, 127, 223, 1);
      font-size: 14px;
      margin-left: 1rem;
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .split {
    width: 300px;
    height: 1px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    p.split {
      width: 70px;
      height: 40px;
      background: ${({ theme }) => theme.background.dark};
      border: none;
      z-index: 10;
    }
  }

  .resend {
    cursor: pointer;
    font-size: 16px;
  }

  .pass-container {
    width: 300px;
  }
  .socials {
    display: flex;
    width: 300px;
    justify-content: space-between;

    button {
      font-size: 12px !important;
      border: 1px solid white;
      background: rgba(255, 255, 255, 0.03);
    }
  }

  .footer {
    width: 300px;
    text-align: center;
    font-size: 14px;
    margin-top: 0.5rem;
  }
  .link,
  a.link {
    color: ${({ theme }) => theme.fontMuted};
    margin: 0 0.25rem;
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
  }
  .link-plain {
    color: ${({ theme }) => theme.fontMuted};
    margin: 0 0.25rem;
    font-weight: 300;
  }
  .terms-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
  }
  #terms,
  #news {
    display: inline-block;
    margin: 0 1rem 0 0;
    padding: 0;
    width: 12px;
    height: 12px;
  }

  label.terms {
    width: 270px;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    line-height: 1.2;
    letter-spacing: 0.9px;
  }

  .btns {
    display: flex;
    justify-content: space-between;
    width: 300px;
  }

  @media screen and (max-width: 1195px) {
    padding-left: 5rem;
    margin: 2rem 0;
    .tag {
      width: 400px;
    }

    @media screen and (max-width: 1023px) {
      width: 100%;
      align-items: center;
      padding-left: unset;
      margin: 2rem 0;
    }
    @media screen and (max-width: 600px) {
      .tag {
        width: 300px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  max-width: 1000px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.background.dark};
  padding: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;

  h3 {
    margin-bottom: 2rem;
  }

  .heading {
    line-height: 1.5rem;
    max-width: 500px;
    text-align: center;
    margin-bottom: 4rem;
  }

  form {
    width: 100%;
    max-width: 625px;
  }

  input {
    display: block;

    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  textarea {
    display: block;
    width: 100%;
    max-width: 624px;
    height: 150px;
  }

  .ActionButton {
    display: flex;
    margin: 0;
    outline: none;
    position: absolute;
    inset: 2px;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    height: 40px;
    width: 236px;
    border: none;
    background: ${({ theme }) => theme.mainGradient};
    color: #fff;
    cursor: pointer;
  }

  .formGroup {
    display: inline-block;
    vertical-align: top;

    &:nth-child(1) {
      margin-right: 1.5rem;
    }
    @media screen and (max-width: 500px) {
      &:nth-child(1) {
        margin-right: 0;
      }
    }
  }
`;

export const Message = styled.div`
  position: absolute;
  top: 180px;
  left: 50%;
  transform: translateX(-50%);

  &.success {
    color: ${({ theme }) => theme.successColor};
  }
  &.fail {
    color: ${({ theme }) => theme.errorColor};
  }
`;

export const BorderWrapper = styled.div`
  position: relative;
  margin: ${(props) => (props.margin ? props.margin : '0.8rem 0')};
  height: 44px;
  width: ${(props) => (props.width ? `${props.width}px` : `$null`)};
  background: ${({ theme }) => theme.borderGradient};
  &:hover {
    transform: ${(props) => (props.disabled ? null : 'scale(97%)')};
  }
`;

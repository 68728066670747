export default function validate(values) {
  let errors = {};
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid Email is Required.';
  }
  if (!values.code) {
    errors.code = 'A Valid Code is Required';
  }

  if (
    !/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/.test(
      values.password
    )
  ) {
    errors.password = 'Invalid Password';
  }
  if (values.password !== values.confirmPassword) {
    errors.password = 'Passwords do not match.';
  }
  return errors;
}

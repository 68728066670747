/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Web3 from 'web3';
import { API } from 'aws-amplify';
import { Container } from '../Page.styles';
import { UserContext } from '../../context/UserContext';
import { Slot, Slots, PrizeCard, PrizeCards, Section, Blurred, BlurWrapper } from './Collect2Earn.styles';
import nftABI from '../../utils/LOCGamePlayNFT.abi.json';
import config from '../../config';
import StdButton from '../../components/StdButton/StdButton.component';
import Button from '../../components/Button/Button.component';

const Collect2Earn = () => {
  const [user, , walletAddress] = useContext(UserContext);
  const [slots, setSlots] = useState([]);
  const [nfts, setNfts] = useState();
  const [claimPrize, setClaimPrize] = useState();
  const getId = (tokenId) => tokenId.slice(1, 4) + '-' + tokenId.slice(4, 7) + '-' + tokenId.slice(7, 10) + '-' + tokenId.slice(10, 13) + '-' + tokenId.slice(13, 16);

  useEffect(async () => {
    try {
      const response = await API.get('marketplace', '/collect2earn');
      if (response.success) {
        const { required } = response.result;
        const tmpSlots = [];
        let slotsFilled = 0;
        console.log(nfts);
        if (nfts)
          for (let i = 0, il = required.length; i < il; i++) {
            const filled = nfts.filter(e => required[i].id === getId(e)).length > 0;
            if (filled) slotsFilled++;
            tmpSlots.push(<Slot key={required[i].id}>
              <span className="material-symbols-outlined">{filled ? 'check' : 'lock'}</span>
              <img src={required[i].image} />
              <h6>{required[i].name}</h6>
              <button>card</button>
              {!filled && <Button>Go to Store</Button>}
            </Slot>);
          }
        setSlots(tmpSlots);
        if (slotsFilled >= 5)
          setClaimPrize(<StdButton>Claim Prize</StdButton>);
      }
    } catch (err) {
      console.log(err);
    }
  }, [nfts]);

  useEffect(async () => {
    try {
      if (walletAddress && !nfts) {
        const web3 = new Web3(config.maticRpc[0]);
        const contract = new web3.eth.Contract(nftABI, config.nftAddress);
        const res = await contract.methods.tokensOfOwner(walletAddress).call();
        setNfts(res);
      }
    } catch (err) {
      console.log(err);
    }
  }, [user, slots]);

  return (<Container>
    <BlurWrapper>
      <h1>Coming Soon</h1>
      <Blurred>
        <Section>
          <h4>fill all 5 slots to get prize 🎁</h4>
          <p>Slots will automatically be filled with corresponding items from your collection. You may buy additional items for your collection from the store.</p>
          {!walletAddress && <p>Please sign in to participate in this event</p>}
          <Slots>
            {slots.length > 0 && slots}
          </Slots>
          {claimPrize}
        </Section>
        <Section>
          <h4>prizes</h4>
          <p>Depending on the conditions met, you will receive a prize in accordance with the table below. Conditions are updated every week.</p>
          <PrizeCards>
            <PrizeCard cardBG="#95C086">
              <span>👍</span>
              <h6>4 of 5</h6>
              <div className='card-body'>Collect 4 of 5 slots in a week and get the prize:</div>
              <div className='list'>
                <div className='list-item'><span className='subheader-2'>400</span> LOCG</div>
                <div className='list-item'><span className='subheader-2'>800</span> LC</div>
                <div className='list-item'><span className='subheader-2'>LOTTERY ticket</span> X1</div>
              </div>
            </PrizeCard>
            <PrizeCard cardBG="#86C0BD">
              <span>💪</span>
              <h6>All</h6>
              <div className='card-body'>Collect 5 of 5 slots in a week and get the prize:</div>
              <div className='list'>
                <div className='list-item'><span className='subheader-2'>600</span> LOCG</div>
                <div className='list-item'><span className='subheader-2'>1200</span> LC</div>
                <div className='list-item'><span className='subheader-2'>LOTTERY ticket</span> X1</div>
              </div>
            </PrizeCard>
            <PrizeCard cardBG="#A894C7">
              <span>😎</span>
              <h6>all twice</h6>
              <div className='card-body'>Collect 5 of 5 slots 2 weeks in a row and get an additional prize to weekly prize:</div>
              <div className='list'>
                <div className='list-item'><span className='subheader-2'>400</span> LOCG</div>
                <div className='list-item'><span className='subheader-2'>800</span> LC</div>
                <div className='list-item'><span className='subheader-2'>LOTTERY ticket</span> X2</div>
              </div>
            </PrizeCard>
          </PrizeCards>
        </Section>
      </Blurred>
    </BlurWrapper>
  </Container>);
};

export default Collect2Earn;

import React, { useState, useEffect, useContext } from 'react';
import { Container, Section } from './PackPage.styles';
import useFilter from '../../utils/useFilter';
import Filters from '../../components/Filters/Filters.component';
import { getProducts } from '../../utils/api';
import ProductCard from '../../components/ProductCard/ProductCard.component';
import { UserContext } from '../../context/UserContext';
import Timer from '../../components/Timer/Timer.component';
import PageHeader from '../../components/PageHeader/PageHeader.component';
import config from '../../config';
import apolloVideo from '../../video/apollo.mp4';
import FilterButton from '../../components/Button2/FilterButton.component';

const PackPage = ({
  hidePageHeader,
  maticRate,
  rate,
  toggleConfirmation,
  toggleLoginModal,
}) => {
  const sortOptions = {
    NEWEST: 'Newest First',
    OLDEST: 'Oldest First',
    PRICE_ASC: 'Lowest Price',
    PRICE_DESC: 'Highest Price',
  };
  const [showFilter, setShowFilter] = useState(true);
  const [products, setProducts] = useState([]);
  const [newProducts, setNewProducts] = useState([]);
  const [vipProducts, setVipProducts] = useState([]);
  const [timestamp, setTimestamp] = useState(config.saleTimestamp);
  const [currentTime, setCurrentTime] = useState(Date.now());

  const [user] = useContext(UserContext);

  const { filters, setFilters, filteredData, resetFilters } = useFilter(
    newProducts,
    'pack'
  );

  const toggleFilter = () => {
    setShowFilter(showFilter ? false : true);
  };

  useEffect(() => {
    setFilters({ ...filters, sort: 'lowest' });
    getProducts()
      .then((data) => {
        setProducts(data.filter((item) => item.visible));
      })
      .catch((err) => {
        console.log({
          level: 'Error',
          message: 'Unable to Fetch Products',
          error: err,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newProd = products.filter((item) => item.tags.includes('new'));
    setNewProducts(newProd);
    const vipProd = products.filter((item) => item.tags.includes('vip'));
    setVipProducts(vipProd);
  }, [products]);

  return (
    <Container>
      {!hidePageHeader && <PageHeader bgImage='/images/desktop.webp' bgMobileImage='/images/mobile.webp' />}
      {currentTime <= timestamp && (
        <div className='timer'>
          <video width='1024' height='500' autoPlay muted loop>
            <source src={apolloVideo} type='video/mp4' />
          </video>
          <Timer
            caption='sale begins'
            timestamp={timestamp}
            setCurrentTime={setCurrentTime}
          />
        </div>
      )}
      {currentTime > timestamp && filteredData && (
        <main>
          <Filters
            vertical={true}
            filters={filters}
            setFilters={setFilters}
            resetFilters={resetFilters}
            sortOptions={sortOptions}
            packFilter={true}
            items={filteredData}
            setShowFilter={toggleFilter}
          />
          <Section>
            {/* <div className='filter-toggler'><FilterButton onClick={() => toggleFilter()} /></div> */}
            {user?.roles?.includes('vip') &&
              vipProducts.map((product) => (
                <ProductCard
                  key={product._id}
                  product={product}
                  rate={rate}
                  toggleLogin={toggleLoginModal}
                />
              ))}
            {filteredData.map((product) => (
              <ProductCard
                key={product._id}
                product={product}
                rate={rate}
                toggleLogin={toggleLoginModal}
              />
            ))}
          </Section>
        </main>
      )}
    </Container>
  );
};

export default PackPage;

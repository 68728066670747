import styled from 'styled-components';

const getBGColor = (bgColor) => {
  switch (bgColor) {
    case 'Standard':
      return '#FFF9EB';
    case 'Prestige':
      return '#95C086';
    case 'Elite':
      return '#86C0BD';
    case 'Super Elite':
      return '#A894C7';
    case 'Legendary':
      return '#FFCE47';
  }
};

export const Container = styled.div`
  position: relative;
  inset: 1px;
  width: 20vw;
  border-radius: 1.5em;
  background-color: ${({ bgColor }) => getBGColor(bgColor)};
  margin-top: 2rem;
  border: 2px solid ${({ theme }) => theme.background.dark};
  display: flex;
  align-items: center;
  flex-direction: column;

  .wish-icon {
    display: none;
  }

  &:hover .wish-icon {
    display: block;
  }
  &:hover .card-header {
    background: ${({ theme }) => theme.cardHoverGradient};
  }

  .card-bottom {
    background-color: #000;
    margin-top: 2em;
    margin-bottom: -2px;
    padding: .75em 1.5em .5em;
    border: 1px solid #000;
    border-radius: 1em 1em 0 0;
  }

  .card-side {
    color: ${({ theme }) => theme.background.dark};
    width: 1em;
    height: 1em;
    white-space: nowrap;
    position: absolute;
    right: -.25em;
    top: 5em;
    font-weight: 400;
    text-align: center;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);
    text-transform: none;
  }
`;

export const CardHeader = styled.div`
  margin-top: -2rem;
  margin-bottom: 3rem;
  background-size: cover;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-radius: 1em;

  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
  }

  img {
    max-height: 335px;
  }
`;

export const CardContent = styled.div`
  padding: 0 1rem;
  position: relative;
  color: ${({ theme }) => theme.background.dark};

  .title {
    margin-bottom: 1rem;
    white-space: nowrap;
  }

  .desc {
    text-transform: none;
    margin-bottom: 1em;
  }

  .currency {
    display: flex;
    align-items: center;
  
    p {
      font-size: .9em;
    }
    span {
      font-size: .75em;
      font-weight: 300;
    }
  }

  .pack {
    position: absolute;
    color: white;
    padding: 1rem 1rem;
    margin: 0 auto;
    background-color: ${({ theme }) => theme.background.dark};
    text-align: center;
    width: fit-content;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius 1rem 1rem 0 0;
  }
`;

export const SoldOutBanner = styled.div`
  position: absolute;
  inset: 0;
  background: rgba(0, 0, 0, 0.45);
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 6px 6px 0 0;

  &.show {
    display: flex;
  }

  :after {
    content: 'SOLD OUT';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: ${({ theme }) => theme.primaryColor};
    border-radius: 4px;
    top: 50%;
    left: 50%;
    width: 150px;
    padding: 0.5rem 1rem;
    transform: translate(-50%, -50%);
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const WishIcon = styled.div`
  position: absolute;
  background: ${({ inList }) =>
    inList ? `url('/images/boldstar.svg')` : `url('/images/star.svg')`};
  right: 10px;
  top: 10px;
  height: 25px;
  width: 25px;
`;

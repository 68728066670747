import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 0 auto 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 15vh;
  background-image: url('/images/headerbg.png');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 500px) {
    h2 {
      font-size: 24px;
    }
  }
`;

export const Main = styled.main`
  position: relative;
  padding: 4rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90vw;
  max-width: 1520px;

  .selector-container {
    width: 95%;
    max-width: 805px;
    margin: 2rem 0 4rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }

  @media screen and (max-width: 1198px) {
    width: 100vw;
    padding: 1.5rem 0;
    .selector-container {
      flex-direction: column;
      align-items: center;
    }
  }
  @media screen and (max-width: 500px) {
    .selector-container {
      margin-top: 4rem;
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  Border,
  Container,
  CardHeader,
  CardContent,
  SoldOutBanner,
  WishIcon,
} from './ProductCard.styles';
import { Icon } from '../Icons/BaseIcon';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Auth } from 'aws-amplify';

const ProductCard = ({ product, rate, toggleLogin }) => {
  const [user, setUser] = useContext(UserContext);
  const [wishlist, setWishlist] = useState(false);
  const [title, setTitle] = useState(null);
  const [tag, setTag] = useState(null);

  useEffect(() => {
    if (!product) return;
    if (product.title.includes('Prestige')) {
      setTag('Prestige');
      setTitle(product.title.replace('Prestige', ''));
    } else if (product.title.includes('Super Elite')) {
      setTag('Super Elite');
      setTitle(product.title.replace('Super Elite', ''));
    } else if (product.title.includes('Elite')) {
      setTag('Elite');
      setTitle(product.title.replace('Elite', ''));
    } else if (product.title.includes('Legendary')) {
      setTag('Legendary');
      setTitle(product.title.replace('Legendary', ''));
    } else {
      setTag('Standard');
      setTitle(product.title);
    }
  }, [product]);

  useEffect(() => {
    if (user && user.wishlist) {
      if (user.wishlist.includes(product._id) && product._id !== null) {
        setWishlist(true);
      }
    }
  }, [user]);

  const toggleWishlist = async (e) => {
    e.preventDefault();
    if (!user) return;
    const currentUser = await Auth.currentAuthenticatedUser();
    if (wishlist) {
      let newList = user.wishlist.filter(
        (item) => item !== product._id && item
      );
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    } else {
      let newList;
      if (user.wishlist) {
        newList = [...user.wishlist, product._id];
      } else {
        newList = [product._id];
      }
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    }
  };

  const price = Math.ceil(product.price * rate);
  return (
    <NavLink to={`/products/${product._id}`}>
      <Container bgColor={tag}>
        <h5 className='card-side'>{product.rarity}</h5>
        <CardHeader className='card-header' imgURL={product.images[1]}>
          <SoldOutBanner
            className={product.soldQty < product.initialQty ? '' : 'show'}
          ></SoldOutBanner>
          <img
            src={
              product.images[2]
                ? product.images[2]
                : '/images/multi-pack-soon.png'
            }
            alt='Card Pack'
          />
          {/* <WishIcon
            className='wish-icon'
            inList={wishlist}
            onClick={toggleWishlist}
          /> */}
        </CardHeader>
        <CardContent>
          <h6 className='title'>{title}</h6>
          <p className='desc'>{product.description}</p>
          <div className='currency'>
            <Icon src='/images/token.png' />
            <strike>
              <p>
                {isNaN(price) ? 'N/A' : `${price*2} LOCG`} /{' '}
                <span className='usdt'>{product.price*2} USDT</span>
              </p>
            </strike>
          </div>
          <div className='currency'>
            <Icon src='/images/token.png' />
            <p>
              {isNaN(price) ? 'N/A' : `${price} LOCG`} /{' '}
              <span className='usdt'>{product.price} USDT</span>
            </p>
          </div>
        </CardContent>
        <span className='card-bottom subheader-4'>{product.edition}</span>
      </Container>
    </NavLink>
  );
};

export default ProductCard;

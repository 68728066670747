/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Container } from '../Page.styles';
import Filters from '../../components/Filters/Filters.component';
import { Section, Main } from './CardLibrary.styles';
import useFilter from '../../utils/useFilter';
import GameCard from '../../components/GameCard/GameCard.component';
import PageHeader from '../../components/PageHeader/PageHeader.component';
import useResizeObserver from '../../utils/useResizeObserver';
import { getCards } from '../../utils/api';

const CardLibrary = () => {
  const sortOptions = {
    DATE_DESC: 'Newest First',
    DATE_ASC: 'Oldest First',
  };
  const [cards, setCards] = useState(null);
  const { filters, setFilters, filteredData, resetFilters } = useFilter(
    cards,
    'card'
  );

  const sectionRef = useRef();
  const dimensions = useResizeObserver(sectionRef);

  useEffect(() => {
    getCards().then((data) => setCards(data));
  }, []);

  return (
    filteredData && (
      <Container>
        <PageHeader bgImage='https://static-files.locgame.io/images/collection.jpg' />
        <Main>
          <Filters
            items={filteredData}
            filters={filters}
            setFilters={setFilters}
            resetFilters={resetFilters}
            sortOptions={sortOptions}
            characterFilterEnabled={true}
          />
          <Section ref={sectionRef}>
            <div className='content'>
              {filteredData.map((card) => (
                <div key={card._id} className='card'>
                  {card.name}
                  <GameCard key={card.tokenId} card={card} />
                </div>
              ))}
            </div>
          </Section>
        </Main>
      </Container>
    )
  );
};

export default CardLibrary;

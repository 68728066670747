import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.background.light};
  border-radius: 1.5em;
  margin: .25rem 0;

  .back {
    position: absolute;
    top: 0;;
    left: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 545px) {
    max-width: 350px;
  }
`;

export const Content = styled.div`
  position: relative;
  margin-bottom: 3rem;
  display: flex;
  padding: 3em 0;
  justify-content: center;
`;

export const ProductContainer = styled.div`
  display: flex;
  justify-content: center;
  max-width: 1000px;
`;

export const PackInfo = styled.div`
  width: 65%;
  margin-left: 2rem;
  .title {
    font-family: TTHoves;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .description {
    display: flex;
    span {
      margin-right: 1rem;
    }
  }

  .checkout-container {
    display: flex;
    align-items: center;
    align-content: stretch;
    justify-content: space-between;
    gap 0 1em;
    .total {
      padding: 1.125em 1.5em;
      border-radius: 1em;
      border: 2px solid ${({ theme }) => theme.background.dark};
    }
  }

  .control-group {
    width: 100%;
    span {
      margin-left: 1em;
    }
  }

  .s-control-group {
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 35%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  .built-images {
    width: 100%;
    height: 50vh;
  }

  .built-image {
    height: 50vh;
  }

  .back {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.background.dark};
  }
`;

export const PackImage = styled.img`
  position: absolute;
  width: 100%;
  right: 0;
  transform: ${(props) =>
    ` rotateZ(${-10 + 5 * (props.offset - 1)}deg)`};
  transform-origin: bottom left;
`;

export const SelectWrapper = styled.div`
  select {
    border: 2px solid ${({ theme }) => theme.background.dark};
    outline: none;
    appearance: none;
    background-color: ${({ theme }) => theme.background.extraLight};
    border-radius: .5em;
    cursor: pointer;
    padding: 1em .5em 1em 1em;
    font: 600 1em 'Inter';
    width: 100%;
  }

  select::after {
    content: '';
    height: 8px;
    width: 11px;
    background-image: url('/images/arrow-down.svg');
    background-repeat: no-repeat;
    background-size: contain;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    user-select: none;
    pointer-events: none;
  }

  option {
    background: ${({ theme }) => theme.background.extraLight};
    border: 2px solid ${({ theme }) => theme.background.dark};
  }
`;

export const WishIcon = styled.div`
  background: ${({ inList }) =>
    inList ? `url('/images/boldstar.svg')` : `url('/images/star.svg')`};
  height: 20px;
  width: 20px;
  background-size: contain;
  margin: 0 1rem 5px 0;
  cursor: pointer;
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.accent.hoverLight};
  border: 2px solid ${({ theme }) => theme.background.dark};
  border-radius: 1.5em;
  padding: 3.5em;
  color: ${({ theme }) => theme.background.dark};
  margin-top: 1rem;
  justify-content: space-evenly;

  h4 {
    line-height: 100%;
    margin-bottom:.5em;
  }
  p {
    margin: .25em 0;
  }
`;

import MyItems from '../../pages/MyItemsPage/MyItems';
import Wishlist from '../../pages/WishList/WishList';
import WalletPage from '../../pages/WalletPage/WalletPage';
import SettingsPage from '../../pages/UserSettings/Settings';
import MyPacksPage from '../../pages/MyPacksPage/MyPacksPage';
import MyListings from '../../pages/MyListingsPage/MyListings';

const ProfileMain = ({
  tab,
  web3,
  rate,
  maticRate,
  provider,
  setProvider,
  passedHistory,
}) => {
  switch (tab) {
    case '1':
      return <MyPacksPage />;
    case '2':
      return <MyItems />;
    case '3':
      return <MyListings />;
    case '4':
      return <Wishlist rate={rate} maticRate={maticRate} />;
    case '5':
      return (
        <WalletPage
          web3={web3}
          rate={rate}
          provider={provider}
          setProvider={setProvider}
        />
      );
    case '6':
      return <SettingsPage passedHistory={passedHistory} />;

    default:
      return null;
  }
};

export default ProfileMain;

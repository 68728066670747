import styled from 'styled-components';

export const Section = styled.section`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '1700px')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: ${({ margin }) => (margin ? margin : '2rem')};
  padding: 0.5rem 1rem;
  gap: 3rem;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 0.5rem;
    flex-direction: column;
    align-items: center;
  }

  .show {
    display: flex;
  }
  .hide {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : '1425px')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => (margin ? margin : '0 0 0 2rem')};
  padding: 0.5rem 2rem;

  .filter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .filterMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 5px;
    cursor: pointer;
    font: 600 .75rem/.8rem TTHoves;
    color: #667FDF;

    .close {
      margin-left: 5px;
      font-size: 9px;
    }
  }

  @media screen and (max-width: 625px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    padding-left: 0;

    .filter-search-wrapper {
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
    }
  }
`;

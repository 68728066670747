import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin: 2rem 0 5rem;
`;

export const Container = styled.div`
  height: 690px;
  width: 617px;
  padding: 1.85rem 1rem;
  overflow: hidden;

  h3 {
    margin-bottom: 1rem;
  }

  @media screen and (max-width: 650px) {
    width: 350px;
    padding: 1.25rem 1rem;
  }

  @media screen and (max-width: 390px) {
    width: 300px;
  }
`;

export const Controls = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 50px;
  width: 320px;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%) translateY(60px);
`;

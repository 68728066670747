import styled from 'styled-components';

export const Container = styled.div`
  display:flex;
  max-height:65vh;
  width:100%;
  flex-direction:column;
  align-items:center;
  margin .125rem 0;
  background-color: ${({ theme }) => theme.background.light};
  border-radius: 1.5rem;

  h2 {
    margin .75rem 0;
  }
  & .content {
    width: 100%;
    max-height:100%;
    height: calc(100vw*9/16);
    overflow:hidden;
    background-position:center top;
    background-repeat:no-repeat;
    background-size:cover;
    border-radius:1.5rem;
    background-image: ${({ bgImage }) => `url(${bgImage})`};

    @media screen and (max-width: 500px) {
      background-image: ${({ bgMobileImage }) => `url(${bgMobileImage})`};
    }
  }

`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem 0;
  white-space: nowrap;
`;

export const PropGrid = styled.div`
  display: flex;
  flex-direction: column;
  white-space: normal;
  gap: .5rem 0;
`;

export const Property = styled.div`
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  Border,
  Container,
  CardHeader,
  CardContent,
  SoldBanner,
  WishIcon,
  SellBtn,
  SkeletonImg,
  SkeletonText,
  TransferBtn,
} from './NFTCard.styles';
import { Icon } from '../Icons/BaseIcon';
import { NavLink } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Auth } from 'aws-amplify';
import { fromWei } from '../../utils/useWeb3';
import config from '../../config';

const NFTCard = ({ nft: NFT, containerRef, size, card, rate, page }) => {
  const [user, setUser, walletAddress] = useContext(UserContext);
  const [nft, setNft] = useState(NFT);
  const [maticPrice, setMaticPrice] = useState(null);
  const [wishlist, setWishlist] = useState(false);
  const [imgsToLoad, setImgsToLoad] = useState(1);
  const [loading, setLoading] = useState(true);

  const getEditionSetText = (num) => {
    switch (num) {
      case '001':
      case '002':
        return 'Genesis';
      case '003':
        return 'Apollo';
      case '004':
        return 'The Awakening';
      case '005':
        return 'Legends Awakening';
    }
  };

  const getVRarityText = (num) => {
    switch (num) {
      case '001':
        return 'Standard';
      case '002':
        return 'Prestige';
      case '003':
        return 'Elite';
      case '004':
        return 'Super Elite';
      case '005':
        return 'Legendary';
    }
  };

  useEffect(() => {
    if (nft === null && card) {
      const data = {};
      const metadata = {};
      data.id = `${config.nftAddress}-${card.tokenId}`;
      data.tokenTokenId = card.tokenId;
      data.tokenOwner = walletAddress;
      data.contractAddress = config.nftAddress;
      metadata.name = card.name;
      metadata.description = card.description;
      metadata.image = card.image;
      metadata.valid = false;
      metadata.vRarity = card.vRarity;
      metadata.edition = card.set;
      data.tokenMetadata = metadata;
      setNft(data);
    }
  }, []);

  useEffect(() => {
    if (nft) {
      if (user && user.wishlist) {
        if (user.wishlist.includes(nft.id) && nft.id !== null) {
          setWishlist(true);
        }
      }
      if (nft.hasOrder)
        setMaticPrice(
          parseFloat(
            fromWei(nft?.signedOrder?.order?.price.toString(), 'ether')
          )
        );
    }
  }, [user, nft]);

  const handleLoad = () => {
    const remainingImgs = imgsToLoad - 1;
    setImgsToLoad(remainingImgs);
    if (remainingImgs > 0) return;
    setLoading(false);
  };

  const toggleWishlist = async (e) => {
    e.preventDefault();
    if (!user) return;
    const currentUser = await Auth.currentAuthenticatedUser();
    if (wishlist) {
      let newList = user.wishlist.filter((item) => item !== nft.id && item);
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    } else {
      let newList;
      if (user.wishlist) {
        newList = [...user.wishlist, nft.id];
      } else {
        newList = [nft.id];
      }
      let newListString = JSON.stringify(newList);
      await Auth.updateUserAttributes(currentUser, {
        'custom:wishlist': newListString,
      });
      setWishlist(!wishlist);
      setUser({ ...user, wishlist: newList });
    }
  };

  return (
    nft && (
      <Border size={size} ref={containerRef} bgColor={nft.tokenMetadata.vRarity}>
        {!nft.hasOrder &&
          nft.tokenOwner.toLowerCase() === walletAddress?.toLowerCase() && (
            <div className='sell-btn'>
              <NavLink to={`/list/${nft.tokenTokenId}`}>
                <SellBtn>Sell</SellBtn>
              </NavLink>
              <NavLink to={`/transfer/${nft.tokenTokenId}`}>
                <TransferBtn>Transfer</TransferBtn>
              </NavLink>
            </div>
          )}
        <NavLink to={`/item/${nft.tokenTokenId}`}>
          <Container size={size}>
            <h5 className='card-side'>{getVRarityText(nft.tokenMetadata.vRarity)}</h5>
            <CardHeader className='card-header'>
              {!nft?.hasOrder && page === 'wishlist' && (
                <SoldBanner
                  className={nft.soldQty < nft.initialQty ? '' : 'show'}
                ></SoldBanner>
              )}
              {loading && <SkeletonImg width='137px' height='202px' />}
              <img
                src={
                  nft.tokenMetadata.image
                    ? nft.tokenMetadata.image
                    : '/images/multi-pack-soon.png'
                }
                alt='Card Pack'
                onLoad={handleLoad}
                className={loading ? 'hide' : null}
              />
              <WishIcon
                className='wish-icon'
                inList={wishlist}
                onClick={toggleWishlist}
              />
            </CardHeader>
            <CardContent className='content' size={size}>
              <div className='header'>
                {loading ? (<SkeletonText height='.6rem' width='50%' />) : (<h6 className='title'>{nft.tokenMetadata.name}</h6>)}
                {maticPrice && (
                  <div className='currency'>
                    {loading ? (
                      <SkeletonText height='.6rem' width='60px' />
                    ) : (
                      <>
                        <Icon
                          src='/images/matic-logo.png'
                          width='14px'
                          height='14px'
                          onLoad={handleLoad}
                        />
                        <p>
                          {maticPrice} Matic /{' '}
                          <span className='usdt'>
                            {(maticPrice * rate).toFixed(2)} USDT
                          </span>
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
              {loading ? (<SkeletonText height='.6rem' />)
                : (<>
                  <p className='contract'><span className='t-label'>Contract:</span>{nft.contractAddress}</p>
                  <p className='tokenId'><span className='t-label'>ID:</span>{nft.tokenTokenId}</p>
                </>)}
            </CardContent>
            <span className='card-bottom subheader-4'>{getEditionSetText(nft.tokenMetadata.edition)}</span>
          </Container>
        </NavLink>
      </Border>
    )
  );
};

export default NFTCard;

import styled from 'styled-components';

export const ShadowDiv = styled.div`
`;

export const ButtonContainer = styled.div`
`;

export const StyledButton = styled.button`
  border: none;
  padding: 0;
  background-color: ${({ theme }) => theme.bgColor};
`;

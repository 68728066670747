import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  DropDown,
  Section,
  Tabs,
} from './ProfilePage.styles';
import { UserContext } from '../../context/UserContext';
import ProfileMain from '../../components/ProfileMain/ProfileMain.component';
import ButtonFlat from '../../components/Button2/ButtonFlat.component';

const ProfilePage = (props) => {
  const [user, , walletAddress] = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState('1');
  const history = useHistory();

  useEffect(() => {
    if (history.location.tab) {
      setSelectedTab(history.location.tab);
    }
  }, [history.location.tab]);

  const handleTab = (e) => {
    setSelectedTab(e.target.id);
  };

  const handleSelect = (e) => {
    setSelectedTab(e.target.value);
  };
  return user ? (
    <Container>
      <Section>
        <Tabs>
          <ButtonFlat
            id='1'
            className={selectedTab === '1' ? 'active' : null}
            onClick={handleTab}
          >
            Packs
          </ButtonFlat>
          <ButtonFlat
            id='2'
            className={selectedTab === '2' ? 'active' : null}
            onClick={handleTab}
          >
            Cards
          </ButtonFlat>
          <ButtonFlat
            id='3'
            className={selectedTab === '3' ? 'active' : null}
            onClick={handleTab}
          >
            Listings
          </ButtonFlat>
        </Tabs>
        <div className='select'>
          <DropDown
            className='Select'
            onChange={handleSelect}
            value={selectedTab}
          >
            <option value='1'>Packs</option>
            <option value='2'>Cards</option>
            <option value='3'>Listings</option>
            {/* <option value='4'>Wish List</option>
            <option value='5'>Wallet</option>
            <option value='6'>Settings</option> */}
          </DropDown>
        </div>
      </Section>
      <ProfileMain
        tab={selectedTab}
        web3={props.web3}
        rate={props.rate}
        maticRate={props.maticRate}
        provider={props.provider}
        setProvider={props.setProvider}
        passedHistory={history}
      />
    </Container>
  ) : null;
};

export default ProfilePage;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  Container,
  Image,
  Description,
  StatusIcon,
} from './TransactionItem.styles';
import config from '../../config';

const TransactionItem = ({ transaction }) => {
  const { product } = transaction;
  const etherscanURL = config.etherscanURL;

  if (product && transaction)
    return (
      <Container>
        <Image src={product.images[0] || '/images/cardPack.png'} />
        <Description>
          <p className='small'>{`${transaction.qty} ${
            transaction.qty === 1 ? 'Pack' : 'Packs'
          } `}</p>
          <p className='title'>{product.title}</p>
          <p className='small'>
            {transaction.created && transaction.created.slice(0, 10)}
            <StatusIcon status={transaction.status}>
              {transaction.status === 'Complete'
                ? transaction.status
                : transaction.status === 'Failed'
                ? transaction.status
                : transaction.status === 'Canceled by User'
                ? 'Canceled'
                : 'In Progress'}
            </StatusIcon>
          </p>
          {transaction.txHash ? (
            <a
              href={`${etherscanURL}${transaction.txHash}`}
              target='_blank'
              rel='noreferrer'
            >
              {transaction.txHash}
            </a>
          ) : transaction.currency === 'USD C/C' ? (
            <p className='staking'>C/C Order: {transaction._id}</p>
          ) : (
            <p className='staking'>
              Reward Program: {transaction.staking?.name}
            </p>
          )}
        </Description>
      </Container>
    );
  else return null;
};

export default TransactionItem;

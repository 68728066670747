import Web3 from 'web3'


export function validateListing(values) {
  let errors = {};

  if (!values.price) {
    errors.price = 'Selling Price is required.';
  }
  if (isNaN(values.price) || values.price <= 0) {
    errors.price = 'Selling Price must be a positive number.';
  }

  if (values.reserveAddress && !Web3.utils.isAddress(values.reserveAddress)) {
    errors.reserveAddress = 'A Valid Wallet Address is Required.';
  }
  if (values.reserved && !Web3.utils.isAddress(values.reserveAddress)) {
    errors.reserveAddress = 'A Valid Wallet Address is Required to Reserve This Sale.';
  }
  if (!values.reserved && values.reserveAddress) {
    errors.reserveAddress = 'Please select the reserve checkbox or leave this field blank.';
  }

  return errors;
}
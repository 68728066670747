export default function validate(values) {
  let errors = {};
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid Email is Required.';
  }
  if (!values.message) {
    errors.message = 'A Message is Required';
  }
  if (!values.name) {
    errors.name = 'Your Name is Required';
  }

  return errors;
}

import styled from 'styled-components';

export const ShadowDiv = styled.div`
`;

export const ButtonContainer = styled.div`
  position: relative;
`;

export const StyledButton = styled.button`
  border: none;
  padding: 1rem 1.5rem;
  background-color: transparent;
  border-radius: .5em;
  color: ${({ theme }) => theme.accent.shade2};

  &:hover {
    background-color: ${({ theme }) => theme.accent.hoverLight};
  }

  &:active {
    background-color: ${({ theme }) => theme.accent.activatedLight};
  }

  &.active {
    background-color: ${({ theme }) => theme.accent.activatedLight};
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 95%;
  max-width: 1000px;
  margin: 1rem auto;
  background-color: ${({ theme }) => theme.primaryColor};
  border-radius: 10px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;

  .open {
    transform: rotate(-90deg);
  }

  .closed {
    transform: rotate(90deg);
  }
`;

export const Content = styled.div`
  width: 95%;
  padding: 1rem 1rem 0.5rem;
  margin: 0 0 1rem 2rem;
  border-left: 1px solid rgba(255, 255, 255, 0.4);

  &.open {
    display: block;
  }

  &.closed {
    display: none;
    padding: 0;
  }

  pre {
    white-space: pre-wrap;
  }

  @media screen and (max-width: 500px) {
    margin: 0 0 0 0.5rem;
  }
`;

import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  backdrop-filter: blur(4px);
  overflow: auto;
  @media screen and (max-height: 885px) {
    justify-content: flex-start;
  }
`;

export const Modal = styled.div`
  display: flex;
  position: relative;
  padding: 3rem 2rem;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-size: cover;
  width: 485px;
  height: 755px;
  overflow: none;
  border-radius: 14px;

  @media screen and (max-width: 500px) {
    padding: 3rem 0.75rem;
    width: 100vw;
  }
`;

export const CloseBtn = styled.div`
  height: 25px;
  width: 25px;
  position: absolute;
  display: ${({ complete }) => (complete ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 17px;
  right: 17px;
  color: #5e5877;
  background-image: url('/images/close-circle.svg');
  background-size: cover;
  cursor: pointer;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  padding: 0.75rem 0.25rem;

  p {
    text-align: center;
    margin: 1rem 0 0.5rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;

  h3,
  h4 {
    margin-bottom: 0.5rem;
  }
  h4,
  h6 {
    font-weight: 500;
  }
  img {
    max-width: 150px;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-row: 1/4;
  }

  .row1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    height: 210px;
    gap: 0.75rem;
  }

  .row2 {
    margin: 2rem 0;

    .step {
      display: flex;
      align-items: center;
      padding: 0.5rem 0;
      height: 40px;
    }

    .step-number {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 26px;
      width: 26px;
      margin-right: 1rem;
      border-radius: 50%;
      font-size: 14px;
      font-weight: 700;

      &.complete {
        background: ${({ theme }) => theme.background.shade1};
      }

      &.current {
        background: ${({ theme }) => theme.hoverGradient};
        animation: 1700ms ease-in-out infinite pulse;
      }
    }
  }

  .step-text {
    font-size: 18px;
  }

  .license {
    display: flex;
    align-items: center;
  }
  .price {
    display: flex;
    align-items: center;
  }

  .spinner {
    opacity: ${({ complete }) => (complete ? '0' : '1')};
    height: 90px;
    margin: 1rem 0 2rem;
  }

  @media screen and (max-width: 500px) {
    .hash {
      width: 350px;
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1);
    }
  }
`;

import React, { useRef, useState } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';
import { Container, Content, Wrapper, PackImage } from './ProductItem.styles';
import BuyButton from '../BuyButton/BuyButton.component';
import { Span } from '../BuyButton/BuyButton.styles';
import { Auth, API } from 'aws-amplify';
import { parseCardId } from '../../utils/utils';

const ProductItem = ({
  order,
  setNewCards,
  newCards,
  cardData,
  orders,
  setOrders,
  setShowFlipBtn,
}) => {
  const [hide, setHide] = useState(false);
  const [opening, setOpening] = useState(false);
  const topAnimationRef = useRef(null);
  const bottomAnimationRef = useRef(null);
  const imagesRef = useRef(null);

  async function updateOrder(orderInfo) {
    const apiName = 'marketplace';
    const path = `/order/${order._id}`;
    const myInit = {
      body: orderInfo,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('signature')}`,
      },
    };

    return await API.put(apiName, path, myInit);
  }

  const handleOpen = async () => {
    setOpening(true);
    topAnimationRef.current.container.style.opacity = 1;
    bottomAnimationRef.current.container.style.opacity = 1;
    bottomAnimationRef.current.play();
    setTimeout(() => {
      topAnimationRef.current.play();
    }, 2500);
    setTimeout(() => {
      setHide(true);
      const filteredOrders = orders.filter((item) => item._id !== order._id);
      setOrders(filteredOrders);
      if (order.tokens.length > 0) {
        let cards = order.tokens.map((token) => {
          const cardId = parseCardId(token.token);
          const metadata = cardData.filter((data) => data.id === cardId);
          return metadata[0] ? { tokenId: token.token, ...metadata[0] } : null;
        });
        cards = cards.filter((card) => card !== null);
        setNewCards([...cards, ...newCards]);
        setShowFlipBtn((prevState) => {
          return { ...prevState, visible: true };
        });
      }
    }, 8000);
    try {
      updateOrder({ isOpened: true });
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to update Order',
        error: err,
      });
    }
  };

  const onComplete = () => {
    topAnimationRef.current.container.style.opacity = 0;
    bottomAnimationRef.current.container.style.opacity = 0;
    setHide('delete');
  };

  const buildImage = () => {
    let content = [];
    if (!order.product.images[2]) {
      return <img src='/images/multi-pack-soon.png' alt='packaging' />;
    }
    for (let i = 1; i <= order.qty; i++) {
      content.push(
        <PackImage
          totalPacks={order.qty}
          src={order.product.images[2]}
          alt='Packaging'
          offset={i}
          key={i}
        />
      );
    }
    return <div className='built-image'>{content}</div>;
  };

  return (
    <Wrapper
      className={hide === 'delete' ? 'delete' : hide ? 'hideCard' : null}
    >
      <Container>
        <Content>
          {/* <h3>{order.qty === 1 ? '1 PACK' : `${order.qty} PACKS`}</h3> */}
          <img
            src={
              order.product?.images?.length > 0
                ? order.product.images[1]
                : '/images/cardPack.png'
            }
            alt='Product'
            className='bg-img'
          />

          <div className='animation'>
            <Player
              onEvent={(event) => {
                if (event === 'complete') onComplete();

                if (event === 'play')
                  imagesRef.current.classList.toggle('fade');
              }}
              autoplay={false}
              renderer='svg'
              src='/animations/top.json'
              style={{
                height: '100%',
                width: '100%',
                zIndex: 10,
                inset: 0,
                position: 'absolute',
                opacity: 0,
                transition: 'opacity 500ms ease-out',
              }}
              ref={topAnimationRef}
            />
            <div className='packs' ref={imagesRef}>
              {order.qty === 1 ? (
                <img
                  src={
                    order.product?.images[2]
                      ? order.product.images[2]
                      : '/images/multi-pack-soon.png'
                  }
                  alt='Product'
                />
              ) : (
                buildImage()
              )}
            </div>
            <Player
              autoplay={false}
              renderer='svg'
              src='/animations/bottom.json'
              style={{
                height: '100%',
                width: '550px',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                zIndex: 0,
                position: 'absolute',
                opacity: 0,
                transition: 'opacity 1000ms ease-out',
              }}
              ref={bottomAnimationRef}
            />
          </div>

          <div className='action'>
            <BuyButton
              margin={'0 40px 0'}
              disabled={order.status !== 'Complete' || opening ? true : false}
              onClick={handleOpen}
            >
              <Span>
                {order.status === 'Complete' && !order.isOpen
                  ? 'Open'
                  : order.status === 'Failed'
                  ? 'Failed'
                  : 'In Progress'}
              </Span>
            </BuyButton>
          </div>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default ProductItem;

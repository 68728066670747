import styled from 'styled-components';

export const Container = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  font-weight: 600;
  word-wrap: normal;
  justify-content: space-evenly;
  /* padding: 0 0.5rem; */
  cursor: pointer;
  position: relative;
`;

export const Username = styled.p`
  display: inline;
  margin: 0 0.5rem;
  @media screen and (max-width: 1199px) {
    display: none;
  }
`;

export const Menu = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.background.dark};
  color: #d3cdcd;
  width: 180px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 60px;
  opacity: 0;
  transform: translateY(-200%);
  transition: all 0.5s ease-in-out;
  border-radius: 1em;
  z-index: 10000;

  &.visible {
    opacity: 1;
    transform: translateY(0);
  }
  @media screen and (max-width: 1199px) {
    left: -30px;
  }
`;

export const MenuItems = styled.ul`
  list-style: none;
  background-color: ${({ theme }) => theme.background.light};
  border: 2px solid ${({ theme }) => theme.background.dark};
  border-radius: 1em;
  box-shadow: 1rem 1rem 0 ${({ theme }) => theme.background.dark};
`;

export const Link = styled.li`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.8rem;
  border-radius: 1em;
  text-transform:none;

  &:hover {
    padding-left: 20px;
    background-color: ${({ theme }) => theme.accent.hoverLight};
    transition: padding-left 0.3s, background-color 0.3s;
  }

  &:active {

  }

  span {
    margin: 0 .5em;
  }
`;

import React, { useEffect, useState } from 'react';
import Web3 from 'web3';
import { NavLink } from 'react-router-dom';
import Button2 from '../Button2/Button2.component';
import { dexABI } from '../../utils/dexABI';
import gameplayABI from '../../utils/LOCGamePlayNFT.abi.json';
import config from '../../config';
import {
  getGasPrice,
  getMaxPriorityFee,
  postNftOrder,
  updateNftOrder,
  updateTokenInfo,
  getBanishedIds,
} from '../../utils/api';
import { useHistory } from 'react-router-dom';
import { checkChainId, parseCardId } from '../../utils/utils';
import { USER_REJECTED } from '../../constants';
import Spinner from '../Spinner/Spinner';
import { fromWei, toWei } from '../../utils/useWeb3';

const MultiButton = ({
  nft,
  walletAddress,
  user,
  nftId,
  setNetworkError,
  toggleConfirmation,
  toggleLoginModal,
  setComplete,
}) => {
  // needs to be refactored to work with wallet connect etc. check for window ethereum.

  const web3 = new Web3(window.ethereum);
  const [order, setOrder] = useState();
  const [signature, setSignature] = useState(null);
  const [dexContract, setDexContract] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [msg, setMsg] = useState({ top: '', bottom: '' });
  const [banishedIds, setBanishedIds] = useState(null);

  const history = useHistory();

  const handleChain = async () => {
    await checkChainId(web3, config.maticChainId, setNetworkError, setChainId);
  };

  useEffect(() => {
    if (nft.hasOrder) {
      setOrder(nft.signedOrder.order);
      setSignature(nft.signedOrder.sig);
    }
    if (!web3.givenProvider || !web3.givenProvider.isMetaMask) return;
    handleChain();
    web3.eth.currentProvider.on('chainChanged', handleChain);
    if (chainId === config.maticChainId)
      setDexContract(new web3.eth.Contract(dexABI, config.maticDexContract));
    getBanishedIds().then((ids) => setBanishedIds(ids));
    return () => {
      web3.eth.currentProvider.removeListener('chainChanged', handleChain);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chainId !== config.maticChainId) return;
    setDexContract(new web3.eth.Contract(dexABI, config.maticDexContract));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  const handleBuy = async () => {
    if (!user) {
      toggleLoginModal();
      return;
    }
    if (!walletAddress) {
      history.push({ pathname: '/profile', tab: 'wallet' });
      return;
    }
    try {
      await handleChain();
      if (chainId !== config.maticChainId) return;
      toggleConfirmation();
      let newOrder;
      let weiToSend = 0;
      let gasPrice = toWei(await getGasPrice('polygon'), 'Gwei');
      let maxPriorityFeePerGas = await getMaxPriorityFee('polygon');
      if (
        order.erc20PaymentToken === '0x0000000000000000000000000000000000000000'
      ) {
        weiToSend = order.price;
      }
      await dexContract.methods
        .fillOrder(
          order.signer,
          order.token,
          order.tokenId,
          order.erc20PaymentToken,
          order.price,
          order.ttl,
          order.nonce,
          walletAddress.toLowerCase(),
          signature
        )
        .send({
          value: weiToSend,
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas,
          from: walletAddress,
        })
        .on('transactionHash', async (txHash) => {
          const nftOrder = {
            txHash,
            seller: {
              wallet: order.signer,
            },
            amount: {
              currency: 'MATIC',
              value: order.price,
            },
            chain: 'matic',
            tokens: [order.tokenId],
            status: 'Pending',
          };
          //Post Order
          try {
            newOrder = await postNftOrder(nftOrder);
          } catch (err) {
            console.log(err);
          }
        })
        .on('receipt', (receipt) => {
          updateNftOrder(newOrder._id);
          updateTokenInfo(config.maticNetworkName, order.token, order.tokenId);
        });
      setComplete(true);
      setMsg({ top: '', bottom: '' });
    } catch (err) {
      setMsg({ top: '', bottom: '' });
      toggleConfirmation();
      if (err?.code === USER_REJECTED) return;
      console.log(err);
    }
  };

  const handleCancel = async () => {
    setShowSpinner(true);
    try {
      const gasPrice = toWei(await getGasPrice('polygon'), 'Gwei');
      const maxPriorityFeePerGas = await getMaxPriorityFee('polygon');
      await dexContract.methods
        .cancelOrder(nft.signedOrder.hash, signature)
        .send({
          maxFeePerGas: gasPrice,
          maxPriorityFeePerGas,
          from: walletAddress,
        });
      setShowSpinner(false);
      history.push('/items');
    } catch (err) {
      setShowSpinner(false);
      if (err?.code === USER_REJECTED) return;
      console.log(err);
    }
  };

  const handleBanish = async () => {
    const gasPrice = toWei(await getGasPrice('polygon'), 'Gwei');
    const maxPriorityFeePerGas = await getMaxPriorityFee('polygon');
    const nftContract = new web3.eth.Contract(gameplayABI, config.nftAddress);
    await nftContract.methods
      .burn(nft.tokenTokenId)
      .send({
        maxPriorityFeePerGas,
        maxFeePerGas: gasPrice,
        from: walletAddress,
      })
      .on('error', (err) => console.log(err))
      .on('transactionHash', async (txHash) => {
        const nftOrder = {
          txHash,
          seller: {
            wallet: walletAddress,
          },
          amount: {
            currency: 'MATIC',
            value: 0,
          },
          chain: 'matic',
          tokens: [nft.tokenTokenId],
          status: 'Pending',
        };
        //Post Order
        try {
          await postNftOrder(nftOrder);
          history.push('/profile');
          alert("Your mystery pack is on it's way");
        } catch (err) {
          console.log(err);
        }
      });
  };

  return (
    <>
      {showSpinner && <Spinner msg1={msg.top} msg2={msg.bottom} />}

      {nft?.tokenOwner?.toLowerCase() === walletAddress && !nft.hasOrder && (
        <NavLink to={`/list/${nftId}`}>
          <Button2>Sell</Button2>
        </NavLink>
      )}
      {banishedIds && banishedIds.includes(parseCardId(nft.tokenTokenId)) && (
        <NavLink to={`/sacrifice/${nft.tokenTokenId}`}>
          <Button2>Sacrifice</Button2>
        </NavLink>
      )}
      {nft.tokenOwner.toLowerCase() === walletAddress && nft.hasOrder && order && (
        <>
          <NavLink to={`/list/${nftId}`}>
            <Button2>Update Sale</Button2>
          </NavLink>
          <Button2>Cancel Sale</Button2>
        </>
      )}
      {nft.tokenOwner.toLowerCase() !== walletAddress && nft.hasOrder && order && (
        <Button2>BUY {fromWei(order.price)} Matic</Button2>
      )}
    </>
  );
};

export default MultiButton;

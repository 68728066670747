import React from 'react';
import { Container } from '../Page.styles';
import { Main, } from './Privacy.styles';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb.component';

const PrivacyPage = (props) => {
  

  return (
    <Container>
      <Breadcrumb />
     
      <Main>
        <h3>PRIVACY POLICY</h3>
        <p>
          This is the Privacy Policy of RBL Labs B.V. (hereinafter referred to as "RBL Labs," "we,"
          "us" or "our"), a company having its address at 367 Nassaukade, 1054AB Amsterdam. RBL
          Labs is registered with the Chamber of Commerce under number 83142614. Our Privacy
          Policy explains how we collect, use, share and protect information in relation to our website
          locgame.io (the "Service"). We collect this information when you visit our website with your
          computer, tablet or mobile phone (“Computer”). We process personal data in a manner that
          is in accordance with the General Data Protection Regulation (the “GDPR”), the GDPR-
          based legislation and the other current privacy legislation.
        </p> 
        <p>
          By using the Service, you understand and agree to the collection and use of information in
          accordance with this Privacy Policy. Our Privacy Policy applies to all visitors, users, and
          others who access the Service ("Users").
        </p>
        <h4>What kinds of information do we collect?</h4>
        <h4>Information you provide</h4>
        <p>
          We collect the following information you provide us with directly:<br/>
          a. Communications between you and RBL Labs (e.g., we may send you Service-related
          emails);
        </p>
        <h4>Log file information</h4>
        <p>
          We collect information that your browser sends whenever you visit our Service. This log file
          information may include information such as your computer's Internet Protocol address,
          browser type, browser version, the pages of our Service that you visit, the time and date of
          your visit, the time spent on those pages and other statistics.
        </p>
        <h4>Analytics services (non-personally identifiable information only)</h4>
        <p>
          We use third-party analytics tools to help us measure traffic and usage trends for the Service.
          These tools collect information sent by your device or our Service, including the web pages
          you visit, add-ons, and other information that assists us in improving the Service. The tools
          use ‘cookies’, which are text files placed on your device, to collect your log information and
          behavior information in an anonymous form. We collect and use this analytics information
          with analytics information from other Users so that it cannot reasonably be used to identify
          any particular individual User. With respect to Google Analytics, although Google Analytics
          plants a permanent cookie on your web browser to identify you, the cookie cannot be used by
          anyone but Google. Google’s ability to use and share information collected by Google
          Analytics about your visits is restricted by the Google Analytics Terms of Use and the Google
          Privacy Policy. You can prevent Google Analytics from recognizing you on return visits
          by disabling cookies.
        </p>
        <h4>How do we use this information?</h4>
        <p>
          We use all of the information we have to help us provide and support our Services. Here is
          how:<br/>
          a. remember information so you will not have to re-enter it during your visit or the next
          time you visit the Service;<br/>
          b. provide, improve, test, and monitor the effectiveness of our Service;<br/>
          c. monitor metrics such as total number of visitors, traffic, and demographic patterns;<br/>
          d. diagnose or fix technology problems;<br/>
          e. develop and test new products and features; <br/>
        </p>
        <h4>How is this information shared?</h4>
        <p>We will not rent or sell your information to third parties outside RBL Labs.</p>
        <h4>Change of control</h4>
        <p>
          If we sell or otherwise transfer part or the whole of RBL Labs or our assets to another
          organization (e.g., in the course of a transaction like a merger, acquisition, bankruptcy,
          dissolution, liquidation), your information collected through the Service may be among the
          items sold or transferred. The buyer or transferee will have to honor the commitments we
          have made in this Privacy Policy.
          </p>
          <h4>Legal requests and preventing harm</h4>
          <p>
            We may access, preserve and share your information in response to a legal request (like a
            search warrant, court order or subpoena) if we have a good faith belief that the law requires us
            to do so. We may also access, preserve and share information when we have a good faith
            belief it is necessary to: detect, prevent and address fraud and other illegal activity; to protect
            ourselves, you and others, including as part of investigations; and to prevent death or
            imminent bodily harm. Information we receive about you may be accessed, processed and
            retained for an extended period of time when it is the subject of a legal request or obligation,
            governmental investigation, or investigations concerning possible violations of our terms or
            policies, or otherwise to prevent harm.
          </p>
          <h4>Safety and security</h4>
          <p>
            RBL Labs has taken appropriate technical and organizational measures by using the latest
            technologies to protect your information against loss or unlawful processing. We use
            safeguards to help keep the information collected through the Service secure. However, RBL
            Labs cannot ensure the security of any information you transmit to RBL Labs or guarantee
            that information on the Service may not be accessed, disclosed, altered, or destroyed. We
            request you to do your part to help us. You are responsible for controlling access to emails
            between you and RBL Labs, at all times. We are not responsible for the functionality, privacy,
            or security measures of any other organization.
          </p>
          <h4>International transfer</h4>
          
      <p>
        Your information may be transferred to, and maintained on computers located outside of your
        state, province, country or other governmental jurisdiction where the data protection laws may
        differ than those from your jurisdiction. If you are located outside Netherlands and choose to
        provide information to us, please note that we transfer the information to The Netherlands.
        Your consent to this Privacy Policy followed by your submission of such information
        represents your agreement to that transfer.
      </p> 
      <h4>Retention period</h4>
      <p>
      In accordance with the law, RBL Labs does not retain data any longer than is required for
      attaining the purposes for which they were collected.
      </p>
      <h4>Inspection and correction</h4>
      <p>
        If you wish to know which of your data RBL Labs has recorded or if you wish to amend or
        remove data that you cannot amend via your account, please contact RBL Labs.
      </p>
      <h4>Third-party applications, websites and services</h4>
      <p>
        We are not responsible for the practices employed by any applications, websites or services
        linked to or from our Service, including the information or content contained within them.
        Please remember that when you use a link to go from our Service to another application,
        website or service, our Privacy Policy does not apply to those third-party applications,
        websites or services. Your browsing and interaction on any third-party application, website or
        service, including those that have a link on our Services, are subject to that third party's own
        rules and policies.
      </p>
      <h4>Children's privacy</h4>
      <p>
        Our Service does not address anyone under the age of 13 ("Children"). We do not knowingly
        collect personally identifiable information from children under 13. If you are a parent or
        guardian and you are aware that your Children has provided us with personal information,
        please contact us. If we become aware that we have collected personal information from a
        child under age 13 without verification of parental consent, we take steps to remove that
        information from our servers.
      </p>
      <h4>Changes to this privacy policy</h4>
      <p>
        We may modify or update our Privacy Policy from time to time. We will notify you of any
        changes by posting the new Privacy Policy on this page. You are advised to review this
        Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective
        when they are posted on this page.
      </p>
      <h4>How to contact us</h4>
      <p>
        If you have any questions about this Privacy Policy of the Service, please send an email to
        info@locgame.io.
      </p>
      </Main>
    </Container>
  );
};

export default PrivacyPage;

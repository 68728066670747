const env = process.env.REACT_APP_ENVIRONMENT || 'dev'; // 'local', 'dev' or 'prod'
console.log('Load config for:', env);

const local = {
  env,
  cognito: {
    aws_project_region: 'us-east-2',
    aws_cognito_region: 'us-east-2',
    aws_cognito_identity_pool_id:
      'us-east-2:09f9472d-2036-4fac-9fc5-2b3822326004',
    aws_user_pools_id: 'us-east-2_jzQ13CTgf',
    aws_user_pools_web_client_id: '4pmvmls2qq7d5snsrsn9n0sdq6',
    oauth: {
      domain: 'dev-auth.locgame.io',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: 'https://localhost:3000/',
      redirectSignOut: 'https://localhost:3000/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
    aws_user_files_s3_bucket: 'static-files.locgame.io',
    aws_user_files_s3_bucket_region: 'us-east-2',
    cookieStorage: {
      domain: 'localhost',
      secure: false,
      path: '/',
      expires: 365,
    },
    API: {
      endpoints: [
        {
          name: 'marketplace',
          endpoint: process.env.REACT_APP_ENDPOINT,
        },
        {
          name: 'openMarketplace',
          endpoint: 'https://dev-api.market.locgame.io',
        },
        {
          name: 'global',
          endpoint: '',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: 'static-files.locgame.io', //REQUIRED -  Amazon S3 bucket name
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      },
    },
  },
  rootURL: 'https://localhost:3000',
  adminUrl: 'http://devadmin.locgame.io',
  etherscanURL: 'https://goerli.etherscan.io/txs',
  ethRpc: 'https://goerli.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  LOCGcontract: '0x52b741Cd3adc36Fb0253520503fa9D920BbD3794'.toLowerCase(),
  USDTcontract: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b'.toLowerCase(), // Compound USDC 6 decimal token
  nftAddress: '0x16581F93797E33fd2B1a3497822aDF1762EE36e2'.toLowerCase(),
  maticRpc: [
    'https://polygon-mumbai.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
    'https://rpc-mumbai.maticvigil.com/',
    'https://rpc-mumbai.matic.today/',
  ],
  recv_acct: '0x59Fca321FDa2e4752466b4bA0b3702e5C0697Cfb',
  ethNetworkName: 'goerli',
  ethChainId: 5,
  maticNetworkName: 'mumbai',
  maticChainId: 80001,
  maticDexContract: '0x5e8dc750da0eb97bc40b739c3b9e5ec4d98fb6a3',
  contactEmail: 'jason.nicholls@locgame.io',
  availableSets: ['001'],
  accessCode: 'APOLLO11',
  earlyTimestamp: 1651406400000,
  saleTimestamp: 1651510800000,
  reCaptchaKeyEnterprise: '6LexnO8fAAAAAMO36RSvWe1hhAnbtVC112T3HEKn',
  reCaptchaKeyV2: '6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj',
};

const localstg = {
  env: 'staging-local',
  cognito: {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-central-1',
    aws_cognito_identity_pool_id:
      'eu-central-1:7826fe71-b673-4d78-88f8-a3f424163b55',
    aws_user_pools_id: 'eu-central-1_btBconCfj',
    aws_user_pools_web_client_id: '2mqt65v3bpat7lt630ife1ql2g',
    oauth: {
      domain: 'stg-auth.locgame.io',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: 'https://localhost:3000',
      redirectSignOut: 'https://localhost:3000',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
    aws_user_files_s3_bucket: 'static-files.locgame.io',
    aws_user_files_s3_bucket_region: 'eu-central-1',
    cookieStorage: {
      domain: 'localhost',
      secure: true,
      path: '/',
      expires: 365,
    },
    API: {
      endpoints: [
        {
          name: 'marketplace',
          // endpoint: 'http://localhost:3001',
          endpoint: 'https://api.stg-market.locgame.io',
        },
        {
          name: 'openMarketplace',
          endpoint: 'https://api.stg-market.locgame.io',
        },
        {
          name: 'global',
          endpoint: '',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: 'static-files.locgame.io', //REQUIRED -  Amazon S3 bucket name
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      },
    },
  },
  rootURL: 'https://stg-market.locgame.io',
  etherscanURL: 'https://etherscan.io/tx/',
  ethRpc: 'https://mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  LOCGcontract: '0x60eb57d085c59932d5faa6c6026268a4386927d0'.toLowerCase(),
  USDTcontract: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(),
  nftAddress: '0x248f931139Ef14AE4ca43Add2DbF5D9E61741C4D'.toLowerCase(),
  maticRpc: [
    'https://rpc-mainnet.maticvigil.com/',
    'https://polygon-mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
    'https://rpc-mainnet.matic.today/',
  ],
  recv_acct: '0x59Fca321FDa2e4752466b4bA0b3702e5C0697Cfb',
  ethNetworkName: 'ethereum',
  ethChainId: 1,
  maticNetworkName: 'polygon',
  maticChainId: 137,
  maticDexContract: '0xe7ad268dddf1efd6583503a078128cdfa1fc302f'.toLowerCase(),
  contactEmail: 'support@locgame.io',
  availableSets: ['001'],
  accessCode: 'APOLLO11',
  earlyTimestamp: 1652097600000,
  saleTimestamp: 1652184000000,
  reCaptchaKeyEnterprise: '6LexnO8fAAAAAMO36RSvWe1hhAnbtVC112T3HEKn',
  reCaptchaKeyV2: '6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj',
};

const dev = {
  env,
  cognito: {
    aws_project_region: 'us-east-2',
    aws_cognito_region: 'us-east-2',
    aws_cognito_identity_pool_id:
      'us-east-2:09f9472d-2036-4fac-9fc5-2b3822326004',
    aws_user_pools_id: 'us-east-2_jzQ13CTgf',
    aws_user_pools_web_client_id: '4pmvmls2qq7d5snsrsn9n0sdq6',
    oauth: {
      domain: 'dev-auth.locgame.io',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: 'https://dev-market.locgame.io/',
      redirectSignOut: 'https://dev-market.locgame.io/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
    aws_user_files_s3_bucket: 'static-files.locgame.io',
    aws_user_files_s3_bucket_region: 'us-east-2',
    cookieStorage: {
      domain: 'locgame.io',
      secure: true,
      path: '/',
      expires: 365,
    },
    API: {
      endpoints: [
        {
          name: 'marketplace',
          endpoint: 'https://dev-api.market.locgame.io',
        },
        {
          name: 'openMarketplace',
          endpoint: 'https://dev-api.market.locgame.io',
        },
        {
          name: 'global',
          endpoint: '',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: 'static-files.locgame.io', //REQUIRED -  Amazon S3 bucket name
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      },
    },
  },
  adminUrl: 'https://devadmin.locgame.io',
  rootURL: 'https://dev-market.locgame.io',
  etherscanURL: 'https://goerli.etherscan.io/txs',
  ethRpc: 'https://goerli.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  LOCGcontract: '0x52b741Cd3adc36Fb0253520503fa9D920BbD3794'.toLowerCase(),
  USDTcontract: '0x4DBCdF9B62e891a7cec5A2568C3F4FAF9E8Abe2b'.toLowerCase(),
  nftAddress: '0x16581F93797E33fd2B1a3497822aDF1762EE36e2'.toLowerCase(),
  maticRpc: [
    'https://rpc-mumbai.maticvigil.com/',
    'https://polygon-mumbai.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
    'https://rpc-mumbai.matic.today/',
  ],
  // recv_acct: '0x59Fca321FDa2e4752466b4bA0b3702e5C0697Cfb',
  recv_acct: '0x59Fca321FDa2e4752466b4bA0b3702e5C0697Cfb',
  ethNetworkName: 'goerli',
  ethChainId: 5,
  maticNetworkName: 'mumbai',
  maticChainId: 80001,
  maticDexContract: '0x5e8dc750da0eb97bc40b739c3b9e5ec4d98fb6a3',
  contactEmail: 'support@locgame.io',
  availableSets: ['001'],
  accessCode: 'APOLLO11',
  earlyTimestamp: 1651507200000,
  saleTimestamp: 1651507200000,
  reCaptchaKeyEnterprise: '6LexnO8fAAAAAMO36RSvWe1hhAnbtVC112T3HEKn',
  reCaptchaKeyV2: '6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj',
};

const staging = {
  env,
  cognito: {
    // Staging User pool Settings
    // aws_project_region: 'eu-central-1',
    // aws_cognito_region: 'eu-central-1',
    // aws_cognito_identity_pool_id:
    //   'eu-central-1:7826fe71-b673-4d78-88f8-a3f424163b55',
    // aws_user_pools_id: 'eu-central-1_btBconCfj',
    // aws_user_pools_web_client_id: '2mqt65v3bpat7lt630ife1ql2g',
    // temp. using prod user pool until custom Authorizer can be created
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-central-1',
    aws_cognito_identity_pool_id:
      'eu-central-1:7826fe71-b673-4d78-88f8-a3f424163b55',
    aws_user_pools_id: 'eu-central-1_2mQTiK8Mt',
    aws_user_pools_web_client_id: '1i43ost62poj5388ei3hk3fjs3',
    // temp setting end 
    oauth: {
      // domain: 'stg-auth.locgame.io',
      domain: 'auth.locgame.io',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: 'https://stg-market.locgame.io/',
      redirectSignOut: 'https://stg-market.locgame.io/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
    aws_user_files_s3_bucket: 'static-files.locgame.io',
    aws_user_files_s3_bucket_region: 'us-east-2',
    cookieStorage: {
      domain: 'locgame.io',
      secure: true,
      path: '/',
      expires: 365,
    },
    API: {
      endpoints: [
        {
          name: 'marketplace',
          endpoint: 'https://stg-api.market.locgame.io',
        },
        {
          name: 'openMarketplace',
          endpoint: 'https://stg-api.market.locgame.io',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: 'static-files.locgame.io', //REQUIRED -  Amazon S3 bucket name
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      },
    },
  },
  adminUrl: 'https://stg-admin.locgame.io',
  rootURL: 'https://stg-market.locgame.io',
  etherscanURL: 'https://etherscan.io/tx/',
  ethRpc: 'https://mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  LOCGcontract: '0x60eb57d085c59932d5faa6c6026268a4386927d0'.toLowerCase(),
  USDTcontract: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(),
  nftAddress: '0x248f931139Ef14AE4ca43Add2DbF5D9E61741C4D'.toLowerCase(),
  maticRpc: [
    'https://rpc-mainnet.maticvigil.com/',
    'https://polygon-mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
    'https://rpc-mainnet.matic.today/',
  ],
  recv_acct: '0x59Fca321FDa2e4752466b4bA0b3702e5C0697Cfb',
  ethNetworkName: 'ethereum',
  ethChainId: 1,
  maticNetworkName: 'polygon',
  maticChainId: 137,
  maticDexContract: '0xe7ad268dddf1efd6583503a078128cdfa1fc302f'.toLowerCase(),
  contactEmail: 'support@locgame.io',
  availableSets: ['002'],
  accessCode: 'APOLLO11',
  earlyTimestamp: 1651507200000,
  saleTimestamp: 1651510800000,
  reCaptchaKeyEnterprise: '6LexnO8fAAAAAMO36RSvWe1hhAnbtVC112T3HEKn',
  reCaptchaKeyV2: '6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj',
};

const prod = {
  env,
  cognito: {
    aws_project_region: 'eu-central-1',
    aws_cognito_region: 'eu-central-1',
    aws_cognito_identity_pool_id:
      'eu-central-1:7826fe71-b673-4d78-88f8-a3f424163b55',
    aws_user_pools_id: 'eu-central-1_2mQTiK8Mt',
    aws_user_pools_web_client_id: '1i43ost62poj5388ei3hk3fjs3',
    oauth: {
      domain: 'auth.locgame.io',
      scope: ['aws.cognito.signin.user.admin', 'email', 'openid', 'profile'],
      redirectSignIn: 'https://market.locgame.io/',
      redirectSignOut: 'https://market.locgame.io/',
      responseType: 'code',
    },
    federationTarget: 'COGNITO_USER_AND_IDENTITY_POOLS',
    aws_user_files_s3_bucket: 'static-files.locgame.io',
    aws_user_files_s3_bucket_region: 'us-east-2',
    cookieStorage: {
      domain: 'locgame.io',
      secure: true,
      path: '/',
      expires: 365,
    },
    API: {
      endpoints: [
        {
          name: 'marketplace',
          endpoint: 'https://api.market.locgame.io',
        },
        {
          name: 'openMarketplace',
          endpoint: 'https://api.market.locgame.io',
        },
        {
          name: 'global',
          endpoint: '',
        },
      ],
    },
    Storage: {
      AWSS3: {
        bucket: 'static-files.locgame.io', //REQUIRED -  Amazon S3 bucket name
        region: 'us-east-2', //OPTIONAL -  Amazon service region
      },
    },
  },
  adminUrl: 'https://admin.locgame.io',
  rootURL: 'https://market.locgame.io',
  etherscanURL: 'https://etherscan.io/tx/',
  ethRpc: 'https://mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  LOCGcontract: '0x60eb57d085c59932d5faa6c6026268a4386927d0'.toLowerCase(),
  USDTcontract: '0xdac17f958d2ee523a2206206994597c13d831ec7'.toLowerCase(),
  nftAddress: '0x31DD87a135c2bcDEF49f36b48750575366a21B5E'.toLowerCase(),

  maticRpc: [
    'https://rpc-mainnet.maticvigil.com/',
    'https://polygon-mainnet.infura.io/v3/57bf1838a7584ea891b89dca3bb79532',
  ],
  recv_acct: '0x01E61b5eDf77b020d3E6b0458D926cDc023DdF46',
  ethNetworkName: 'ethereum',
  ethChainId: 1,
  maticNetworkName: 'polygon',
  maticChainId: 137,
  maticDexContract: '0xe7ad268dddf1efd6583503a078128cdfa1fc302f'.toLowerCase(),
  contactEmail: 'support@locgame.io',
  availableSets: ['002'],
  accessCode: 'APOLLO11',
  earlyTimestamp: 1652184000000,
  saleTimestamp: 1652270400000,
  reCaptchaKeyEnterprise: '6LdInPMfAAAAAARn87oHdYzyc4uFgSELd-xYskE4',
  reCaptchaKeyV2: '6LdHOd8hAAAAAKBVebcs3IKYIAKk0nhpq8LmgPCj',
};

const config = {
  local,
  localstg,
  staging,
  dev,
  prod,
};

export default config[env];

import styled from 'styled-components';

const getBGColor = (bgColor) => {
  switch (bgColor) {
    case 'Standard':
      return '#FFF9EB';
    case 'Prestige':
      return '#95C086';
    case 'Elite':
      return '#86C0BD';
    case 'Super Elite':
      return '#A894C7';
    case 'Legendary':
      return '#FFCE47';
  }
};

export const Container = styled.div`
  position: relative;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-style: preserve-3d;
  background: transparent;
  &:hover {
    zoom: ${(props) => (props.hover ? '102%' : null)};
  }

  img {
    height: 100%;
    width: 100%;
  }

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
    transform: rotateY(180deg);
    cursor: pointer;
  }

  &.flip {
    -webkit-animation: 1s spinIn linear;
    animation: 1s spinIn linear;
  }

  @keyframes spinIn {
    0% {
      transform: rotateY(180deg);
    }
    25% {
      transform: rotateY(360deg);
    }
    75% {
      transform: rotateY(180deg);
    }
    100% {
      transform: rotateY(0deg);
    }
  }
`;

export const CardFront = styled.div`
  border-radius: 8px;
  background-size: cover;
  height: 100%;
  max-width: 100%;
  //z-index: 1;
  position: absolute;
  inset: 0;
  background: transparent;
  backface-visibility: hidden;

  &:hover img {
    ${(props) => (props.hover ? props.theme.cardHover : null)};
  }
`;
export const CardBack = styled.div`
  border-radius: 8px;
  transform: rotateY(180deg);
  height: 100%;
  width: 100%;
  position: absolute;
  inset: 0;
  background: transparent;
  backface-visibility: hidden;

  img {
    &:hover {
      ${(props) => (props.hover ? props.theme.cardHover : null)};
    }
  }
`;

import React, { useState } from 'react';

import { Container, Header, Content } from './FAQItem.styles';
import { Icon } from '../Icons/BaseIcon';

const FAQItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Container>
      <Header onClick={() => setIsOpen(!isOpen)}>
        <h4>{item.Question}</h4>{' '}
        <Icon
          src='/images/line-arrow-right.svg'
          className={isOpen ? 'open' : 'closed'}
        />
      </Header>
      <Content className={isOpen ? 'open' : 'closed'}>
        <pre dangerouslySetInnerHTML={{ __html: item.Solution }} />
      </Content>
    </Container>
  );
};

export default FAQItem;

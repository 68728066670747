import React from 'react';
import { Container } from './RoundButton.styles';

const RoundButton = (props) => {
  return (
    <Container {...props}>
      <img src='/images/line-arrow-right.svg' alt='next' name={props.id} />
    </Container>
  );
};

export default RoundButton;

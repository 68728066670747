import styled from 'styled-components';

export const Container = styled.div`
  width: 60px;
  height: 60px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: ${({ transform }) => (transform ? transform : null)};
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '0' : '1')};

  img {
    transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : null)};
  }

  &:hover {
    background: ${({ disabled }) =>
      disabled ? null : 'rgba(255, 255, 255, 0.25)'};
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${({ theme }) => theme.navHeight};
  background: ${({ theme }) => theme.bgColor};
  padding: 6rem;

  .back {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    color: ${({ theme }) => theme.background.dark};
  }
`;

export const Content = styled.div`
  position: relative;
  width: 100%;

  h2 {
    margin-bottom: 1.5rem;
  }

  .wrapper {
    background-image: url('/images/temple.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    height: 700px;
    position: relative;

    img {
      position: absolute;
      top: 170px;
      left: 50%;
      max-width: 200px;
      transform: translateX(-55%);
    }
  }

  @media screen and (max-width: 585px) {
    width: 350px;
  }
`;

import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  StyledButton,
  ButtonContainer,
  ShadowDiv,
  ToolTip,
} from './BuyButton.styles';
import Button2 from '../Button2/Button2.component';

const BuyButton = (props) => {
  const history = useHistory();
  return (
    <ShadowDiv {...props} onClick={null}>
      {props.tooltip && (
        <ToolTip
          className='tooltip'
          {...props}
          onClick={
            props.nsf
              ? () => {
                history.push({ pathname: '/support', state: 'buy' });
              }
              : null
          }
        >
          <em>{props.tooltip}</em>
        </ToolTip>
      )}
      <ButtonContainer
        {...props}
        onClick={
          props.nsf
            ? () => {
              history.push({ pathname: '/support', state: 'buy' });
            }
            : null
        }
      >
        <span className='border'></span>
        <span className='border'></span>
        <span className='border'></span>
        <span className='border'></span>
        <Button2 {...props}>{props.children}</Button2>
      </ButtonContainer>
    </ShadowDiv>
  );
};

export default BuyButton;

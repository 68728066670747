// import { censorInput } from '../../utils/utils'

export function validateSignup(values) {
  let errors = {};
  values.email = values.email.toLowerCase();

  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid Email is Required.';
  }
  if (!values.signed) {
    errors.system = 'A Valid Signature is Required.';
  }
  return errors;
}

import { createGlobalStyle } from 'styled-components';

export const lightTheme = {
};

export const darkTheme = {
  errorColor: 'rgb(255,65,65)',
  errorBorder: `1px solid rgb(255,65,65)`,
  fontColor: 'rgba(255, 255, 255, 0.88)',
  fontMuted: 'rgba(255,255,255,.7)',
  inputActiveColor: 'rgba(255, 255, 255, 1)',
  inputActiveBorder: '1px solid rgba(255, 255, 255, 1)',
  inputActiveBackground: 'rgba(255, 255, 255, 0.1)',
  inputDefaultColor: 'rgba(255, 255, 255, 0.4)',
  inputDefaultBorder: '1px solid rgba(255, 255, 255, 0.4)',
  inputDefaultBackground: 'none',
  navHeight: '4.75em',
  accent: {
    shade1: '#FFBB00',
    shade2: '#664A00',
    shade3: '#3D2D00',
    shade4: '#291E00',
    activatedLight: '#FFCE47',
    hoverLight: '#FFE499',
    green: '#95C086',
    blue: '#86C0BD',
    purple: '#A894C7',
    orange: '#FFCE47',
    neutralBlack: 'rgba(0, 0, 0, 0.32)',
  },
  background: {
    dark: '#140F00',
    light: '#FFEFC2',
    extraLight: '#FFF9EB',
  },
  dropShadow: `drop-shadow(5px 5px 1px rgba(50, 66, 206, 0.6))
               drop-shadow(2px 8px 2px rgba(56, 73, 224, 0.4))
               drop-shadow(5px 12px 20px rgba(56, 73, 224, 0.4));`,
  borderGradient: `linear-gradient(
    102.03deg,
    #ffffff -15.87%,
    #5a45db 40.43%,
    #4330b5 113.65%
  )`,
  mainGradient: `linear-gradient(
    287.38deg,
    #4a7af3 -1.9%,
    #5140be 43.64%,
    #954af3 83.54%
  );`,
  hoverGradient: `linear-gradient(
    285.09deg,
    #14317C -0.33%,
    #6C4DC4 55.44%,
     #34094F 104.31%)`,
  cardHover: `filter: drop-shadow(0px 1px 50px rgb(149, 74, 243,.8));`,
};

export const GlobalStyles = createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Thin.woff');
  font-weight:100;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-ExtraLight.woff');
  font-weight:200;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Light.woff');
  font-weight:300;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Regular.woff');
  font-weight:400;
}
@font-face {
  font-family: 'TTHoves' ;
  src: url('/fonts/TTHoves-Medium.woff');
  font-weight:500;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-DemiBold.woff');
    font-weight: 600;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-Bold.woff');
    font-weight: 700;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-ExtraBold.woff');
    font-weight: 800;
}
@font-face {
    font-family: 'TTHoves';
    src: url('/fonts/TTHoves-Black.woff');
    font-weight: 900;
}
@font-face {
    font-family: 'Dark twenty';
    src: url('/fonts/Dark-Twenty.otf');
}
@font-face {
    font-family: 'Proxima Nova';
    src: url('/fonts/ProximaNova-Regular.otf');
}
@font-face {
    font-family: 'Baron Neue';
    src: url('/fonts/Baron-Neue.otf');
}
@font-face {
    font-family: 'D din pro 400';
    src: url('/fonts/D-DIN-PRO-400-Regular.otf');
}

body {
  background-color: ${({ theme }) => theme.background.dark};
  font-family: 'Inter', sans-serif;
  color: ${({ theme }) => theme.fontColor};
  line-height: 150%;
  font-weight: 400;
  padding: .5rem;
}

a {
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.fontColor};
  text-decoration: none;
  line-height: 100%;
}

.subheader-2 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  font-size: .875em;
  line-height: 144%;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #111;
}

.subheader-3 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: .875em;
  line-height: 144%;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  color: #111;
}

.subheader-4 {
  font-family: 'Baron Neue', sans-serif;
  font-weight: 700;
  font-size: .875em;
  letter: 4%;
}

h4 {
  font-family: 'Baron Neue', sans-serif;
  font-weight: 400;
  font-size: 3em;
  line-height: 150%;
  text-transform: uppercase;
}

h5 {
  font-family: 'Dark twenty', serif;
  font-size: 2em;
  line-height: 100%;
}

h6 {
  font-family: 'Baron Neue', sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 400;
}

.t-label {
  font-weight: 600;
}

button {
  font-family: 'Baron Neue', sans-serif;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: ${(props) =>
    props.top ? props.top : `calc(props.theme.navHeight + 1rem)`};
  left: 2rem;
  z-index: 1;

  h5,
  a {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: ${({ theme }) => theme.fontColor};
  }

  span {
    font-size: 22px;
  }
  @media screen and (max-width: 390px) {
    left: 0.75rem;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 1rem auto;
  //padding-top: ${({ theme }) => theme.navHeight};
  width: 100%;
  max-width: 1700px;
  display: flex;
  justify-content: center;

  .row {
    display: flex;
    justify-content: flex-start;
  }

  @media screen and (max-width: 780px) {
    .row {
      flex-direction: column;
    }
  }
`;

export const Header = styled.header`
  display: flex;
  margin: 1rem 5rem 2rem;

  align-items: center;
  justify-content: flex-start;

  #picture {
    display: none;
  }

  #pic-label {
    display: inline-block;
    height: 25px;
    width: 25px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

export const Section = styled.section`
  height: 100%;
  width: 350px;
  margin: 2rem 3rem;
  padding: 0.5rem 3rem;

  @media screen and (max-width: 575px) {
    margin: 0;
    padding: 0;
  }
`;

export const AvatarContainer = styled.div`
  position: relative;
  height: 80px;
  width: 80px;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  background-color: #a1a0a0;
  padding: 0.25rem;
  height: 100%;
  width: 100%;
`;

export const EditButton = styled.img`
  position: absolute;
  right: 0;
  bottom: 6px;
  padding: 3px;
  transform: translateX(25%);
  transform: translateY(25%);
  height: 25px;
  width: 25px;
  background-color: #494747;
  border-radius: 50%;
  border: 1px solid white;
`;

export const Username = styled.p`
  display: inline;
  padding: 0 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  //font-family: 'Dosis';
`;

export const Form = styled.form`
  margin: 1rem 0;
  padding: 1rem 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1199px) {
    max-width: 415px;
  }
`;

import React, { useState, useContext, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CloseBtn, Container, Main } from './LoginPage.styles';
import { Icon } from '../../components/Icons/BaseIcon';
import { UserContext } from '../../context/UserContext';
import useForm from '../../utils/useForm';
import { validateLogin } from './validate';
import Spinner from '../../components/Spinner/Spinner';
import StdButton from '../../components/StdButton/StdButton.component';
import { NavLink } from 'react-router-dom';
import { HandleOutsideClick } from '../../utils/utils';
import config from '../../config';

const LoginPage = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [errors, setErrors] = useState(false);
  const [, setUser, wallet, setWalletAddress] = useContext(UserContext);
  const { ref, isComponentVisible } = HandleOutsideClick();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { sendClose, web3, web3Provider } = props;
  const action = 'login';

  const history = useHistory();

  const handleReCaptchaVerify = useCallback(async () => {
    try {
      if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return { valid: false, errorMessage: 'ExecuteRecaptchaNotYetAvailable' };
      }
      const token = await executeRecaptcha(action);
      const result = await API.post('marketplace', '/recaptcha', { body: { token, action } });
      return result;
    } catch (err) {
      console.log(err);
      return { valid: false, errorMessage: err };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const { values, errors, handleSubmit, handleChange, setErrors } = useForm(
  //   submit,
  //   validateLogin,
  // );

  useEffect(() => {
    if (!isComponentVisible) {
      sendClose();
    }
  }, [isComponentVisible, sendClose]);

  useEffect(() => {
    if (web3Provider !== config.ethRpc)
      web3.eth.getAccounts().then(accounts => accounts.length > 0 && setWalletAddress(accounts[0].toLowerCase()));
  }, []);

  // async function submit() {
  //   setShowSpinner(true);
  //   try {
  //     const recaptcha = await handleReCaptchaVerify();
  //     if (!recaptcha || !recaptcha.valid)
  //       throw new Error(recaptcha.errorMessage);
  //     const user = await Auth.signIn(values.email, values.password);
  //     if (!user.challengeName) {
  //       let attributes = {
  //         given_name:
  //           user.attributes['custom:firstName'] || user.attributes.given_name,
  //         family_name:
  //           user.attributes['custom:lastName'] || user.attributes.family_name,
  //         picture:
  //           user.attributes['custom:avatar'] || user.attributes.picture || null,
  //         email: user.attributes.email,
  //       };
  //       setUser(attributes);
  //       sendClose();
  //       setShowSpinner(false);
  //       history.push('/');
  //     } else {
  //       sendClose();
  //       setShowSpinner(false);
  //       setUser(user);
  //       history.push({
  //         pathname: '/profile',
  //         tab: 'settings',
  //         user: user,
  //       });
  //     }
  //   } catch (err) {
  //     setShowSpinner(false);
  //     if (err.code === 'NotAuthorizedException')
  //       setErrors({ ...errors, password: err.message });
  //     else if (err.code === 'UserNotFoundException')
  //       setErrors({ ...errors, password: 'Incorrect username or password.' });
  //     else if (err.code === 'UserNotConfirmedException') {
  //       setErrors({
  //         ...errors,
  //         email:
  //           'User email is not confirmed. Please check your email for a verification link.',
  //       });
  //     } else if (err.message === 'ExecuteRecaptchaNotYetAvailable') {
  //       setErrors({ ...errors, email: 'Recaptcha net yet available, please check your ad blocker or refresh the page' });
  //     } else {
  //       console.log({ level: 'Error', message: 'Login Error', error: err });

  //       setErrors({
  //         ...errors,
  //         email: 'Unable to log in. Please try again later.',
  //       });
  //     }
  //   }
  // }

  const connectMetamask = async () => {
    setErrors('');
    setShowSpinner(true);
    try {
      if (web3Provider !== config.ethRpc) {
        await web3Provider.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        web3Provider.on('accountChanged', () => {
          console.log('hi');
        });
        if (accounts.length > 0)
          setWalletAddress(accounts[0].toLowerCase());
      } else {
        alert('please install metamask');
      }
    } catch (err) {
      console.log(err.code, err);
      setErrors(err.message);
    }
    setShowSpinner(false);
  };

  const signMetamask = async () => {
    setErrors('');
    setShowSpinner(true);
    try {
      if (wallet) {
        const { msg, isNew } = await API.get('marketplace', `/customer/${wallet}/nonce`);
        const signature = await web3.eth.personal.sign(web3.utils.fromUtf8(msg), wallet);
        const result = await API.post('marketplace', `/customer/${wallet}/signature`, { body: { signature } });
        if (result.success) {
          localStorage.setItem('signature', result.signature);
          localStorage.setItem('wallet', wallet);
          setWalletAddress(wallet);
          setUser({ nonce: result.nonce, email: result.email, name: result.name });
        }
      } else {
        setUser(null);
      }
      sendClose();
    } catch (err) {
      console.log(err.code, err);
      setErrors(err.message);
    }
    setShowSpinner(false);
  };

  return ReactDOM.createPortal(
    <Container>
      {showSpinner && <Spinner />}
      <Main ref={ref}>
        <CloseBtn onClick={sendClose} />
        <p className='title'>LOG IN</p>
        <p>Welcome back to Legends of Crypto.</p>
        <p>Please log in to continue.</p>
        <div className='socials'>
          {web3Provider !== config.ethRpc
            ? (wallet ?
              <StdButton
                width='100%'
                background='rgba(255,255,255,.03)'
                margin='1rem 0'
                onClick={signMetamask}
              >
                <Icon src='/images/metamask-fox.svg' />
                Sign in with Metamask
              </StdButton>
              :
              <StdButton
                width='100%'
                background='rgba(255,255,255,.03)'
                margin='1rem 0'
                onClick={connectMetamask}
              >
                <Icon src='/images/metamask-fox.svg' />
                Connect with Metamask
              </StdButton>)
            :
            <StdButton
              width='100%'
              background='rgba(255,255,255,.03)'
              margin='1rem 0'
              onClick={() => window.location = 'https://metamask.io/download'}
            >
              <Icon src='/images/metamask-fox.svg' />
              Install Metamask
            </StdButton>}
        </div>
        <p className='error-message'>{errors}</p>
        <p className='footer'>
          Need an account?{' '}
          <NavLink to='/signup' onClick={() => sendClose()}>
            <span className='link'>Sign Up</span>
          </NavLink>
        </p>
      </Main>
    </Container>,
    document.getElementById('portal')
  );
};

export default LoginPage;

import React from 'react';
import ReactDOM from 'react-dom';
import {
  ModalWrapper,
  Modal,
  CloseBtn,
  Header,
  Content,
} from './ListingConfirmationModal.styles';
import { fromWei } from '../../utils/useWeb3';
import config from '../../config';
import { Icon } from '../Icons/BaseIcon';

const ListingConfirmationModal = (props) => {
  const { sendClose, nft, currentStep, msg, complete, type } = props;
  const metadata = nft?.tokenMetadata;

  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal>
        <CloseBtn onClick={sendClose} complete={complete} />
        <Header>
          <h3>Complete {type === 'transfer' ? 'Transfer' : 'Listing'}</h3>
        </Header>
        {nft &&
          type === 'transfer' ?
          (
            <Content complete={complete}>
              <h4>{msg}</h4>
              <img src='/images/spinner.gif' alt='spinner' className='spinner' />
              <h4 className='footer'>
                {complete
                  ? 'Transfer Complete'
                  : 'Please do not close your browser.'}
              </h4>
            </Content>
          )
          : (
            <Content complete={complete}>
              <div className='row1'>
                <div className='img-container'>
                  <img
                    src={metadata?.image || '/images/no-image.svg'}
                    alt='Card'
                  />
                </div>
                <div>
                  <h4>{metadata.name}</h4>
                  <h6>{nft?.tokenTokenId}</h6>
                </div>
                <div className='verified'>
                  {nft.contractAddress.toLowerCase() === config.nftAddress && (
                    <div className='license'>
                      <Icon
                        src='/images/green-check.svg'
                        height={17}
                        width={17}
                        margin='0 10px 0 0'
                      />{' '}
                      <p className='small-title'>Contract Verified</p>
                    </div>
                  )}
                </div>
                <div className='price-wrapper'>
                  <p>Price:</p>
                  <div className='price'>
                    <Icon
                      src='/images/matic-logo.png'
                      height={15}
                      width={15}
                      margin='1rem .75rem 1rem 0'
                    />
                    <p>{fromWei(nft.signedOrder.order.price.toString())}</p>
                  </div>
                </div>
              </div>
              <div className='row2'>
                <div className='step'>
                  <div
                    className={
                      currentStep >= 1
                        ? 'step-number complete'
                        : currentStep === 0
                          ? 'step-number current'
                          : 'step-number'
                    }
                  >
                    1
                  </div>
                  <div className='step-text'>Wallet Connected</div>
                </div>
                <div className='step'>
                  <div
                    className={
                      currentStep >= 2
                        ? 'step-number complete'
                        : currentStep === 1
                          ? 'step-number current'
                          : 'step-number'
                    }
                  >
                    2
                  </div>
                  <div className='step-text'>Approve Item Transfer</div>
                </div>
                <div className='step'>
                  <div
                    className={
                      currentStep >= 3
                        ? 'step-number complete'
                        : currentStep === 2
                          ? 'step-number current'
                          : 'step-number'
                    }
                  >
                    3
                  </div>
                  <div className='step-text'>Sign Listing</div>
                </div>
              </div>
              <h4>{msg}</h4>
              <img src='/images/spinner.gif' alt='spinner' className='spinner' />
              <h4 className='footer'>
                {complete
                  ? 'Listing Complete. Close Modal to Continue.'
                  : 'Please do not close your browser.'}
              </h4>
            </Content>
          )}
      </Modal>
    </ModalWrapper>,
    document.getElementById('portal')
  );
};

export default ListingConfirmationModal;

import styled from 'styled-components';

export const Modal = styled.div`
  display: flex;
  position: relative;
  margin: ${({ theme }) => `calc(2rem + ${theme.navHeight} ) 0`};
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 485px;
  height: 750px;
  color: ${({ theme }) => theme.fontColor};

  h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .pass-container {
    width: 300px;
    margin-bottom: 1rem;
  }
  .pass {
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
  @media screen and (max-width: 485px) {
    width: 100vw;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Auth, API } from 'aws-amplify';
import { Section, Header, Container, Main } from './MyItems.styles';
import { UserContext } from '../../context/UserContext';
import Web3 from 'web3';
import nftABI from '../../utils/LOCGamePlayNFT.abi.json';
import config from '../../config';
import NFTCard from '../../components/NFTCard/NFTCard.component';
import useFilter from '../../utils/useFilter';
import Filters from '../../components/Filters/Filters.component';
import { parseCardId } from '../../utils/utils';
import {
  fetchUsersListedNFTs,
  fetchCurrentRate,
  getCards,
} from '../../utils/api';
import Search from '../../components/Search/Search.component';
import useResizeObserver from '../../utils/useResizeObserver';

const MyItems = (props) => {
  const itemWidth = 295;
  const sortOptions = {
    DATE_DESC: 'Newest First',
    DATE_ASC: 'Oldest First',
  };
  const [orderRefreshCount, setOrderRefreshCount] = useState(0);
  const web3 = new Web3(config.maticRpc[0]);
  const nftAddress = config.nftAddress;
  const contract = new web3.eth.Contract(nftABI, nftAddress);
  const [user, , walletAddress] = useContext(UserContext);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [newCards, setNewCards] = useState([]);
  const [cards, setCards] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [autoFlip, setAutoFlip] = useState(false);
  const [rate, setRate] = useState();
  const { filters, setFilters, filteredData, setFilteredData, resetFilters } =
    useFilter(cards);
  const [showFilter, setShowFilter] = useState(false);

  const sectionRef = useRef();
  const dimensions = useResizeObserver(sectionRef);
  const [headerWidth, setHeaderWidth] = useState();

  async function fetchOrders(skip = 0) {
    const apiName = 'marketplace';
    const path = `/order/user/${walletAddress}`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: {
        skip: skip,
      },
    };

    return API.get(apiName, path, myInit);
  }

  function combineCards() {
    setCards([...newCards, ...cards]);
    setNewCards([]);
  }

  async function updateOrders() {
    try {
      setOrderRefreshCount((prevCount) => prevCount + 1);
      let res = await fetchOrders();
      let data = res.data.data;
      if (res.data.data.length === 20) {
        res = await fetchOrders('20');
        if (res.data.data.length) {
          data = [...data, ...res.data.data];
        }
      }
      data = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Canceled by User'
      );

      const pOrders = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Complete'
      );
      setPendingOrders(pOrders);
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to fetch transactions',
        error: err,
      });
    }
  }

  async function fetchUserOrdersAndCards() {
    const newCardsArr = [];
    let listedTokenIds;
    try {
      const listedData = await fetchUsersListedNFTs(walletAddress);
      listedTokenIds = listedData.map((item) => item.tokenTokenId);
      let res = await fetchOrders();
      let data = res.data.data;
      if (res.data.data.length === 20) {
        res = await fetchOrders('20');
        if (res.data.data.length) {
          data = [...data, ...res.data.data];
        }
      }
      data = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Canceled by User'
      );

      const pOrders = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Complete'
      );

      setPendingOrders(pOrders);

      data.forEach((order) => {
        order.tokens.forEach((token) => newCardsArr.push(token.token));
      });
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to fetch transactions',
        error: err,
      });
    }
    try {
      let cardsArr = await contract.methods.tokensOfOwner(walletAddress).call();
      cardsArr = cardsArr.filter((card) => !newCardsArr.includes(card));
      cardsArr = cardsArr.map((tokenId) => {
        const cardId = parseCardId(tokenId);
        const metadata = cardData.filter((data) => data.id === cardId);
        return metadata[0] ? { tokenId, ...metadata[0] } : null;
      });
      cardsArr = cardsArr.filter((card) => card !== null);
      cardsArr = cardsArr.filter(
        (card) => !listedTokenIds.includes(card.tokenId)
      );

      setCards(cardsArr);
    } catch (err) {
      web3.setProvider(config.maticRpc[1]);
      let cardsArr = await contract.methods.tokensOfOwner(walletAddress).call();
      cardsArr = cardsArr.filter((card) => !newCardsArr?.includes(card));
      cardsArr = cardsArr.map((tokenId) => {
        const cardId = parseCardId(tokenId);
        const metadata = cardData.filter((data) => data.id === cardId);
        return metadata[0] ? { tokenId, ...metadata[0] } : null;
      });
      cardsArr = cardsArr.filter((card) => card !== null);
      cardsArr = cardsArr.filter(
        (card) => !listedTokenIds?.includes(card.tokenId)
      );

      setCards(cardsArr);

      console.log({
        level: 'Error',
        message: "Unable to fetch User's Tokens",
        error: err,
      });
    }
  }

  // fetch Card Data on mount
  useEffect(() => {
    fetchCurrentRate('MATIC', 'USDT').then((rate) => setRate(parseFloat(rate)));
    getCards().then((data) => setCardData(data));
    if (window.innerWidth < 910) {
      setShowFilter(false);
    }
  }, []);

  useEffect(() => {
    if (!dimensions) return;
    let cardsToFit = Math.ceil(dimensions.width / itemWidth);
    setHeaderWidth(itemWidth * (cardsToFit - 1));
  }, [dimensions]);

  useEffect(() => {
    if (walletAddress && cardData) {
      fetchUserOrdersAndCards();
    }
  }, [walletAddress, cardData]);

  useEffect(() => {
    setFilteredData(cards);
  }, [cards]);

  useEffect(() => {
    if (autoFlip) setAutoFlip(false);
  }, [autoFlip]);

  useEffect(() => {
    let currentTimeout;
    if (pendingOrders.length > 0 && orderRefreshCount < 15) {
      currentTimeout = setTimeout(updateOrders, 45000);
    }
    return () => {
      if (currentTimeout) clearTimeout(currentTimeout);
    };
  }, [pendingOrders]);

  return (
    <Container>
      <Filters
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        items={cards}
        sortOptions={sortOptions}
      />
      <Main>
        {/* <Header maxWidth={headerWidth}>
          <div className='filter-wrapper'>
            <h4>
              {filteredData.length} / {cards.length} Cards
            </h4>
            <div
              className='filterMenu'
              onClick={() => {
                setShowFilter(!showFilter);
                combineCards();
              }}
            >
              Filters {showFilter ? <span className='close'>X</span> : ''}
            </div>
          </div>
          <Search
            filters={filters}
            setFilters={setFilters}
            resetFilters={resetFilters}
            combineCards={combineCards}
          />
        </Header> */}
        <Section ref={sectionRef}>
          {filteredData.map((card) => (
            <NFTCard
              key={card.tokenId}
              card={card}
              nft={null}
              rate={rate}
              size='small'
            />
          ))}
        </Section>
      </Main>
    </Container>
  );
};

export default MyItems;

import { censorInput } from '../../utils/utils'

export default function validateUser(values) {
  const errors = {};
  if (!values.name) {
    errors.name = 'Last Name is required.';
  }
  const [isDirty, input] = censorInput(values?.name)
  if (isDirty)
    errors.name = `${input} is flagged as inappropriate`
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid email is required.';
  }

  return errors;
}

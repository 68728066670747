import styled from 'styled-components';

const height = 30;
const width = 550;

export const Container = styled.div`
  height: ${height}px;
  width: ${width}px;
  margin-bottom: 6rem;

  p {
    //font-family: Dosis;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  h3 {
    //font-family: Inter;
    font-size: 1.12rem;
    font-weight: 600;
    line-height: 1.3rem;
    text-align: left;
  }
  .heading {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0.75rem;
  }

  #address {
    width: calc(100% - 50px);
    position: relative;
  }

  @media screen and (max-width: 680px) {
    width: 350px;

    #address {
      font-size: 14px;
    }
  }
`;

export const CopyButton = styled.button`
  height: ${height - 4}px;
  width: ${height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 4px;
  position: absolute;
  top: 50%;
  right: 0px;
  cursor: pointer;
  transform: translate(calc(100% + 8px), -50%);

  &:active {
    transform: scale(95%) translate(100%, -50%);
  }

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

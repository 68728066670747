import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  background-color: ${({ theme }) => theme.background.light};
  border-radius:1.5em;
  .logo {
    width: 15%;
    color: ${({ theme }) => theme.background.dark};
    font-family: 'Baron Neue', sans-serif;
    font-weight: 900;
    line-height: 100%;
    font-size: 1.25em;
    margin-left:1.5em;
  }
  .social {
    width: 15%;
    margin-right:1.5em;
  }
  ul {
    display: flex;
    list-style: none;
  }
  li {
    margin-right: 1.5rem;
    text-transform: uppercase;
    font-weight: 400;
    a {
      color: ${({ theme }) => theme.background.dark};
      opacity: 0.6;
      transition: opacity 200ms ease, transform 200ms linear,
        -webkit-transform 200ms linear;
      &:hover {
        opacity: 1;
      }
    }
  }

  .icon-wrapper {
    color: ${({ theme }) => theme.background.dark};
    transition: opacity 200ms ease, transform 200ms linear,
      -webkit-transform 200ms linear;

    &:hover {
      opacity: 1;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-left: 2rem;
  }
  @media screen and (max-width: 649px) {
    flex-direction: column-reverse;
    ul {
      margin-bottom: 1rem;
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Main } from '../Page.styles';
import {
  Container,
  Header,
  AvatarContainer,
  Avatar,
  EditButton,
  Username,
  Form,
  Section,
} from './Settings.styles';
import StdButton from '../../components/StdButton/StdButton.component';
import StyledInput from '../../components/Input/Input.component';
import MessageBox from '../../components/MessageBox/MessageBox.component';
import useForm from '../../utils/useForm';
import validateUser from './validateUser';
import { UserContext } from '../../context/UserContext';
import { Auth, Storage } from 'aws-amplify';
import PasswordChange from '../PasswordChange/PasswordChange.component';

const SettingsPage = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [msg, setMsg] = useState({ visible: false, status: 'success' });
  const dialogRef = useRef();
  const { values, errors, handleSubmit, handleChange, setValues } = useForm(
    updateUser,
    validateUser
  );

  const getAvatar = (user) => {
    if (user.attributes['custom:avatar'])
      return `http://static-files.locgame.io/public/${user.attributes['custom:avatar']}`;
    else if (user.attributes.picture) return user.attributes.picture;
    else return '/images/user.svg';
  };

  async function updateUser() {
    try {
      let userInfo = {};
      let currentUser = await Auth.currentAuthenticatedUser();
      let oAuthUser = /^(google|facebook)./.test(currentUser.username);
      if (oAuthUser) {
        userInfo = {
          'custom:firstName': values.given_name,
          'custom:lastName': values.family_name,
        };
      } else {
        userInfo = {
          'custom:firstName': values.given_name,
          'custom:lastName': values.family_name,
          email: values.email,
        };
      }
      await Auth.updateUserAttributes(currentUser, userInfo);
      setMsg({
        visible: true,
        status: 'success',
        msg: 'Information Updated.',
      });

      currentUser = await Auth.currentAuthenticatedUser();
      const roles =
        currentUser.signInUserSession.accessToken.payload['cognito:groups'];
      let attributes = {
        _id: currentUser.username,
        given_name:
          currentUser.attributes['custom:firstName'] ||
          currentUser.attributes.given_name,
        family_name:
          currentUser.attributes['custom:lastName'] ||
          currentUser.attributes.family_name,
        picture: getAvatar(currentUser),
        email: currentUser.attributes.email,
        roles,
        wishlist: currentUser.attributes['custom:wishlist'],
      };
      if (attributes.wishlist) {
        attributes.wishlist = JSON.parse(attributes.wishlist);
      }
      // if (user.email !== currentUser.email && !oAuthUser) {
      //   history.push({
      //     pathname: '/verifyemail',
      //     props: {
      //       email: values.email,
      //       prevEmail: user.email,
      //     },
      //   });
      // } else {
      setUser(attributes);
      setTimeout(() => {
        setMsg({
          visible: false,
          status: 'success',
          msg: 'Information Updated.',
        });
      }, 3000);
      //}
    } catch (err) {
      setMsg({ visible: true, status: 'fail', msg: 'Unable to Update.' });
      setTimeout(() => {
        setMsg({ visible: false, status: '' });
      }, 3000);

      console.log({
        level: 'Error',
        message: 'Unable to update user attributes',
        error: err,
      });
    }
  }

  useEffect(() => {
    setValues(user);
  }, [user]);

  const handleImageUpload = async (e) => {
    const image = e.target.files[0];
    if (!image) return;
    if (image.size > 6000000) {
      setMsg({
        visible: true,
        status: 'fail',
        msg: 'Image too large. 6MB',
      });
      setTimeout(() => {
        setMsg({ visible: false, status: '' });
      }, 3000);
    } else if (image) {
      setMsg({
        visible: true,
        status: 'success',
        msg: 'Image Uploading',
      });
      let fileName = `${uuidv4()}.png`;
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (evt) => {
        const imgEl = document.createElement('img');
        imgEl.src = evt.target.result;
        imgEl.onload = (e) => {
          const MAX_WIDTH = 200;
          const scale = MAX_WIDTH / e.target.width;
          const canvas = document.createElement('canvas');

          canvas.width = MAX_WIDTH;
          canvas.height = e.target.height * scale;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(e.target, 0, 0, canvas.width, canvas.height);

          ctx.canvas.toBlob(async (smImage) => {
            try {
              //Storage.configure({ level: 'public' });
              const res = await Storage.put(`${fileName}`, smImage, {
                contentType: 'image/png',
              });
              let currentUser = await Auth.currentAuthenticatedUser();
              let avatar = currentUser.attributes['custom:avatar'];
              if (avatar) {
                Storage.remove(`${avatar}`);
              }
              await Auth.updateUserAttributes(currentUser, {
                'custom:avatar': res.key,
              });
              setMsg({
                visible: true,
                status: 'success',
                msg: 'Image Updated Successfully',
              });
              currentUser = await Auth.currentAuthenticatedUser();
              const roles =
                currentUser.signInUserSession.accessToken.payload[
                'cognito:groups'
                ];
              let attributes = {
                _id: currentUser.username,
                given_name:
                  currentUser.attributes['custom:firstName'] ||
                  currentUser.attributes.given_name,
                family_name:
                  currentUser.attributes['custom:lastName'] ||
                  currentUser.attributes.family_name,
                picture: getAvatar(currentUser),
                email: currentUser.attributes.email,
                roles,
                wishlist: currentUser.attributes['custom:wishlist'],
              };
              if (attributes.wishlist) {
                attributes.wishlist = JSON.parse(attributes.wishlist);
              }
              setUser(attributes);
              setTimeout(() => {
                setMsg({
                  visible: false,
                  status: 'success',
                  msg: 'Image Updated Successfully',
                });
              }, 3000);
            } catch (err) {
              setMsg({
                visible: true,
                status: 'fail',
                msg: 'Unable to update',
              });
              setTimeout(() => {
                setMsg({ visible: false, status: '' });
              }, 3000);
              console.log({
                level: 'Error',
                message: 'Unable to update user image attribute',
                error: err,
              });
            }
          }, 'image/png');
        };
      };
    }
  };

  return (
    <Container>
      <Main>
        <Header>
          <AvatarContainer>
            <Avatar src={user.picture ? user.picture : '/images/user.svg'} />
            {/* <form>
              <input
                accept='image/*'
                id='picture'
                name='picture'
                type='file'
                onChange={handleImageUpload}
                ref={dialogRef}
              />
              <label
                id='pic-label'
                onClick={() => {
                  dialogRef.current.click();
                }}
              >
                <EditButton src='/images/edit.svg' />
              </label>
            </form> */}
          </AvatarContainer>
          {values.given_name && (
            <Username>{`${values.given_name} ${values.family_name}`}</Username>
          )}

          <MessageBox
            className={msg.visible ? 'visible' : null}
            status={msg.status}
            msg={msg.msg}
          />
        </Header>
        <div className='row'>
          <Section>
            <h4>Personal Information</h4>
            {values.email && (
              <Form>
                <StyledInput
                  id='name'
                  name='name'
                  type='text'
                  value={values.name}
                  errors={errors.name}
                  placeholder='Name'
                  autoComplete='off'
                  onChange={handleChange}
                  width='300px'
                />
                <StyledInput
                  id='email'
                  name='email'
                  type='email'
                  value={values.email}
                  errors={errors.email}
                  placeholder='Email'
                  autoComplete='off'
                  onChange={handleChange}
                  width='300px'
                  disabled={true}
                />
              </Form>
            )}
            <StdButton onClick={handleSubmit} width={300} height={45}>
              Save Changes
            </StdButton>
          </Section>
          {/*!/^(google|facebook)./.test(user._id) && (
            <Section>
              <PasswordChange
                passedUser={props?.passedHistory?.location?.user}
              />
            </Section>
          )*/}
        </div>
      </Main>
    </Container>
  );
};

export default SettingsPage;

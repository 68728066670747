import React, { useRef, useState, useContext, useEffect } from 'react';
import { API } from 'aws-amplify';
import ReCAPTCHA from "react-google-recaptcha";
import StyledInput from '../../components/Input/Input.component';
import { UserContext } from '../../context/UserContext';
import Spinner from '../../components/Spinner/Spinner';
import StdButton from '../../components/StdButton/StdButton.component';
import { Icon } from '../../components/Icons/BaseIcon';
import { Main } from './SignupPage.styles';
import config from '../../config';

const Screen2 = (props) => {
  const {
    values,
    handleChange,
    handleSubmit,
    showSpinner,
    setPage,
    setValues,
    showRecaptcha,
    web3,
    web3Provider,
  } = props;
  const [, setUser, wallet, setWalletAddress] = useContext(UserContext);
  const [errors, setErrors] = useState(false);
  const termsRef = useRef();
  const recaptchaRef = useRef();
  // const newsRef = useRef(); // news subscription @ login currently disabled.

  useEffect(() => {
    if (web3Provider !== config.ethRpc)
      web3.eth.getAccounts().then(accounts => accounts.length > 0 && setWalletAddress(accounts[0].toLowerCase()));
  }, []);

  const connectMetamask = async () => {
    setErrors({});
    try {
      if (web3Provider !== config.ethRpc) {
        await web3Provider.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0)
          setWalletAddress(accounts[0].toLowerCase());
      } else {
        alert('please install metamask');
      }
    } catch (err) {
      console.log(err.code, err);
      setErrors({ system: err.message });
    }
  };

  const signMetamask = async () => {
    setErrors({});
    try {
      if (wallet) {
        const { msg, isNew } = await API.get('marketplace', `/customer/${wallet}/nonce`);
        const signature = await web3.eth.personal.sign(web3.utils.fromUtf8(msg), wallet);
        const result = await API.post('marketplace', `/customer/${wallet}/signature`, { body: { signature } });
        if (result.success) {
          if (isNew !== true) {
            setErrors({ system: 'account already exists' });
          } else {
            setValues({ ...values, signed: true, signature: result.signature, new: result.new });
          }
        } else {
          setErrors({ system: 'failed to aquire signature' });
        }
      }
    } catch (err) {
      console.log(err.code, err);
      setErrors({ system: err.message });
    }
  };

  return (
    <Main>
      {showSpinner && <Spinner />}
      <p className='title'>SIGN UP</p>
      {errors.system && <p className='msg'>{errors.system}</p>}
      <p>
        {values.email}
        <span
          className='change-link'
          onClick={() => {
            setErrors((prev) => ({ ...prev, system: '' }));
            setPage(1);
          }}
        >
          Change
        </span>
      </p>
      {web3Provider !== config.ethRpc
        ? (wallet ?
          <StdButton
            width='100%'
            background='rgba(255,255,255,.03)'
            margin='1rem 0'
            onClick={signMetamask}
          >
            <Icon src='/images/metamask-fox.svg' />
            Sign up with Metamask
          </StdButton>
          :
          <StdButton
            width='100%'
            background='rgba(255,255,255,.03)'
            margin='1rem 0'
            onClick={connectMetamask}
          >
            <Icon src='/images/metamask-fox.svg' />
            Connect with Metamask
          </StdButton>)
        :
        <StdButton
          width='100%'
          background='rgba(255,255,255,.03)'
          margin='1rem 0'
          onClick={() => window.location = 'https://metamask.io/download'}
        >
          <Icon src='/images/metamask-fox.svg' />
          Install Metamask
        </StdButton>}
      <div className='terms-container'>
        <input
          ref={termsRef}
          type='checkbox'
          name='terms'
          id='terms'
          value={values.terms}
          onChange={() => {
            setValues({ ...values, terms: termsRef.current.checked });
          }}
        />
        <label className='terms' htmlFor='terms'>
          By using our website, you agree to our
          <a
            className='link'
            href='https://www.locgame.io/privacy-policy'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy{' '}
          </a>
          and our{' '}
          <a
            className='link'
            href='https://app.termly.io/document/terms-of-use-for-ecommerce/87fd563d-a253-40fa-a323-a2f856667cf6#agreement'
            target='_blank'
            rel='noreferrer'
          >
            Terms and Conditions
          </a>
          .
        </label>
      </div>
      <div>
        {showRecaptcha ?
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={config.reCaptchaKeyV2}
            onChange={(recaptcha) => {
              console.log(recaptcha);
              setValues({ ...values, recaptcha });
            }}
          />
          : ''}
      </div>
      {/* <div className='terms-container'>
        <input
          ref={newsRef}
          type='checkbox'
          name='news'
          id='news'
          value={values.news}
          onChange={() => {
            setValues({ ...values, news: newsRef.current.checked });
          }}
        />
        <label className='terms' htmlFor='news'>
          Subscribe to our Newsletter!
        </label>
      </div> */}

      <div className='btns'>
        <StdButton
          width={300}
          onClick={handleSubmit}
          disabled={values.signed && !errors.system && termsRef.current && termsRef.current.checked ? false : true}
        >
          Sign Up
        </StdButton>
      </div>
    </Main>
  );
};

export default Screen2;

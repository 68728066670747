/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Main } from '../Page.styles';
import { Section, Row, Column } from './WalletPage.styles';
import ABI from 'erc-20-abi';
import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import WalletBalance from '../../components/WalletBalance/WalletBalance.component';
import WalletAddress from '../../components/WalletAddress/WalletAddress.component';
import TransactionList from '../../components/TransactionList/TransactionList.component';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Spinner from '../../components/Spinner/Spinner';

import config from '../../config';
import StdButton from '../../components/StdButton/StdButton.component';
import { checkChainId } from '../../utils/utils';
import ErrorModal from '../../components/ErrorModal/ErrorModal.component';

const CONTRACT_ADDRESS = config.LOCGcontract;

const WalletPage = ({ rate, web3, provider, setProvider }) => {
  const ethereum = window.ethereum;
  const [contract, setContract] = useState(
    new web3.eth.Contract(ABI, CONTRACT_ADDRESS)
  );
  const [chainId, setChainId] = useState(null);
  const [networkError, setNetworkError] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [msg, setMsg] = useState('');
  const [
    user,
    ,
    walletAddress,
    setWalletAddress,
    ethBalance,
    setEthBalance,
    tokenBalance,
    setTokenBalance,
  ] = useContext(UserContext);

  const handleChain = async () => {
    await checkChainId(web3, config.ethChainId, setNetworkError, setChainId);
  };

  useEffect(() => {
    if (walletAddress) {
      setContract(new web3.eth.Contract(ABI, CONTRACT_ADDRESS));
      updateBalance(walletAddress);
    }
  }, [walletAddress, chainId]);

  const connectWallet = async () => {
    setMsg('Connecting');
    setIsConnecting(true);
    if (!ethereum && provider !== config.ethRpc) {
      let wcProvider = new WalletConnectProvider({
        infuraId: '59ab074c808946a48eb6bc5b6b2aafcd',
      });
      setProvider(wcProvider);
    }
    if (web3) {
      try {
        if (ethereum) await ethereum.request({ method: 'eth_requestAccounts' });
        else await provider.enable();
        let accounts = await web3.eth.getAccounts();
        setWalletAddress(accounts[0].toLowerCase());
        updateBalance(accounts[0]);
        setMsg('');
        setIsConnecting(false);
      } catch (err) {
        setMsg('Unable to connect to wallet.');
        setIsConnecting(false);
      }
    } else {
      setMsg('Metamask extension not detected!');
    }
  };

  const updateBalance = async (fromAddress) => {
    await handleChain(web3, config.ethChainId, setNetworkError, setChainId);
    if (fromAddress !== undefined) {
      try {
        let value = await web3.eth.getBalance(fromAddress);
        setEthBalance(web3.utils.fromWei(value, 'ether'));
        let tokens = await contract.methods.balanceOf(fromAddress).call();
        tokens = web3.utils.fromWei(tokens, 'Ether');
        setTokenBalance(tokens);
      } catch (err) {
        console.log({
          level: 'Error',
          message: 'Unable to fetch user balance',
          error: err,
        });
      }
    } else {
      setEthBalance('0');
      setTokenBalance('0');
    }
  };

  return (
    <Container>
      <Main>
        {networkError && (
          <ErrorModal
            title='Please change your chain'
            msg={`${config.maticNetworkName} is required.`}
          />
        )}
        {!walletAddress ? (
          <Section>
            <h2>Wallet is not connected</h2>
            <p>Please connect your wallet to continue</p>
            <StdButton onClick={connectWallet} width={244} height={50}>
              Connect
            </StdButton>
          </Section>
        ) : (
          <Row>
            <Column>
              <WalletBalance balance={[ethBalance, tokenBalance]} rate={rate} />
              <WalletAddress address={walletAddress} />
            </Column>
            <Column>
              <TransactionList userId={walletAddress} />
            </Column>
          </Row>
        )}
        {isConnecting && <Spinner msg1={msg} />}
      </Main>
    </Container>
  );
};

export default WalletPage;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.background.light};
  border-radius: 1.5em;
  margin: .25rem 0;

  .back {
    position: absolute;
    top: 0;;
    left: 0;
    cursor: pointer;
  }

  @media screen and (max-width: 545px) {
    max-width: 350px;
  }`;

export const Main = styled.main`
  display: flex;
  gap: 0 2em;
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { API } from 'aws-amplify';
import { Container, Main } from '../Page.styles';
import { Section } from './WishList.styles';
import { UserContext } from '../../context/UserContext';
import ProductCard from '../../components/ProductCard/ProductCard.component';
import { fetchNFT } from '../../utils/api';
import config from '../../config';
import NFTCard from '../../components/NFTCard/NFTCard.component';

const WishList = ({ rate, maticRate }) => {
  const [user] = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [nfts, setNfts] = useState([]);

  useEffect(() => {
    if (user.wishlist) {
      const productsList = [];
      const nftsList = [];
      user.wishlist.forEach((item) => {
        if (!item) return;
        if (item.includes('-')) {
          nftsList.push(item);
        } else {
          productsList.push(item);
        }
      });
      fetchProducts(productsList);
      fetchNFTs(nftsList);
    }
  }, [user.wishlist]);

  function getProduct(id) {
    const apiName = 'marketplace';
    const path = `/product/${id}`;

    return API.get(apiName, path);
  }

  async function fetchProducts(products) {
    if (products.length > 0) {
      try {
        const items = [];
        await Promise.all(
          products.map(async (item) => {
            let product = await getProduct(item);
            items.push(product);
          })
        );
        if (items.length > 0) setProducts(items);
      } catch (err) {
        console.log({
          level: 'Error',
          message: 'Unable to fetch product',
          error: err,
        });
      }
    }
  }
  async function fetchNFTs(nfts) {
    if (nfts.length > 0) {
      try {
        const items = [];
        await Promise.all(
          nfts.map(async (item) => {
            const [contract, id] = item.split('-');
            let nft = await fetchNFT(config.maticNetworkName, contract, id);
            items.push(nft);
          })
        );
        if (items.length > 0) setNfts(items);
      } catch (err) {
        console.log({
          level: 'Error',
          message: 'Unable to fetch NFTs',
          error: err,
        });
      }
    }
  }

  return (
    <Container>
      <Main>
        {products.length > 0 && (
          <Section>
            {products.map((product) => (
              <ProductCard key={product._id} product={product} rate={rate} />
            ))}
          </Section>
        )}
        {nfts && (
          <Section>
            {nfts.map((nft) => {
              return (
                <NFTCard
                  key={nft.id}
                  nft={nft}
                  size='small'
                  page='wishlist'
                  rate={maticRate}
                />
              );
            })}
          </Section>
        )}
      </Main>
    </Container>
  );
};

export default WishList;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { parseNftAttributes } from './utils';

const editions = {
  genesis: ['001', '002'],
  apollo: ['003'],
  awakening: ['004'],
  legendsAwakening: ['005'],
};

const useFilter = (data, type = 'card') => {
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    sort: 'price-asc',
    edition: [],
    rarity: [],
    type: [],
  });

  useEffect(() => {
    if (data && type === 'card') {
      filterCardData();
    }
    if (data && type === 'nft') {
      filterNftData();
    }
    if (data && type === 'pack') {
      filterPackData();
    }
  }, [filters, data]);

  const resetFilters = () => {
    setFilters({
      search: '',
      sort: 'newest',
      edition: [],
      rarity: [],
      type: [],
    });
  };

  const filterCardData = () => {
    let filteredSet = data;

    // filter by edition

    if (filters.edition?.length > 0) {
      let selectedEditions = [];
      filters.edition.forEach((edition) =>
        selectedEditions.push(...editions[edition])
      );
      filteredSet = filteredSet.filter((card) =>
        selectedEditions.includes(card.set)
      );
    }

    // filter by visual rarity if required
    if (filters.rarity?.length > 0) {
      filteredSet = filteredSet.filter((card) =>
        filters.rarity?.includes(card.vRarity)
      );
    }
    // filter by type if required
    if (filters.type?.length > 0) {
      filteredSet = filteredSet.filter((card) =>
        filters.type?.includes(card.atkType?.toLowerCase())
      );
    }

    if (filters.sort === 'DATE_ASC') {
      filteredSet = filteredSet.sort((a, b) => {
        if (a.releaseDate > b.releaseDate) return 1;
        if (a.releaseDate < b.releaseDate) return -1;
        return 0;
      });
    }
    if (filters.sort === 'DATE_DESC') {
      filteredSet = filteredSet.sort((a, b) => {
        if (a.releaseDate < b.releaseDate) return 1;
        if (a.releaseDate > b.releaseDate) return -1;
        return 0;
      });
    }

    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((card) => re.test(card.name));
    }

    setFilteredData([...filteredSet]);
  };

  const filterNftData = () => {
    let filteredSet = data;

    if (filters.edition?.length > 0) {
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        const edition = card['edition set'].toLowerCase();
        if (edition.includes('genesis'))
          card.set = 'genesis';
        else if (edition.includes('apollo'))
          card.set = 'apollo';
        else if (edition.includes('legends awakening'))
          card.set = 'legendsAwakening';
        else if (edition.includes('awakening'))
          card.set = 'awakening';
        return filters.edition.includes(card.set);
      });
    }

    // // filter by visual rarity if required
    if (filters.rarity?.length > 0) {
      filteredSet = filteredSet.filter((nft) => {
        const card = parseNftAttributes(nft);
        return filters.rarity?.includes(card['visual rarity'].toLowerCase());
      });
    }

    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((card) =>
        re.test(card.tokenMetadata.name)
      );
    }

    setFilteredData(filteredSet);
  };

  const filterPackData = () => {
    let filteredSet = data;

    if (filters.edition?.length > 0) {
      let selectedEditions = [];
      filters.edition.forEach((edition) =>
        selectedEditions.push(...editions[edition])
      );
      filteredSet = filteredSet.filter((pack) => {
        pack.set = pack.items[0].set;

        return selectedEditions.includes(pack.set);
      });
    }

    // filter by visual rarity if required
    if (filters.rarity?.length > 0) {
      filteredSet = filteredSet.filter((pack) => {
        pack.rarity = getRarity(pack);
        return filters.rarity?.includes(pack.rarity);
      });
    }

    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      filteredSet = filteredSet.filter((pack) => re.test(pack.title));
    }
    if (filters.sort === 'PRICE_ASC') {
      filteredSet = filteredSet.sort((a, b) => a.price - b.price);
    }
    if (filters.sort === 'PRICE_DESC') {
      filteredSet = filteredSet.sort((a, b) => b.price - a.price);
    }
    if (filters.sort === 'OLDEST') {
      filteredSet = filteredSet.sort((a, b) =>
        a.created > b.created ? 1 : a.created < b.created ? -1 : 0
      );
    }
    if (filters.sort === 'NEWEST') {
      filteredSet = filteredSet.sort((a, b) =>
        a.created < b.created ? 1 : a.created > b.created ? -1 : 0
      );
    }

    setFilteredData([...filteredSet]);
  };

  return { filteredData, setFilteredData, filters, setFilters, resetFilters };
};

export default useFilter;

function getRarity(pack) {
  const packTitle = pack.title.toLowerCase();
  if (packTitle.includes('prestige')) return '002';
  if (packTitle.includes('super elite')) return '004';
  if (packTitle.includes('elite')) return '003';
  if (packTitle.includes('legendary')) return '005';
  return '001';
}

import React from 'react';
import StdButton from '../StdButton/StdButton.component';
import { Container } from './WalletBalance.styles';

const WalletBalance = (props) => {
  const [, tokenBalance] = props.balance;

  return (
    <Container>
      <p className='heading'>Your balance:</p>
      {tokenBalance && (
        <h2>
          {parseInt(tokenBalance)} LOCG{' '}
          <span>{(parseFloat(tokenBalance) / props.rate).toFixed(2)} usdt</span>
        </h2>
      )}
      <a
        href='https://app.uniswap.org/#/swap?use=V2'
        target='_blank'
        rel='noreferrer'
      >
        <StdButton height={50} width={244}>
          Buy LOCG
        </StdButton>
      </a>
    </Container>
  );
};

export default WalletBalance;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 85px;
  padding: 1rem 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 650px) {
    width: 310px;
    padding: 0.5rem;
  }
  @media screen and (max-width: 390px) {
    width: 100%;
    padding: 0;
  }
`;

export const Image = styled.img`
  height: 65px;
  width: 65px;
  border-radius: 50%;
  margin-right: 1rem;

  @media screen and (max-width: 650px) {
    height: 42px;
    width: 42px;
    border-radius: 50%;
    margin-right: 0.5rem;
  }
  @media screen and (max-width: 390px) {
    display: none;
  }
`;

export const Description = styled.div`
  flex-grow: 1;
  .small {
    font-size: 12px;
    line-height: 16px;
  }
  .title {
    font-size: 20px;
    line-height: 24px;
  }
  a,
  .staking {
    display: inline-block;
    //font-family: Dosis;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    background: linear-gradient(
      283.41deg,
      #93aeff -64.26%,
      #4e4aff -64.24%,
      #ffffff 125%
    );
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 650px) {
    a,
    .staking {
      display: inline-block;
      font-size: 12px;
      letter-spacing: 0.75px;
      width: 23ch;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .heading {
      flex-direction: column;
      align-items: flex-start;
      h5 {
        font-size: 0.75rem;
        margin: 0.25rem 0;
      }
    }
  }
  @media screen and (max-width: 390px) {
    width: 300px;
  }
`;

export const StatusIcon = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  padding: 0 1rem;
  color: ${({ status }) =>
    status === 'Failed' || status === 'Canceled by User'
      ? '#FF4444'
      : status === 'Complete'
      ? '#3AE2A6'
      : 'rgba(255, 230, 0, 1)'};

  @media screen and (max-width: 650px) {
    font-size: 8px;
    min-width: 70px;
    padding: 0 0.8rem;
  }
`;

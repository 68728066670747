import React, { useState } from 'react';
import { Modal } from './PasswordReset.styles';
import { Icon } from '../Icons/BaseIcon';
import useForm from '../../utils/useForm';
import validate from './validate';
import { Auth } from 'aws-amplify';
import StyledInput from '../Input/Input.component';
import StdButton from '../StdButton/StdButton.component';
import { useHistory } from 'react-router-dom';

const PasswordReset = (props) => {
  const [msg, setMsg] = useState('');
  const [showPass, setShowPass] = useState(false);
  const history = useHistory();

  const { values, errors, handleSubmit, handleChange, setErrors } = useForm(
    updatePassword,
    validate
  );

  async function updatePassword() {
    try {
      await Auth.forgotPasswordSubmit(
        values.email,
        values.code,
        values.password
      );
      setMsg('Password Successfully Updated. Redirecting to Login');
      setTimeout(() => {
        history.push('/');
        return;
      }, 3000);
    } catch (err) {
      setErrors({ code: err.message });
    }
  }
  async function sendCode() {
    setErrors({});
    setMsg('');
    if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
      setErrors({ email: 'A Valid Email is Required.' });
      return;
    }
    try {
      await Auth.forgotPassword(values.email);
      setMsg('Verification Code Sent');
    } catch (err) {
      setErrors({
        email: "A User with this email doesn't Exist",
      });
    }
  }

  return (
    <Modal>
      <h2>Reset Password</h2>

      <StyledInput
        type='text'
        name='email'
        value={values.email}
        placeholder=' Email'
        onChange={handleChange}
        autoComplete='off'
        width='300px'
        errors={errors.email}
      />

      <span className={msg ? 'success' : null}>{msg}</span>

      <StdButton width={300} height={45} margin='1rem 0' onClick={sendCode}>
        <Icon src='/images/sms.svg' />
        Send Code
      </StdButton>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <StyledInput
          type='text'
          name='code'
          value={values.code}
          placeholder=' Verification Code'
          onChange={handleChange}
          autoComplete='off'
          errors={errors.code}
          width='300px'
        />

        <StyledInput
          name='password'
          id='password'
          value={values.password}
          errors={errors.password}
          type={showPass ? 'text' : 'password'}
          placeholder='Password'
          autoComplete='off'
          onChange={handleChange}
          width='300px'
          showIcon={true}
          togglePassword={() => setShowPass(!showPass)}
          showPassword={showPass}
        />
        <StyledInput
          type={showPass ? 'text' : 'password'}
          name='confirmPassword'
          value={values.confirmPassword}
          placeholder='Confirm Password'
          onChange={handleChange}
          autoComplete='off'
          width='300px'
          showIcon={true}
          togglePassword={() => setShowPass(!showPass)}
          showPassword={showPass}
        />
        <div className='pass-container'>
          <p className='pass'>Password must:</p>
          <p className='pass'>- Be 8 or more characters.</p>
          <p className='pass'>
            - Include one uppercase and one &nbsp;&nbsp;&nbsp;lowercase letter.
          </p>
          <p className='pass'>- Include a number.</p>
          <p className='pass'>- Include a special character.</p>
        </div>
      </form>
      <StdButton
        width={300}
        height={45}
        margin='.5rem 0'
        onClick={handleSubmit}
      >
        <Icon src='/images/sms.svg' />
        Update Password
      </StdButton>
    </Modal>
  );
};

export default PasswordReset;

import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;

  input {
    height: 26px;
    background: none;
    border: none;
    outline: none;
    color: ${({ theme }) => theme.fontColor};
    border-bottom: 1px solid ${({ theme }) => theme.borderMutedColor};
  }

  @media screen and (max-width: 390px) {
    padding: 0;
    margin: 0;
    input {
      width: 230px;
    }
  }
`;

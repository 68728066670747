/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/UserContext';
import { Container, Content } from './SacrificePage.styles';
import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/ErrorModal/ErrorModal.component';
import ABI from '../../utils/LOCGamePlayNFT.abi.json';
import config from '../../config';
import { USER_REJECTED } from '../../constants';
import { checkChainId } from '../../utils/utils';
import NFTImage from '../../components/NFTImage/NFTImage.component';
import {
  addToken,
  fetchNFT,
  getGasPrice,
  getMaxPriorityFee,
  postNftOrder,
} from '../../utils/api';
import StdButton from '../../components/StdButton/StdButton.component';
import { Player } from '@lottiefiles/react-lottie-player';
import { toWei } from '../../utils/useWeb3';

const SacrificePage = (props) => {
  const web3 = props.web3;
  const animationRef = useRef();
  const imgRef = useRef();
  const [contract, setContract] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [nft, setNft] = useState(null);
  const [, , walletAddress] = useContext(UserContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [validMetadata, setValidMetadata] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState('');

  const history = useHistory();

  const id = props.match.params.id;

  const handleChain = async () => {
    await checkChainId(web3, config.maticChainId, setNetworkError, setChainId);
  };

  useEffect(() => {
    if (!web3.givenProvider || !web3.givenProvider.isMetaMask) return;
    handleChain();
    web3.eth.currentProvider.on('chainChanged', handleChain);
    if (chainId === config.maticChainId)
      setContract(new web3.eth.Contract(ABI, config.nftAddress));
    getNftData();
    return () => {
      web3.eth.currentProvider.removeListener('chainChanged', handleChain);
    };
  }, []);

  useEffect(() => {
    if (chainId !== config.maticChainId) return;
    setContract(new web3.eth.Contract(ABI, config.nftAddress));
  }, [chainId]);

  async function getNftData() {
    setShowSpinner(true);
    try {
      let currentNFT = await fetchNFT(
        config.maticNetworkName,
        config.nftAddress,
        id
      );
      if (!currentNFT) {
        await addToken(config.maticNetworkName, config.nftAddress, id);
        currentNFT = await fetchNFT(
          config.maticNetworkName,
          config.nftAddress,
          id
        );
      }
      setNft(currentNFT);
      setValidMetadata(currentNFT?.tokenMetadata?.valid);
      setShowSpinner(false);
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
    }
  }

  const handleBanish = async () => {
    const gasPrice = toWei(await getGasPrice('polygon'), 'Gwei');
    const maxPriorityFeePerGas = await getMaxPriorityFee('polygon');
    // const nftContract = new web3.eth.Contract(gameplayABI, config.nftAddress);
    await contract.methods
      .burn(nft.tokenTokenId)
      .send({
        maxPriorityFeePerGas,
        maxFeePerGas: gasPrice,
        from: walletAddress,
      })
      .on('error', (err) => console.log(err))
      .on('transactionHash', async (txHash) => {
        const nftOrder = {
          txHash,
          seller: {
            wallet: walletAddress,
          },
          amount: {
            currency: 'MATIC',
            value: 0,
          },
          chain: 'matic',
          tokens: [nft.tokenTokenId],
          status: 'Pending',
        };
        //Post Order
        try {
          await postNftOrder(nftOrder);
          animationRef.current.container.style.opacity = 1;
          animationRef.current.play();
          setTimeout(() => {
            imgRef.current.style.opacity = 0;
          }, 1000);
        } catch (err) {
          console.log(err);
        }
      });
  };

  const onComplete = () => {
    animationRef.current.container.style.display = 'none';
    history.push('/profile');
  };

  return (
    <Container>
      {networkError && (
        <ErrorModal
          title='Please change your chain'
          msg={`${config.maticNetworkName} is required to list your NFT`}
        />
      )}
      {error && (
        <ErrorModal
          title='Error'
          msg={error}
          sendClose={() => setError(false)}
        />
      )}

      {showSpinner && <Spinner />}
      {nft && (
        <Content>
          <div className='back subheader-2' onClick={() => history.goBack()}>&lt; Back</div>
          <div className='wrapper'>
            <img
              ref={imgRef}
              src={
                validMetadata ? nft.tokenMetadata.image : '/images/no-image.svg'
              }
              alt={`${nft?.tokenMetadata?.name} Card`}
            />
            <Player
              onEvent={(event) => {
                if (event === 'complete') onComplete();
              }}
              autoplay={false}
              renderer='svg'
              src='/animations/sacrifice.json'
              style={{
                height: '70%',
                width: '97%',
                zIndex: 10,
                bottom: '150px',
                position: 'absolute',
                opacity: 0,
                transition: 'opacity 1000ms ease-out',
              }}
              ref={animationRef}
            />
          </div>
          <StdButton
            width={300}
            height={45}
            margin='1.5rem auto'
            onClick={handleBanish}
          >
            Sacrifice
          </StdButton>
        </Content>
      )}
    </Container>
  );
};

export default SacrificePage;

import React from 'react';
import ReactDOM from 'react-dom';
import {
  ModalWrapper,
  Modal,
  CloseBtn,
  Header,
  Content,
} from './NFTPurchaseConfirmation.styles';

import config from '../../config';
import { fromWei } from '../../utils/useWeb3';
import { Icon } from '../Icons/BaseIcon';

const NFTPurchaseConfirmation = (props) => {
  const { sendClose, nft, complete } = props;
  const metadata = nft.tokenMetadata;

  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal>
        <CloseBtn onClick={sendClose} complete={complete} />
        <Header>
          <h3>Complete Purchase</h3>
        </Header>
        {nft && (
          <Content complete={complete}>
            <div className='row1'>
              <div className='img-container'>
                <img
                  src={metadata?.image || '/images/no-image.svg'}
                  alt='Card'
                />
              </div>
              <div>
                <h4>{metadata.name}</h4>
                <h6>{nft?.tokenTokenId}</h6>
              </div>
              <div className='verified'>
                {nft.contractAddress.toLowerCase() === config.nftAddress && (
                  <div className='license'>
                    <Icon
                      src='/images/green-check.svg'
                      height={17}
                      width={17}
                      margin='0 10px 0 0'
                    />{' '}
                    <p className='small-title'>Contract Verified</p>
                  </div>
                )}
              </div>
              <div className='price-wrapper'>
                <p>Price:</p>
                <div className='price'>
                  <Icon
                    src='/images/matic-logo.png'
                    height={15}
                    width={15}
                    margin='1rem .75rem 1rem 0'
                  />
                  <p>{fromWei(nft.signedOrder.order.price.toString())}</p>
                </div>
              </div>
            </div>
            {!complete && (
              <>
                <h4 className='warning'>
                  Completed transactions are final. Please make sure you trust
                  the NFT contract.
                </h4>
                <h4 className='warning'>
                  Confirm the transaction to complete your purchase.
                </h4>
              </>
            )}
            <img src='/images/spinner.gif' alt='spinner' className='spinner' />
            <h4 className='footer warning'>
              {complete
                ? 'Purchase Complete. Close Modal to Continue.'
                : 'Please do not close your browser.'}
            </h4>
          </Content>
        )}
      </Modal>
    </ModalWrapper>,
    document.getElementById('portal')
  );
};

export default NFTPurchaseConfirmation;

import styled from 'styled-components';

export const Container = styled.div`
  .properties {
    display: flex;
    align-items: stretch;
    gap: 1rem;
    width: 100%;
  }
  .properties > div {
    width: 50%;
  }

  .history {
    font-size: .5em;
  }
`;

export const Section = styled.div`
  background-color: ${({ theme }) => theme.accent.hoverLight};
  border: 2px solid ${({ theme }) => theme.background.dark};
  border-radius: 1.5em;
  padding: 3.5em;
  color: ${({ theme }) => theme.background.dark};
  margin: .5rem 0;
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  gap: 2rem 0;

  h4 {
    white-space: nowrap;
  }
  .btns {
    display: flex;
    gap: 0 1rem;
  }
`;
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { Container, Main } from '../Page.styles';
import { Section, Header } from './MyListings.styles';
import { UserContext } from '../../context/UserContext';
import NFTCard from '../../components/NFTCard/NFTCard.component';
import useFilter from '../../utils/useFilter';
import Filters from '../../components/Filters/Filters.component';
import { fetchUsersListedNFTs, fetchCurrentRate } from '../../utils/api';
import Search from '../../components/Search/Search.component';

const MyListings = (props) => {
  const sortOptions = {
    OLDEST_ORDERS: 'Newest Orders',
    NEWEST_ORDERS: 'Oldest Orders',
    PRICE_ASC: 'Lowest Price',
    PRICE_DESC: 'Highest Price',
    TOKEN_ID_ASC: 'Token Id',
  };
  const [, , walletAddress] = useContext(UserContext);
  const [listedNFTs, setListed] = useState([]);
  const [rate, setRate] = useState();
  const { filters, setFilters, filteredData, resetFilters } = useFilter(
    listedNFTs,
    'nft'
  );
  const [showFilter, setShowFilter] = useState(false);

  // fetch Card Data on mount
  useEffect(() => {
    fetchCurrentRate('MATIC', 'USDT').then((rate) => setRate(parseFloat(rate)));
    if (window.innerWidth < 910) {
      setShowFilter(false);
    }
  }, []);

  useEffect(() => {
    if (walletAddress)
      fetchUsersListedNFTs(walletAddress)
        .then((data) => setListed(data))
        .catch((err) => console.log(err));
  }, [walletAddress]);

  return (
    <Container>
      <Filters
        items={filteredData}
        filters={filters}
        setFilters={setFilters}
        resetFilters={resetFilters}
        sortOptions={sortOptions}
      />
      <Main>
        <Section>
          {filteredData.map((item) => (
            <NFTCard key={item.id} nft={item} size='small' rate={rate} />
          ))}
        </Section>
      </Main>
    </Container>
  );
};

export default MyListings;

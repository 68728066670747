import styled from 'styled-components';

export const Container = styled.div`
  width: 400px;
  min-height: 60px;
  text-align: center;
  padding: 0.8rem;
  margin-left: 4rem;
  border: 1px solid
    ${(props) => (props.status === 'success' ? '#2abd57' : '#ff4444')};
  border-radius: 5px;
  background: ${({ theme }) => theme.primaryColor};
  color: ${(props) => (props.status === 'success' ? '#2abd57' : '#ff4444')};
  display: none;

  &.visible {
    display: block;
  }
`;

import React, { useContext, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Container, Hero } from './SignupPage.styles';
import { API } from 'aws-amplify';
import useForm from '../../utils/useForm';
import { validateSignup } from './validate';
import Spinner from '../../components/Spinner/Spinner';
import Screen1 from './Screen1';
import Screen2 from './Screen2';
import Screen3 from './Screen3';
import { UserContext } from '../../context/UserContext';

const SignupPage = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [page, setPage] = useState(1);
  const [, setUser, wallet, setWalletAddress, signature, setSignature] = useContext(UserContext);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { web3, web3Provider } = props;
  const action = 'signup';

  const showRecaptcha = () => executeRecaptcha ? false : true;

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = async (v2Token) => {
    if (!executeRecaptcha) {
      console.log('Execute recaptcha enterprise not yet available');
      if (v2Token) {

        return { success: true };
      } else return { success: false, err: 'reCAPTCHA verification failed.' };
    }
    const token = await executeRecaptcha(action);
    const result = await API.post('marketplace', '/recaptcha', { body: { token, action } });
    if (result.valid)
      return { success: true };
    else
      return { success: false, err: result.errorMessage };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  // You can use useEffect to trigger the verification as soon as the component being loaded
  // useEffect(() => {
  // }, []);

  const { values, errors, handleSubmit, handleChange, setErrors, setValues } =
    useForm(submit, validateSignup, {
      email: '',
      signed: false,
      signature: null,
    });

  async function submit(values) {
    const reCaptchaResult = await handleReCaptchaVerify(values.recaptcha);
    if (!reCaptchaResult.success) {
      setErrors({ ...errors, system: reCaptchaResult.err });
      return;
    }
    if (!values.terms) return;
    if (!values.signed) return;
    setShowSpinner(true);
    try {
      try {
        const { nonce, email, name, new: isNew } = values;
        if (isNew !== true) {
          setErrors({ system: 'account already exists' });
        } else {
          setUser({ nonce, email, name, signature, wallet });
          setPage(3);
        }
      } catch (err) {
        console.log(err.code, err);
        setErrors({ system: err.message });
      } finally {
        setShowSpinner(false);
      }
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
      if (err.code === 'InvalidPasswordException' || err.code === 'InvalidParameterException')
        setErrors({ ...errors, system: err.message });
      else if (err.code === 'UsernameExistsException')
        setErrors({ ...errors, system: err.message });
      else if (
        err.code === 'UserLambdaValidationException' &&
        err.message.includes('PreSignUp failed with error')
      ) {
        setErrors({ ...errors, system: err.message.substr(27) });
      } else {
        console.log({ level: 'Error', message: 'Signup Error', error: err });
        setErrors({
          ...errors,
          system: 'Unable to sign up. Please try again later.',
        });
      }
    }
  }

  return (
    <Container>
      {showSpinner && <Spinner />}
      <Hero>
        <img src='/images/logo1.svg' alt='Logo' />
        <h2>Discover Collect and Trade NFTs</h2>
        <p>
          LOCG Marketplace is fully-fledged NFT marketplace on LOC coin and ETH.
          Get quick and easy access to digital collectibles and explore, buy and
          sell NFTs from different collections and artists. Get your Genesis
          Cards, all minted here for the first time!
        </p>
      </Hero>
      {page === 1 ? (
        <Screen1
          values={values}
          errors={errors}
          handleChange={handleChange}
          setShowSpinner={setShowSpinner}
          setPage={setPage}
        />
      ) : page === 2 ? (
        <Screen2
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          showSpinner={showSpinner}
          setPage={setPage}
          setValues={setValues}
          setErrors={setErrors}
          showRecaptcha={showRecaptcha()}
          web3={web3}
          web3Provider={web3Provider}
        />
      ) : page === 3 ? (
        <Screen3 values={values} />
      ) : null}
    </Container>
  );
};

export default SignupPage;

import styled from 'styled-components';

export const ShadowDiv = styled.div`
`;

export const ButtonContainer = styled.div`
`;

export const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  isolation: isolate;
  &:hover {
  }
`;

export const Span = styled.div`
  display: inline-block;
  margin: 0 3px;
  text-transform: uppercase;
  z-index: 1000;
`;

export const ToolTip = styled.div`
  position: absolute;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.8);
  font: 600 .75rem/.8rem TTHoves;
  font-weight: 300;
  line-height: 1.3;
  top: 0;
  left: 50%;
  width: 250px;
  padding: 0.75rem 1.2rem;
  transform: translateY(-115%) translateX(-50%) scale(0);
  transform-origin: bottom center;
  transition: transform 250ms ease-in-out;
  cursor: ${(props) => (props.nsf ? 'pointer' : 'default')};
`;

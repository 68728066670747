import styled from 'styled-components';

export const Section = styled.section`
  min-height: 511px;
  width: 90%;
  max-width: 1700px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin: 2rem auto;
  padding: 0.5rem;
  gap: 1.5rem;

  &.hide {
    display: none;
  }

  @media screen and (max-width: 1690px) {
    max-width: 1400px;
  }
  @media screen and (max-width: 1290px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 900px) {
    flex-direction: column;
    padding: 0;
    margin: 1rem 0;
    flex-direction: column;
    align-items: center;
  }

  @media screen and (max-width: 390px) {
    width: 100%;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory } from 'react-router';
import { UserContext } from '../../context/UserContext';
import { Container, Main, Content } from './TransferPage.styles';
import { Icon } from '../../components/Icons/BaseIcon';
import StyledInput from '../../components/Input/Input.component';
import Spinner from '../../components/Spinner/Spinner';
import ErrorModal from '../../components/ErrorModal/ErrorModal.component';
import ABI from '../../utils/LOCGamePlayNFT.abi.json';
import config from '../../config';
import { USER_REJECTED } from '../../constants';
import { checkChainId } from '../../utils/utils';
import NFTImage from '../../components/NFTImage/NFTImage.component';
import {
  addToken,
  fetchNFT,
  getGasPrice,
  getMaxPriorityFee,
  postSignedOrder,
} from '../../utils/api';
import StdButton from '../../components/StdButton/StdButton.component';
import useForm from '../../utils/useForm';
import { validateTransfer } from './validateTransfer';
// import Checkbox from '../../components/Checkbox/Checkbox.component';
import { fromWei, toWei } from '../../utils/useWeb3';
import ListingConfirmationModal from '../../components/ListingConfirmationModal/ListingConfirmationModal.component';
import useResizeObserver from '../../utils/useResizeObserver';

const ListingPage = (props) => {
  const web3 = props.web3;
  const mainRef = useRef();
  const [contract, setContract] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [nft, setNft] = useState(null);
  const [, , walletAddress] = useContext(UserContext);
  const [showSpinner, setShowSpinner] = useState(false);
  const [networkError, setNetworkError] = useState(false);
  const [btnWidth, setBtnWidth] = useState(500);
  const [validMetadata, setValidMetadata] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const dimensions = useResizeObserver(mainRef);
  const [msg, setMsg] = useState(null);
  const [error, setError] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [complete, setComplete] = useState(false);

  const history = useHistory();

  const { values, errors, handleSubmit, handleChange, setValues } = useForm(
    handleListing,
    validateTransfer,
    { address: '' }
  );

  const id = props.match.params.id;

  const handleChain = async () => {
    await checkChainId(web3, config.maticChainId, setNetworkError, setChainId);
  };

  useEffect(() => {
    if (!web3.givenProvider || !web3.givenProvider.isMetaMask) return;
    handleChain();
    web3.eth.currentProvider.on('chainChanged', handleChain);
    console.log(chainId === config.maticChainId);
    if (chainId === config.maticChainId)
      setContract(new web3.eth.Contract(ABI, config.nftAddress, { from: walletAddress }));
    getNftData();
    return () => {
      web3.eth.currentProvider.removeListener('chainChanged', handleChain);
    };
  }, []);

  useEffect(() => {
    if (chainId !== config.maticChainId) return;
    setContract(new web3.eth.Contract(ABI, config.nftAddress, { from: walletAddress }));
  }, [chainId]);

  // useEffect(() => {
  //   if (!contract || !walletAddress || chainId !== config.maticChainId) return;
  //   contract.methods
  //     .isApprovedForAll(walletAddress, config.maticDexContract)
  //     .call()
  //     .then((data) => {
  //       setIsApproved(data);
  //     });
  // }, [contract, walletAddress, chainId]);

  useEffect(() => {
    if (!dimensions) return;
    if (dimensions.width > 585) setBtnWidth(500);
    else setBtnWidth(300);
  }, [dimensions]);

  useEffect(() => {
    if (!values.price || isNaN(values.price)) return;
  }, [values.price]);

  async function getNftData() {
    setShowSpinner(true);
    try {
      let currentNFT = await fetchNFT(
        config.maticNetworkName,
        config.nftAddress,
        id
      );
      if (!currentNFT) {
        await addToken(config.maticNetworkName, config.nftAddress, id);
        currentNFT = await fetchNFT(
          config.maticNetworkName,
          config.nftAddress,
          id
        );
      }
      setNft(currentNFT);
      setValidMetadata(currentNFT?.tokenMetadata?.valid);
      if (currentNFT?.hasOrder) {
        setValues((prev) => ({
          ...prev,
          price: fromWei(currentNFT.signedOrder.order.price),
        }));
      }
      setShowSpinner(false);
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
    }
  }

  // const handleCheckbox = () => {
  //   setValues((prev) => ({ ...prev, reserved: !prev.reserved }));
  // };

  async function handleListing() {
    if (!walletAddress) {
      history.push({
        pathname: '/profile',
        tab: 'wallet',
      });
      return;
    }
    setConfirm(true);
    try {
      await handleChain();
      if (chainId !== config.maticChainId) return;
      setMsg('Please Approve Token Transfers');
      const gasPrice = toWei(await getGasPrice('polygon'), 'Gwei');
      console.log(walletAddress, values.address, nft.tokenTokenId)
      await contract.methods
        .transferFrom(walletAddress, values.address, nft.tokenTokenId)
        .send({ gas: 200000, gasPrice })
        .on('transactionHash', (txHash) => setMsg('Processing Approval'));
      // setNft(nft);
      setCurrentStep(4);
      setComplete(true);
      setMsg(null);
    } catch (err) {
      setMsg(null);
      setConfirm(false);
      if (err?.code === USER_REJECTED) return;
      if (String(err).includes('RPC Error'))
        setError('Blockchain RPC Error, Please try again in a few minutes.');
      if (String(err).includes('Unable to create order'))
        setError('Unable to create order, Please try again in a few minutes.');
      console.log('Error:', err.message);
    }
  }

  const sendClose = () => {
    setConfirm(false);
    history.push({ pathname: '/profile', tab: '2' });
  };

  return (
    <Container ref={mainRef}>
      {networkError && (
        <ErrorModal
          title='Please change your chain'
          msg={`${config.maticNetworkName} is required to list your NFT`}
        />
      )}
      {error && (
        <ErrorModal
          title='Error'
          msg={error}
          sendClose={() => setError(false)}
        />
      )}
      {confirm && (
        <ListingConfirmationModal
          type="transfer"
          nft={nft}
          sendClose={sendClose}
          currentStep={currentStep}
          msg={msg}
          complete={complete}
        />
      )}
      {showSpinner && <Spinner />}
      {nft && (
        <Main>
          <Content>
            <div className='back subheader-2' onClick={() => history.goBack()}>&lt; Back</div>
            <h2>Transfer item to another address</h2>

            <p className='heading'>Wallet Address</p>

            <form onSubmit={handleSubmit}>
              <div className='row'>
                <StyledInput
                  name='address'
                  id='address'
                  type='text'
                  value={values.address}
                  errors={errors.price}
                  placeholder='Address '
                  autoComplete='off'
                  onChange={handleChange}
                />
              </div>
              {/* <div className='row'>
                <p className='heading'>Reserve for specific buyer</p>
                <Checkbox
                  margin='4px 30px'
                  checked={values.reserved}
                  value={values.reserved}
                  onClick={handleCheckbox}
                  onChange={() => {}}
                />
              </div>
              <p>This item can be purchased as soon as it's listed.</p>
              <div className='reserve'>
                <StyledInput
                  name='reserveAddress'
                  id='reserveAddress'
                  type='text'
                  value={values.reserveAddress}
                  errors={errors.reserveAddress}
                  placeholder='Reserve Wallet Address'
                  autoComplete='off'
                  onChange={handleChange}
                />
              </div> */}
              <StdButton
                width={btnWidth}
                onClick={handleSubmit}
                margin='3rem 0 0'
              >
                TRANSFER NFT
              </StdButton>
            </form>
          </Content>
          <div>
            <NFTImage
              img={
                validMetadata ? nft.tokenMetadata.image : '/images/no-image.svg'
              }
              price={values.price}
            />
          </div>
        </Main>
      )}
    </Container>
  );
};

export default ListingPage;

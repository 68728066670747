import styled from 'styled-components';

let height = 210;
let width = 500;

export const Container = styled.div`
  height: ${height}px;
  width: ${width}px;
  padding: 1.8rem 0;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0.75rem;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.25rem;
    text-align: left;
    text-transform: uppercase;
  }

  @media screen and (max-width: 680px) {
    width: 350px;
    padding: 1.8rem 1rem;

    h2 {
      font-size: 1.5rem;
    }
    span {
      font-size: 0.8rem;
    }
  }
  @media screen and (max-width: 390px) {
    width: 296px;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { Container, Main } from '../Page.styles';
import { Section, Header } from './MyPacksPage.styles';
import { UserContext } from '../../context/UserContext';
import ProductItem from '../../components/ProductItem/ProductItem.component';
import GameCard from '../../components/GameCard/GameCard.component';
import Button from '../../components/Button/Button.component';
import { getCards, fetchOrders } from '../../utils/api';

const MyPacksPage = (props) => {
  const [orderRefreshCount, setOrderRefreshCount] = useState(0);
  // const [currentTimeout, setCurrentTimeout] = useState(null);
  const [user, , walletAddress] = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [newCards, setNewCards] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [autoFlip, setAutoFlip] = useState(false);
  const [showFlipBtn, setShowFlipBtn] = useState({
    visible: false,
    flippedCards: 0,
  });

  // fetch Card Data on mount
  useEffect(() => {
    getCards().then((data) => setCardData(data));
  }, []);

  async function fetchOrders(skip = 0) {
    const apiName = 'marketplace';
    const path = `/order/user/${walletAddress}`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('signature')}`,
      },
      queryStringParameters: {
        skip: skip,
      },
    };

    return API.get(apiName, path, myInit);
  }

  async function updateOrders() {
    try {
      setOrderRefreshCount((prevCount) => prevCount + 1);
      let data = await fetchOrders(0);
      data = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Canceled by User'
      );
      setOrders(data);

      const pOrders = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Complete'
      );
      setPendingOrders(pOrders);
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to fetch transactions',
        error: err,
      });
    }
  }

  async function fetchUserOrdersAndCards() {
    const newCardsArr = [];
    try {
      let data = await fetchOrders(0);
      data = data.data.data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Canceled by User'
      );
      setOrders(data);

      const pOrders = data.filter(
        (order) =>
          order.isOpened === false &&
          order.wallet.toLowerCase() === walletAddress.toLowerCase() &&
          order.status !== 'Failed' &&
          order.status !== 'Complete'
      );

      setPendingOrders(pOrders);

      data.forEach((order) => {
        order.tokens.forEach((token) => newCardsArr.push(token.token));
      });
    } catch (err) {
      console.log({
        level: 'Error',
        message: 'Unable to fetch transactions',
        error: err,
      });
    }
  }

  useEffect(() => {
    if (walletAddress && cardData) {
      fetchUserOrdersAndCards();
    }
  }, [walletAddress, cardData]);

  useEffect(() => {
    if (autoFlip) setAutoFlip(false);
  }, [autoFlip]);

  useEffect(() => {
    let currentTimeout;
    if (pendingOrders.length > 0 && orderRefreshCount < 15) {
      currentTimeout = setTimeout(updateOrders, 45000);
    }
    return () => {
      if (currentTimeout) clearTimeout(currentTimeout);
    };
  }, [pendingOrders]);

  return (
    <Container>
      <Main>
        {orders.length > 0 && (
          <Section>
            {orders.map((order, i) => (
              <ProductItem
                key={order._id}
                order={order}
                orders={orders}
                setOrders={setOrders}
                setNewCards={setNewCards}
                showFlipBtn={showFlipBtn}
                setShowFlipBtn={setShowFlipBtn}
                newCards={newCards}
                cardData={cardData}
              />
            ))}
          </Section>
        )}
        <Header>
          <div className='filter-wrapper'>
            {newCards.length > 0 && <h4>{newCards.length} Cards</h4>}
          </div>
          {newCards.length > showFlipBtn.flippedCards && showFlipBtn.visible && (
            <Button
              onClick={() => {
                setShowFlipBtn({
                  visible: false,
                  flippedCards: newCards.length,
                });
                setAutoFlip(true);
              }}
            >
              Flip All Cards
            </Button>
          )}
        </Header>
        <Section>
          {newCards.length > 0 &&
            newCards.map((card, i) => (
              <GameCard
                key={card.tokenId}
                card={card}
                newCard={i}
                autoFlip={autoFlip}
                setShowFlipBtn={setShowFlipBtn}
              />
            ))}
        </Section>
      </Main>
    </Container>
  );
};

export default MyPacksPage;

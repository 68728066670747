import * as EmailValidator from 'email-validator';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from '../../components/Icons/BaseIcon';
import StyledInput from '../../components/Input/Input.component';
import StdButton from '../../components/StdButton/StdButton.component';
import { Main } from './SignupPage.styles';

const Screen1 = (props) => {
  const { values, errors, handleChange, setShowSpinner, setPage } = props;
  return (
    <Main>
      <p className='title'>SIGN UP</p>
      <p>Sign up today and become a legend.</p>
      <StyledInput
        name='email'
        id='email'
        type='text'
        value={values.email}
        errors={errors.email}
        placeholder='Email'
        autoComplete='off'
        onChange={handleChange}
        width='300px'
      />

      <StdButton
        width={300}
        onClick={() => setPage(2)}
        disabled={!EmailValidator.validate(values.email)}
        background={
          !EmailValidator.validate(values.email) ? `rgbs(255,255,255,.15)` : null
        }
      >
        Next
      </StdButton>
      {/*<div className='split'>
        <p className='split'>OR</p>
      </div>
      <div className='socials'>
        <StdButton
          width={140}
          justify='flex-start'
          onClick={() => {
            setShowSpinner(true);
            Auth.federatedSignIn({ provider: 'Google' });
          }}
        >
          <Icon src='/images/google.svg' height={20} width={20} />
          Google Sign In
        </StdButton>
        <StdButton
          width={140}
          justify='flex-start'
          onClick={() => {
            setShowSpinner(true);
            Auth.federatedSignIn({ provider: 'Facebook' });
          }}
        >
          <Icon src='/images/facebook.svg' height={20} width={20} />
          Facebook Sign In
        </StdButton>
      </div> */}
      <p className='footer'>
        Have an account?
        <NavLink to='/?login'>
          <span className='link'>Log In</span>
        </NavLink>
      </p>
    </Main>
  );
};

export default Screen1;

import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { StyledButton } from '../../components/StdButton/StdButton.styles';
import { Main } from './SignupPage.styles';

const Screen3 = ({ values }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <Main>
      <img className='logo' src='/images/logo1.svg' alt='logo' />
      <h3>Confirm your email address</h3>
      <p className='email'>{values.email}</p>
      <p className='tag'>
        Please check your email and verify this account before you can log in.
      </p>
      <p className='tag2'>
        If you do not receive a confirmation email, please check your spam
        folder. Also you can
      </p>
      <StyledButton
        className='resend'
        onClick={() => {
          setClicked(true);
          Auth.resendSignUp(values.email);
        }}
        width={300}
        disabled={clicked}
      >
        Resend verification email
      </StyledButton>
    </Main>
  );
};

export default Screen3;
